<!-- DELETE POLICY DIALOG -->
<div
  *ngIf="dialogType === 'deletePolicyDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <div class="warningText">ARE YOU SURE YOU</div>
    <div class="warningText pb15">WANT TO DELETE</div>
    <div class="dialogText pb15">
      {{ dialogTitle.toUpperCase() }}
    </div>
  </header>
  <div class="dialogBottom">
    <div mat-dialog-actions class="spaceBetween">
      <div class="">
        <button mat-flat-button color="primary" (click)="closeDialog()">
          CANCEL
        </button>
      </div>
      <div class="customRedButton right-align">
        <button mat-flat-button (click)="deletePolicy()" cdkFocusInitial>
          DELETE
        </button>
      </div>
    </div>
  </div>
</div>

<!-- DELETE PRODUCT DIALOG -->
<div
  *ngIf="dialogType === 'deleteProductDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <div class="warningText">ARE YOU SURE YOU</div>
    <div class="warningText pb15">WANT TO CANCEL</div>
    <h1 class="dialogText pb15">
      {{ dialogTitle.toUpperCase() }}
    </h1>
  </header>
  <div class="dialogBottom">
    <div mat-dialog-actions class="spaceBetween">
      <button mat-flat-button color="primary" (click)="closeDialog()">
        CANCEL
      </button>
      <div class="customRedButton">
        <button mat-flat-button (click)="deleteProduct()" cdkFocusInitial>
          DELETE
        </button>
      </div>
    </div>
  </div>
</div>

<!-- CANCEL IMPORT DIALOG -->
<div
  *ngIf="dialogType === 'cancelImportDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <h2 class="dialogText">
      ARE YOU SURE YOU WANT<br />
      TO CANCEL IMPORT {{ selectedItem.csvFileData.customName }}?
    </h2>
    <h3 class="warningColor pb15">
      THIS WILL NOT UNDO ANY<br />
      CHANGES ALREADY MADE
    </h3>
  </header>
  <div class="dialogBottom">
    <div mat-dialog-actions class="spaceBetween">
      <button mat-flat-button color="primary" (click)="closeDialog()">
        CLOSE
      </button>
      <div class="customRedButton">
        <button
          mat-flat-button
          (click)="closeDialog(); importService.cancelImport(selectedItem.id)"
          cdkFocusInitial
        >
          CANCEL
        </button>
      </div>
    </div>
  </div>
</div>

<!-- DELETE IMPORT DIALOG -->
<div
  *ngIf="dialogType === 'deleteImportDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <h2 class="dialogText">
      ARE YOU SURE YOU WANT<br />
      TO DELETE IMPORT {{ selectedItem.csvFileData.customName }}?
    </h2>
    <h3 class="warningColor pb15">
      THIS WILL ALSO DELETE THE<br />
      UPLOADED CSV BUT NOT<br />
      UNDO ANY CHANGES MADE
    </h3>
  </header>
  <div class="dialogBottom">
    <div mat-dialog-actions class="spaceBetween">
      <button mat-flat-button color="primary" (click)="closeDialog()">
        CLOSE
      </button>
      <div class="customRedButton">
        <button
          mat-flat-button
          (click)="closeDialog(); fileService.deleteImport(selectedItem)"
          cdkFocusInitial
        >
          DELETE
        </button>
      </div>
    </div>
  </div>
</div>

<!-- ADD/EDIT A SCHEDULED PRODUCT PREMIUM -->
<div
  *ngIf="dialogType === 'scheduledPremiumDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <h2 class="whiteColor">
      {{ (selectedItem ? "EDIT" : "NEW") + " SCHEDULED PREMIUM" }}
    </h2>
  </header>
  <div class="pt15 pb15">
    <form class="flex" [formGroup]="scheduledPremiumForm">
      <mat-form-field appearance="outline">
        <mat-label>START DATE</mat-label>
        <input
          autocomplete="off"
          matInput
          formControlName="startDate"
          [matDatepicker]="startDatePicker"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="startDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>STANDARD PREMIUM</mat-label>
        <input
          autocomplete="off"
          type="number"
          matInput
          formControlName="standardAmount"
          inputmode="numeric"
          decimalOnly
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>WAITING PREMIUM</mat-label>
        <input
          autocomplete="off"
          type="number"
          matInput
          formControlName="waitingAmount"
          inputmode="numeric"
          decimalOnly
        />
      </mat-form-field>
    </form>
  </div>
  <div>
    <div mat-dialog-actions>
      <div>
        <button mat-flat-button color="primary" (click)="closeDialog()">
          CANCEL
        </button>
      </div>
      <button
        mat-flat-button
        color="primary"
        cdkFocusInitial
        class="right-align"
        (click)="updateProductScheduledPremiums()"
        [disabled]="
          scheduledPremiumForm.invalid || !hasScheduledPremiumFormChanged()
        "
      >
        {{ selectedItem ? "SAVE" : "CREATE" }}
      </button>
    </div>
  </div>
</div>

<!-- DELETE PRODUCT SCHEDULED PREMIUM DIALOG -->
<div
  *ngIf="dialogType === 'deleteScheduledPremiumDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <div class="warningText">ARE YOU SURE YOU</div>
    <div class="warningText pb15">WANT TO DELETE</div>
    <div *ngIf="selectedItem.startDate" class="dialogText pb15">
      THE SCHEDULED
    </div>
    <div *ngIf="selectedItem.startDate" class="dialogText pb15">
      PREMIUM FOR
      {{
        dateTimeService.timestampToDate(selectedItem.startDate)
          | date : "dd/MM/yyyy"
      }}
    </div>
  </header>
  <div class="dialogBottom">
    <div mat-dialog-actions class="spaceBetween">
      <button mat-flat-button color="primary" (click)="closeDialog()">
        CANCEL
      </button>
      <div class="customRedButton">
        <button
          mat-flat-button
          (click)="deleteProductScheduledPremium()"
          cdkFocusInitial
        >
          DELETE
        </button>
      </div>
    </div>
  </div>
</div>

<!-- DELETE PLAN MEMBER DIALOG -->
<div
  *ngIf="dialogType === 'deletePlanMemberDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <div class="warningText">ARE YOU SURE YOU</div>
    <div class="warningText pb15">WANT TO DELETE</div>
    <h1 class="dialogText pb15">
      {{ dialogTitle.toUpperCase() }}
    </h1>
  </header>
  <div class="dialogBottom">
    <div mat-dialog-actions class="spaceBetween">
      <button mat-flat-button color="primary" (click)="closeDialog()">
        CANCEL
      </button>
      <div class="customRedButton">
        <button mat-flat-button (click)="deletePlanMember()" cdkFocusInitial>
          DELETE
        </button>
      </div>
    </div>
  </div>
</div>

<!-- DELETE ADD ON PLAN DIALOG -->
<div
  *ngIf="dialogType === 'deleteAddOnPlanDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <div class="warningText">ARE YOU SURE YOU</div>
    <div class="warningText pb15">WANT TO DELETE</div>
    <div class="dialogText pb15">
      {{ dialogTitle.toUpperCase() }}
    </div>
  </header>
  <div class="dialogBottom">
    <div mat-dialog-actions class="spaceBetween">
      <button mat-flat-button color="primary" (click)="closeDialog()">
        CANCEL
      </button>
      <div class="customRedButton">
        <button mat-flat-button (click)="deleteAddOnPlan()" cdkFocusInitial>
          DELETE
        </button>
      </div>
    </div>
  </div>
</div>

<!-- CREATE/EDIT A NEW COMMENT -->
<div
  *ngIf="dialogType === 'commentDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
  (keydown.enter)="commentForm.valid && updateComments()"
>
  <header>
    <h1 class="ps45 whiteColor">
      {{ dialogTitle.toUpperCase() }}
    </h1>
  </header>
  <div [formGroup]="commentForm" class="flex">
    <mat-form-field appearance="outline">
      <textarea
        matInput
        matTextareaAutosize
        placeholder="TYPE HERE"
        autocomplete="off"
        rows="6"
        formControlName="comment"
        [maxlength]="maxChars"
      ></textarea>
      <div class="flex pt5">
        <span class="fadedWhiteColor right-align"
          >{{ commentForm.get("comment")?.value?.length ?? 0 }} /
          {{ maxChars }}</span
        >
      </div>
    </mat-form-field>
  </div>
  <div>
    <div mat-dialog-actions>
      <div>
        <button mat-flat-button color="primary" (click)="closeDialog()">
          CANCEL
        </button>
      </div>
      <button
        mat-flat-button
        color="primary"
        (click)="updateComments()"
        cdkFocusInitial
        class="right-align"
        [disabled]="
          commentForm.invalid ||
          commentForm.get('comment')?.value === selectedItem?.comment
        "
      >
        SAVE
      </button>
    </div>
  </div>
</div>

<!-- DELETE COMMENT DIALOG -->
<div
  *ngIf="dialogType === 'deleteCommentDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <h2 class="warningText">
      ARE YOU SURE YOU WANT<br />TO DELETE THE COMMENT
    </h2>
  </header>
  <div class="dialogBottom">
    <div mat-dialog-actions class="spaceBetween">
      <button mat-flat-button color="primary" (click)="closeDialog()">
        CANCEL
      </button>
      <div class="customRedButton">
        <button mat-flat-button (click)="deletePolicyComment()" cdkFocusInitial>
          DELETE
        </button>
      </div>
    </div>
  </div>
</div>

<!-- DELETE MEMBER DIALOG -->
<div
  *ngIf="dialogType === 'deleteMemberDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <div class="warningText">ARE YOU SURE YOU</div>
    <div class="warningText pb15">WANT TO DELETE</div>
    <h1 class="dialogText pb15">
      {{ selectedItem.firstName + " " + selectedItem.lastName | uppercase }}
    </h1>
  </header>
  <div class="dialogBottom">
    <div mat-dialog-actions class="spaceBetween">
      <button mat-flat-button color="primary" (click)="closeDialog()">
        CANCEL
      </button>
      <div class="customRedButton">
        <button mat-flat-button (click)="deletePolicyMember()" cdkFocusInitial>
          DELETE
        </button>
      </div>
    </div>
  </div>
</div>

<!-- DELETE ADDON DIALOG -->
<div
  *ngIf="dialogType === 'deleteAddOnDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <div class="warningText">ARE YOU SURE YOU</div>
    <div class="warningText pb15">WANT TO DELETE</div>
    <h1 class="dialogText pb15">
      {{ addOnService.getAddOnNameById(selectedItem.addOnId).text | uppercase }}
    </h1>
  </header>
  <div class="dialogBottom">
    <div mat-dialog-actions class="spaceBetween">
      <button mat-flat-button color="primary" (click)="closeDialog()">
        CANCEL
      </button>
      <div class="customRedButton">
        <button mat-flat-button (click)="deletePolicyAddOn()" cdkFocusInitial>
          DELETE
        </button>
      </div>
    </div>
  </div>
</div>

<!-- DELETE REPORT DIALOG -->
<div
  *ngIf="dialogType === 'deleteReportDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <div class="warningText">ARE YOU SURE YOU</div>
    <div class="warningText pb15">WANT TO DELETE</div>
    <h1 class="dialogText pb15">
      {{ selectedItem.reportName | uppercase }}
    </h1>
  </header>
  <div class="dialogBottom">
    <div mat-dialog-actions class="spaceBetween">
      <button mat-flat-button color="primary" (click)="closeDialog()">
        CANCEL
      </button>
      <div class="customRedButton">
        <button mat-flat-button (click)="deleteReport()" cdkFocusInitial>
          DELETE
        </button>
      </div>
    </div>
  </div>
</div>

<!-- DELETE GENERATED REPORT DIALOG -->
<div
  *ngIf="dialogType === 'deleteGeneratedReportDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <div class="warningText">ARE YOU SURE YOU</div>
    <div class="warningText pb15">WANT TO DELETE</div>
    <h1 class="dialogText pb15">
      {{ selectedItem.reportName | uppercase }}
    </h1>
  </header>
  <div class="dialogBottom">
    <div mat-dialog-actions class="spaceBetween">
      <button mat-flat-button color="primary" (click)="closeDialog()">
        CANCEL
      </button>
      <div class="customRedButton">
        <button
          mat-flat-button
          (click)="deleteGeneratedReport()"
          cdkFocusInitial
        >
          DELETE
        </button>
      </div>
    </div>
  </div>
</div>

<!-- CREATE A NEW MESSAGE -->
<div
  *ngIf="dialogType === 'messageDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
  (keydown.enter)="
    message.value && (inputText = message.value); updateMessages()
  "
>
  <header>
    <h1 class="ps45 whiteColor">
      {{ dialogTitle.toUpperCase() }}
    </h1>
  </header>
  <div class="flex">
    <mat-form-field appearance="outline" class="expand">
      <textarea
        matInput
        matTextareaAutosize
        placeholder="TYPE HERE"
        autocomplete="off"
        maxlength="160"
        rows="6"
        #message
        >{{ inputText }}</textarea
      >
      <mat-hint align="end" class="fadedWhiteColor">
        {{ message.value.length || 0 }} / 160
      </mat-hint>
    </mat-form-field>
  </div>
  <div>
    <div mat-dialog-actions class="spaceBetween">
      <button mat-flat-button color="primary" (click)="closeDialog()">
        CANCEL
      </button>
      <button
        mat-flat-button
        color="primary"
        (click)="inputText = message.value; updateMessages()"
        cdkFocusInitial
        class="customRedButton"
      >
        SEND
      </button>
    </div>
  </div>
</div>

<!-- UPLOAD FILE -->
<div
  *ngIf="dialogType === 'uploadDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
  (keydown.enter)="
    customName.value &&
      canUploadOrUpdateFile(customName.value) &&
      updateFiles(customName.value)
  "
>
  <header class="header">
    <h1 class="whiteColor">
      {{ dialogTitle.toUpperCase() }}
    </h1>
  </header>
  <div class="flex file-form-container">
    <div class="flex">
      <mat-form-field appearance="outline">
        <mat-label>FILE TITLE</mat-label>
        <input
          autocomplete="off"
          oninput="this.value = this.value.toUpperCase()"
          matInput
          cdkFocusInitial
          #customName="matInput"
          [inputMaxLength]="40"
          (focus)="mainService.selectInputText($event)"
        />
      </mat-form-field>
    </div>
    <div class="file-selected">
      <input
        #fileInput
        type="file"
        accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.csv,.txt,.svg,image/jpeg,image/png,image/gif,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-word,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
        style="display: none"
        (change)="fileService.onFileSelected($event)"
      />
      <span class="file-text" *ngIf="index === -1">{{
        fileService.selectedFile
          ? fileService.selectedFile.name
          : "NO FILE SELECTED"
      }}</span>
      <span class="file-text" *ngIf="index !== -1">{{
        policyService.selectedPolicy && policyService.selectedPolicy.files
          ? policyService.selectedPolicy.files[index].name
          : "ERROR DISPLAYING FILE NAME"
      }}</span>
    </div>
    <div
      mat-button
      #chooseFileButton
      class="choose-file-button"
      *ngIf="dialogTitle !== 'EDIT FILE TITLE'"
      (click)="fileInput?.click()"
    >
      CHOOSE A FILE
    </div>
  </div>
  <div class="pt45">
    <div mat-dialog-actions>
      <div>
        <button
          mat-flat-button
          color="primary"
          class="Montserrat"
          (click)="closeDialog()"
        >
          CANCEL
        </button>
      </div>
      <button
        mat-flat-button
        color="primary"
        (click)="updateFiles(customName.value)"
        class="right-align Montserrat"
        [disabled]="!canUploadOrUpdateFile(customName.value)"
      >
        {{ inputText ? "UPDATE" : "UPLOAD" }}
      </button>
    </div>
  </div>
</div>

<!-- VIEW FILE -->
<div
  *ngIf="dialogType === 'viewFileDialog'"
  class="dialog-container scroll"
  (keydown.escape)="closeDialog()"
>
  <header class="view-file-close-button">
    <button mat-fab color="primary" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div class="flex view-file-container">
    <img
      [src]="
        policyService.selectedPolicy && policyService.selectedPolicy.files
          ? policyService.selectedPolicy.files[index].url
          : ''
      "
      alt="{{
        policyService.selectedPolicy && policyService.selectedPolicy.files
          ? policyService.selectedPolicy.files[index].name
          : ''
      }}"
    />
  </div>
</div>

<!-- DELETE FILE CONFIRMATION -->
<div
  *ngIf="dialogType === 'deleteFileDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <div class="warningText">ARE YOU SURE YOU</div>
    <div class="warningText pb15">WANT TO DELETE</div>
    <h1 class="dialogText pb15">
      {{ dialogTitle.toUpperCase() }}
    </h1>
  </header>
  <div class="dialogBottom">
    <div mat-dialog-actions class="spaceBetween">
      <button
        mat-flat-button
        color="primary"
        class="Montserrat"
        (click)="closeDialog()"
      >
        CANCEL
      </button>
      <div class="customRedButton right-align">
        <button
          mat-flat-button
          class="Montserrat"
          (click)="
            fileService.deleteFile(
              policyService.selectedPolicy && policyService.selectedPolicy.files
                ? policyService.selectedPolicy.files[index]
                : undefined,
              index
            );
            closeDialog()
          "
          cdkFocusInitial
        >
          DELETE
        </button>
      </div>
    </div>
  </div>
</div>

<!-- IMPORT FILE -->
<div
  *ngIf="dialogType === 'importDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="header">
    <h1 class="whiteColor">
      {{ dialogTitle.toUpperCase() }}
    </h1>
  </header>
  <form class="flex file-form-container" [formGroup]="importForm">
    <mat-form-field appearance="outline">
      <mat-label>FILE TITLE</mat-label>
      <input
        formControlName="customName"
        autocomplete="off"
        oninput="this.value = this.value.toUpperCase()"
        matInput
        cdkFocusInitial
        [inputMaxLength]="40"
        (focus)="mainService.selectInputText($event)"
      />
    </mat-form-field>
    <div class="file-selected">
      <input
        #fileInput
        type="file"
        accept=".csv"
        style="display: none"
        (change)="fileService.onFileSelected($event)"
      />
      <span class="file-text" *ngIf="index === -1">{{
        fileService.selectedFile
          ? fileService.selectedFile.name
          : "NO FILE SELECTED"
      }}</span>
    </div>
    <div
      mat-button
      #chooseFileButton
      class="choose-file-button"
      (click)="fileInput?.click()"
    >
      CHOOSE A FILE
    </div>
    <mat-form-field appearance="outline" class="pt20">
      <mat-label>TYPE</mat-label>
      <mat-select formControlName="type">
        <ng-container
          *ngIf="userService.userData?.email?.endsWith('ioio.co.za')"
        >
          <mat-option [value]="ImportType.MEMBER">{{
            ImportType.MEMBER
          }}</mat-option>
        </ng-container>
        <ng-container
          *ngIf="userService.userData?.email?.endsWith('ioio.co.za')"
        >
          <mat-option [value]="ImportType.ADD_ON">{{
            ImportType.ADD_ON
          }}</mat-option>
        </ng-container>
        <ng-container
          *ngIf="userService.userData?.email?.endsWith('ioio.co.za')"
        >
          <mat-option [value]="ImportType.POL360_TRANSACTION">{{
            ImportType.POL360_TRANSACTION
          }}</mat-option>
        </ng-container>
        <ng-container *ngIf="rolesRightsService.currentUserRole?.imports?.eft">
          <mat-option [value]="ImportType.EFT_TRANSACTION">{{
            ImportType.EFT_TRANSACTION
          }}</mat-option>
        </ng-container>
        <ng-container
          *ngIf="rolesRightsService.currentUserRole?.imports?.payAt"
        >
          <mat-option [value]="ImportType.PAY_AT_TRANSACTION">{{
            ImportType.PAY_AT_TRANSACTION
          }}</mat-option>
        </ng-container>
        <ng-container
          *ngIf="rolesRightsService.currentUserRole?.imports?.netcash"
        >
          <mat-option [value]="ImportType.NETCASH_TRANSACTION">{{
            ImportType.NETCASH_TRANSACTION
          }}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </form>
  <div>
    <div mat-dialog-actions>
      <div>
        <button
          mat-flat-button
          color="primary"
          class="Montserrat"
          (click)="closeDialog()"
        >
          CANCEL
        </button>
      </div>
      <button
        mat-flat-button
        color="primary"
        class="right-align Montserrat"
        [disabled]="!this.fileService.selectedFile || importForm.invalid"
        (click)="
          fileService.initiateFileUpload(
            importForm.get('customName')?.value,
            importForm.get('type')?.value
          );
          closeDialog()
        "
      >
        IMPORT
      </button>
    </div>
  </div>
</div>

<!-- RETRY IMPORT CONFIRMATION -->
<div
  *ngIf="dialogType === 'retryImportDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <div class="warningText">ARE YOU SURE YOU WANT</div>
    <div class="warningText pb15">TO RETRY THIS IMPORT?</div>
    <h1 class="dialogText pb15">
      {{ selectedItem.csvFileData?.customName }}
    </h1>
  </header>
  <div class="dialogBottom">
    <div mat-dialog-actions class="spaceBetween">
      <button
        mat-flat-button
        color="primary"
        class="Montserrat"
        (click)="closeDialog()"
      >
        CANCEL
      </button>
      <div class="right-align">
        <button
          mat-flat-button
          class="Montserrat"
          color="primary"
          (click)="importService.retryImport(selectedItem); closeDialog()"
          cdkFocusInitial
        >
          RETRY
        </button>
      </div>
    </div>
  </div>
</div>

<!-- ADJUST ADDON WAITING PERIOD -->
<div
  *ngIf="dialogType === 'addOnWaitingPeriodDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
  (keydown.enter)="
    waitingPeriodForm.get('requestedWaitingDate')?.value &&
      updateAddOnRequestedWaitingDate(
        waitingPeriodForm.get('requestedWaitingDate')?.value
      )
  "
>
  <header>
    <h2 class="whiteColor center-text">WAITING PERIOD</h2>
    <h3 class="whiteColor center-text pb15">
      {{ dialogTitle.toUpperCase() }}
    </h3>
  </header>
  <form class="file-form-container" [formGroup]="waitingPeriodForm">
    <div class="center-text">
      <mat-form-field
        class="pl15 center-text removeFormFieldBottomPadding"
        appearance="outline"
      >
        <mat-label>THIRD PARTY DATE</mat-label>
        <input
          formControlName="requestedWaitingDate"
          autocomplete="off"
          matInput
          [max]="maxDate"
          [matDatepicker]="addOnWaitingDatePicker"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="addOnWaitingDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #addOnWaitingDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
  <h4 class="whiteColor center-text">
    <a class="requestedColor">
      {{
        getNewWaitingPeriodLeftByDate(
          waitingPeriodForm.get("requestedWaitingDate")?.value
        )
      }}
      DAYS
    </a>
    WILL BE LEFT OF<br />
    THE WAITING PERIOD
  </h4>
  <div class="pt15">
    <div mat-dialog-actions>
      <div>
        <button mat-flat-button color="primary" (click)="closeDialog()">
          CANCEL
        </button>
      </div>
      <button
        [disabled]="
          selectedItem.requestedWaitingDate ===
          waitingPeriodForm.get('requestedWaitingDate')?.value
        "
        mat-flat-button
        color="primary"
        (click)="
          updateAddOnRequestedWaitingDate(
            waitingPeriodForm.get('requestedWaitingDate')?.value
          )
        "
        cdkFocusInitial
        class="right-align"
      >
        SAVE
      </button>
    </div>
  </div>
</div>

<!-- ADJUST MEMBER WAITING PERIOD -->
<div
  *ngIf="dialogType === 'memberWaitingPeriodDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
  (keydown.enter)="
    waitingPeriodForm.get('requestedWaitingDate')?.value &&
      updateMemberRequestedWaitingDate(
        waitingPeriodForm.get('requestedWaitingDate')?.value
      )
  "
>
  <header>
    <h2 class="whiteColor center-text">WAITING PERIOD</h2>
    <h3 class="whiteColor center-text pb15">
      {{ dialogTitle.toUpperCase() }}
    </h3>
  </header>
  <form class="file-form-container" [formGroup]="waitingPeriodForm">
    <div class="center-text">
      <mat-form-field
        class="pl15 center-text removeFormFieldBottomPadding"
        appearance="outline"
      >
        <mat-label>THIRD PARTY DATE</mat-label>
        <input
          formControlName="requestedWaitingDate"
          autocomplete="off"
          matInput
          [max]="maxDate"
          [matDatepicker]="memberWaitingDatePicker"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="memberWaitingDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #memberWaitingDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
  <h4 class="whiteColor center-text">
    <a class="requestedColor">
      {{
        getNewWaitingPeriodLeftByDate(
          waitingPeriodForm.get("requestedWaitingDate")?.value
        )
      }}
      DAYS
    </a>
    WILL BE LEFT OF<br />
    THE WAITING PERIOD
  </h4>
  <div class="pt15">
    <div mat-dialog-actions>
      <div>
        <button mat-flat-button color="primary" (click)="closeDialog()">
          CANCEL
        </button>
      </div>
      <button
        [disabled]="
          selectedItem.requestedWaitingDate ===
          waitingPeriodForm.get('requestedWaitingDate')?.value
        "
        mat-flat-button
        color="primary"
        (click)="
          updateMemberRequestedWaitingDate(
            waitingPeriodForm.get('requestedWaitingDate')?.value
          )
        "
        cdkFocusInitial
        class="right-align"
      >
        SAVE
      </button>
    </div>
  </div>
</div>

<!-- CHANGE POLICY PLAN CONFIRMATION -->
<div
  *ngIf="dialogType === 'changePolicyPlanDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
  (keydown.enter)="changePolicyPlan()"
>
  <header class="center-text">
    <div class="warningText">THIS WILL MAKE ALL ADD-ONS</div>
    <div class="warningText pb15">AND MEMBERS INACTIVE</div>
    <div class="dialogText">ARE YOU SURE YOU WANT</div>
    <div class="dialogText">TO CHANGE FROM</div>
    <div class="dialogText pb15">{{ dialogTitle.toUpperCase() }}?</div>
  </header>
  <div class="dialogBottom">
    <div mat-dialog-actions class="spaceBetween Montserrat">
      <button mat-flat-button color="primary" (click)="closeDialog()">
        CANCEL
      </button>
      <div class="customRedButton right-align">
        <button mat-flat-button (click)="changePolicyPlan()">CONFIRM</button>
      </div>
    </div>
  </div>
</div>

<!-- CHANGE POLICY STATUS CONFIRMATION -->
<div
  *ngIf="dialogType === 'togglePolicyStatusDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
  (keydown.enter)="togglePolicyStatusToInactive()"
>
  <header class="center-text">
    <div class="warningText">THIS WILL MAKE ALL ADD-ONS</div>
    <div class="warningText pb15">AND MEMBERS INACTIVE</div>
    <div class="dialogText">ARE YOU SURE YOU WANT</div>
    <div class="dialogText">TO CHANGE THE STATUS OF</div>
    <div class="dialogText pb15">{{ dialogTitle.toUpperCase() }}?</div>
  </header>
  <div class="dialogBottom">
    <div mat-dialog-actions class="spaceBetween Montserrat">
      <button mat-flat-button color="primary" (click)="closeDialog()">
        CANCEL
      </button>
      <div class="customRedButton right-align">
        <button mat-flat-button (click)="togglePolicyStatusToInactive()">
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</div>

<!-- CREATE NEW USER ROLE -->
<div
  *ngIf="dialogType === 'newUserRoleDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
  (keydown.enter)="
    roleName.value &&
      !rolesRightsService.userRoleNameExists(roleName.value) &&
      createUserRole(roleName.value)
  "
>
  <header class="center-text">
    <h1 class="whiteColor maxWidth">
      {{ dialogTitle.toUpperCase() }}
    </h1>
  </header>
  <div class="file-form-container">
    <div class="flex">
      <mat-form-field appearance="outline">
        <mat-label>ROLE NAME</mat-label>
        <input
          autocomplete="off"
          oninput="this.value = this.value.toUpperCase()"
          matInput
          cdkFocusInitial
          #roleName="matInput"
          [inputMaxLength]="40"
        />
      </mat-form-field>
    </div>
    <div class="center-text">
      <h4
        *ngIf="
          rolesRightsService.userRoleNameExists(roleName.value) &&
          !rolesRightsService.loading
        "
        class="orangeColor"
      >
        THIS NAME ALREADY EXISTS
      </h4>
    </div>
  </div>
  <div>
    <div mat-dialog-actions>
      <div>
        <button mat-flat-button color="primary" (click)="closeDialog()">
          CANCEL
        </button>
      </div>
      <button
        [disabled]="
          !roleName.value ||
          rolesRightsService.userRoleNameExists(roleName.value)
        "
        mat-flat-button
        color="primary"
        (click)="createUserRole(roleName.value)"
        cdkFocusInitial
        class="right-align"
      >
        CREATE
      </button>
    </div>
  </div>
</div>

<!-- SET NEW CURRENT REFERENCE NUMBER -->
<div
  *ngIf="dialogType === 'referenceNumberDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
  (keydown.enter)="
    referenceNumber.value &&
      referenceNumber.valid &&
      searchReferenceNumberDuplicate(referenceNumber.value);
    closeDialog()
  "
>
  <header class="center-text">
    <div class="warningText">THIS WILL BE USED FOR ALL ONWARD</div>
    <div class="warningText">CHANGES UNTIL YOU CONFIRM THIS POLICY</div>
    <div class="warningText pb15">OR SELECT ANOTHER POLICY</div>
    <div class="dialogText">SET THE CURRENT</div>
    <div class="dialogText pb15">REFERENCE NUMBER</div>
  </header>
  <div class="pt15">
    <div class="flex">
      <mat-form-field appearance="outline">
        <mat-label>REFERENCE NUMBER</mat-label>
        <input
          matInput
          cdkFocusInitial
          #referenceNumber="ngModel"
          [(ngModel)]="referenceNum"
          minlength="3"
          maxlength="12"
          alphanumericOnly
        />
        <mat-error *ngIf="referenceNumber.invalid && referenceNumber.touched"
          >USE 3 TO 12 CHARACTERS</mat-error
        >
      </mat-form-field>
    </div>
  </div>
  <div>
    <div mat-dialog-actions>
      <div>
        <button mat-flat-button color="primary" (click)="closeDialog()">
          CANCEL
        </button>
      </div>
      <button
        [disabled]="!referenceNumber.value || referenceNumber.invalid"
        mat-flat-button
        color="primary"
        (click)="
          searchReferenceNumberDuplicate(referenceNumber.value); closeDialog()
        "
        cdkFocusInitial
        class="right-align"
      >
        SET
      </button>
    </div>
  </div>
</div>

<!-- DELETE ROLE CONFIRMATION -->
<div
  *ngIf="dialogType === 'deleteRoleDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <div class="warningText">ARE YOU SURE YOU</div>
    <div class="warningText pb15">WANT TO DELETE</div>
    <div class="dialogText pb15">
      {{ dialogTitle.toUpperCase() }}
    </div>
  </header>
  <div class="dialogBottom">
    <div mat-dialog-actions class="spaceBetween Montserrat">
      <button mat-flat-button color="primary" (click)="closeDialog()">
        CANCEL
      </button>
      <div class="customRedButton right-align">
        <button
          mat-flat-button
          (click)="
            rolesRightsService.deleteRole(
              rolesRightsService.selectedRole &&
                rolesRightsService.selectedRole.id
                ? rolesRightsService.selectedRole.id
                : undefined
            );
            closeDialog()
          "
          cdkFocusInitial
        >
          DELETE
        </button>
      </div>
    </div>
  </div>
</div>

<!-- REVERSE TRANSACTION CONFIRMATION -->
<div
  *ngIf="dialogType === 'reverseTransactionDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="header">
    <div class="warningText">THIS WILL UNDO THE PRODUCTS AND</div>
    <div class="warningText pb15">PERIODS THE TRANSACTION PAID FOR</div>
    <div class="warningText">ARE YOU SURE YOU</div>
    <div class="warningText pb15">WANT TO REVERSE</div>
    <div class="dialogText center-text">
      {{ "RECEIPT " + selectedItem.receiptNumber }}<br />{{
        dateTimeService.timestampToDate(selectedItem.transactionDate)
          | date : "dd/MM/yyyy"
      }}<br />{{ selectedItem.amount | currencyToString }}<br />{{
        selectedItem.method
      }}
    </div>
  </header>
  <div class="pt15">
    <form class="flex" [formGroup]="reasonForm">
      <mat-form-field appearance="outline" color="primary">
        <mat-label>REASON FOR REVERSAL</mat-label>
        <mat-select formControlName="reason">
          <mat-option *ngFor="let reason of reversalReasons" [value]="reason">
            {{ reason }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <div>
    <div mat-dialog-actions>
      <div>
        <button
          mat-flat-button
          color="primary"
          class="Montserrat"
          (click)="closeDialog()"
        >
          CANCEL
        </button>
      </div>
      <div class="customRedButton right-align">
        <button
          mat-flat-button
          color="warn"
          class="Montserrat"
          (click)="
            closeDialog();
            transactionService.reverseOrMoveTransaction(
              selectedItem,
              reasonForm.get('reason')?.value
            )
          "
          [disabled]="reasonForm.invalid"
        >
          REVERSE
        </button>
      </div>
    </div>
  </div>
</div>

<!-- CHANGE POLICY MEMBER STATUS TO CLAIMED CONFIRMATION -->
<div
  *ngIf="dialogType === 'policyMemberToClaimedDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
  (keydown.enter)="changeMemberToClaimed(); closeDialog()"
>
  <header class="header">
    <div class="dialogText">ARE YOU SURE YOU WANT TO</div>
    <div class="dialogText pb15">CHANGE THE STATUS OF</div>
    <div class="dialogText pb15">{{ dialogTitle }}</div>
    <div class="dialogText pb15">TO CLAIMED</div>
  </header>
  <div>
    <div mat-dialog-actions>
      <div class="customRedButton">
        <button
          mat-flat-button
          color="warn"
          class="Montserrat"
          (click)="closeDialog()"
        >
          CANCEL
        </button>
      </div>
      <div class="right-align">
        <button
          mat-flat-button
          color="primary"
          (click)="changeMemberToClaimed(); closeDialog()"
          class="Montserrat"
        >
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</div>

<!-- REVERT POLICY MEMBER STATUS FROM CLAIMED -->
<div
  *ngIf="dialogType === 'policyMemberRevertClaimDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
  (keydown.enter)="revertMemberClaimedStatus(); closeDialog()"
>
  <header class="center-text">
    <div class="dialogText">ARE YOU SURE YOU WANT</div>
    <div class="dialogText pb15">TO CHANGE THE STATUS OF</div>
    <div class="dialogText pb15">{{ dialogTitle.toUpperCase() }}</div>
    <div class="dialogText pb15">BACK TO ACTIVE</div>
  </header>
  <div class="dialogBottom">
    <div mat-dialog-actions class="spaceBetween Montserrat">
      <button mat-flat-button color="primary" (click)="closeDialog()">
        CANCEL
      </button>
      <div class="customRedButton right-align">
        <button
          mat-flat-button
          color="primary"
          (click)="revertMemberClaimedStatus(); closeDialog()"
        >
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</div>

<!-- LOGOUT CONFIRMATION -->
<div
  *ngIf="dialogType === 'logoutDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
  (keydown.enter)="logOut()"
>
  <div class="center-text dialogText maxWidth pt5">
    {{ dialogTitle }}
  </div>
  <div>
    <div mat-dialog-actions>
      <div>
        <button
          mat-flat-button
          color="primary"
          class="Montserrat"
          (click)="closeDialog()"
        >
          CANCEL
        </button>
      </div>
      <button
        mat-flat-button
        color="primary"
        (click)="logOut()"
        class="right-align Montserrat"
      >
        CONFIRM
      </button>
    </div>
  </div>
</div>

<!-- PASSWORD RESET LINK CONFIRMATION -->
<div
  *ngIf="dialogType === 'resetPasswordLinkDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
  (keydown.enter)="
    closeDialog(); userService.forgotPassword(selectedItem.email)
  "
>
  <header class="header">
    <div class="center-text dialogText pb15">
      ARE YOU SURE YOU SEND A<br />PASSWORD RESET LINK TO
    </div>
    <div class="center-text dialogText">{{ selectedItem.email }}?</div>
  </header>
  <div>
    <div mat-dialog-actions>
      <div>
        <button
          mat-flat-button
          color="primary"
          class="Montserrat"
          (click)="closeDialog()"
        >
          CANCEL
        </button>
      </div>
      <button
        mat-flat-button
        color="primary"
        (click)="closeDialog(); userService.forgotPassword(selectedItem.email)"
        class="right-align Montserrat"
      >
        CONFIRM
      </button>
    </div>
  </div>
</div>

<!-- DELETE USER CONFIRMATION -->
<div
  *ngIf="dialogType === 'deleteUserDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="header">
    <div class="center-text warningText pb15">
      ARE YOU SURE YOU WANT TO<br />DELETE THE FOLLOWING USER?
    </div>
    <div class="center-text dialogText">
      {{ selectedItem.displayName }}<br />
      {{ selectedItem.email }}
    </div>
  </header>
  <div>
    <div mat-dialog-actions>
      <div>
        <button
          mat-flat-button
          color="primary"
          class="Montserrat"
          (click)="closeDialog()"
        >
          CANCEL
        </button>
      </div>
      <div class="customRedButton right-align">
        <button
          mat-flat-button
          (click)="closeDialog(); userService.deleteUser(selectedItem.uid)"
          class="right-align Montserrat"
        >
          DELETE
        </button>
      </div>
    </div>
  </div>
</div>

<!-- ACCOUNT CREATED SUCCESSFULLY WELCOME -->
<div
  *ngIf="dialogType === 'accountCreatedSuccessfullyDialog'"
  class="account-created-container"
  [style.--dynamicColor]="
    userService.userData?.roleId && userService.userData?.roleId !== ''
      ? 'var(--greenColor)'
      : 'var(--lightYellowColor)'
  "
  (keydown.escape)="closeDialog()"
>
  <section *ngIf="userService.userData">
    <h1 class="whiteColor center-text">
      ACCOUNT CREATED<br />{{
        userService.userData.roleId === undefined ||
        userService.userData.roleId === ""
          ? ""
          : "SUCCESSFULLY"
      }}
    </h1>
    <h3
      *ngIf="
        userService.userData?.roleId && userService.userData?.roleId !== ''
      "
      class="activeColor center-text"
    >
      YOUR ROLE AND DISPLAY NAME<br />
      WAS AUTOMATICALLY ASSIGNED
    </h3>
    <h3
      *ngIf="
        !userService.userData?.roleId || userService.userData?.roleId === ''
      "
      class="warningColor center-text"
    >
      THERE WAS A PROBLEM ASSIGNING YOUR<br />
      PRESET ROLE/DISPLAY NAME. YOU THEREFORE<br />
      HAVE NO RIGHTS AND CAN'T USE THE SYSTEM.
    </h3>
    <h3
      *ngIf="
        !userService.userData?.roleId || userService.userData?.roleId === ''
      "
      class="requestedColor center-text"
    >
      CONTACT AN ADMINISTRATOR FOR<br />
      ROLES/RIGHTS MANAGEMENT.
    </h3>
    <h2 class="whiteColor center-text">YOUR DETAILS:</h2>
    <h3 class="whiteColor center-text">
      {{ userService.userData.email }}<br />{{
        userService.userData.displayName ?? "NO DISPLAY NAME"
      }}<br />{{ rolesRightsService.currentUserRole?.name ?? "NO ROLE" }}
    </h3>
    <div class="pageRow center">
      <div mat-dialog-actions>
        <button
          mat-flat-button
          color="primary"
          (click)="closeDialog()"
          class="Montserrat"
        >
          CONTINUE
        </button>
      </div>
    </div>
  </section>
</div>

<!-- SHOW ALL EXISTING ID NUMBERS -->
<div *ngIf="dialogType === 'duplicateMemberIdDialog'" class="dialog-table">
  <header class="header">
    <h1 class="dialogText">{{ dialogTitle }}</h1>
  </header>
  <div>
    <div class="table-container">
      <table
        mat-table
        [dataSource]="searchService.dataSourceSearchResults"
        matSort
        #policySearchTable
      >
        <!-- Policy Number Column -->
        <ng-container matColumnDef="policy">
          <th mat-header-cell *matHeaderCellDef>POLICY</th>
          <td
            mat-cell
            *matCellDef="let element"
            [innerHTML]="element.policyNumber"
          ></td>
        </ng-container>
        <!-- Member Type Name Column -->
        <ng-container matColumnDef="memberType">
          <th mat-header-cell *matHeaderCellDef>MEMBER TYPE</th>
          <td mat-cell *matCellDef="let element">
            {{
              planService.allPlans ? getMemberTypeNameById(element).text : ""
            }}
          </td>
        </ng-container>
        <!-- Id Number Column -->
        <ng-container matColumnDef="idNumber">
          <th mat-header-cell *matHeaderCellDef>ID NUMBER</th>
          <td
            mat-cell
            *matCellDef="let element"
            [innerHTML]="element.idNumber"
          ></td>
        </ng-container>
        <!-- First Name Column -->
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef>FIRST NAME</th>
          <td
            mat-cell
            *matCellDef="let element"
            [innerHTML]="element.firstName"
          ></td>
        </ng-container>
        <!-- Last Name Column -->
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef>LAST NAME</th>
          <td
            mat-cell
            *matCellDef="let element"
            [innerHTML]="element.lastName"
          ></td>
        </ng-container>
        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>STATUS</th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="policyService.getAppropriateMemberStatus(element).class"
          >
            {{ policyService.getAppropriateMemberStatus(element).text }}
          </td>
        </ng-container>
        <!-- Buttons Column -->
        <ng-container matColumnDef="button">
          <th mat-header-cell *matHeaderCellDef class="width"></th>
          <td mat-cell *matCellDef="let element" matTooltip="Open In New Tab">
            <a
              *ngIf="rolesRightsService.currentUserRole?.policies?.update"
              mat-icon-button
              (click)="
                onDuplicateIdNumberRowClick(element.policyNumber, $event)
              "
              class="tableIcon-container"
            >
              <mat-icon>open_in_new</mat-icon>
            </a>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedDuplicateMemberId; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedDuplicateMemberId"
          (click)="onDuplicateIdNumberRowClick(row.policyNumber); closeDialog()"
        ></tr>
      </table>
    </div>
    <div mat-dialog-actions>
      <div>
        <button
          mat-flat-button
          color="primary"
          class="Montserrat"
          (click)="closeDialog()"
        >
          CANCEL
        </button>
      </div>
      <div class="customRedButton right-align">
        <button
          *ngIf="rolesRightsService.currentUserRole?.policies?.override"
          mat-flat-button
          (click)="overrideMemberId(); closeDialog()"
          class="Montserrat"
        >
          OVERRIDE
        </button>
      </div>
    </div>
  </div>
</div>

<!-- MEMBER AGE REQUEST/OVERRIDE -->
<div
  *ngIf="dialogType === 'overrideMemberIdAgeDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="header">
    <div class="warningText">POSSIBLY UNDERAGED</div>
    <div class="dialogText">PLEASE VERIFY ID NUMBER</div>
    <div class="dialogText">{{ dialogTitle }}</div>
  </header>
  <div>
    <div mat-dialog-actions>
      <div class="customRedButton">
        <button
          mat-flat-button
          color="warn"
          class="Montserrat"
          (click)="closeDialog()"
        >
          CANCEL
        </button>
      </div>
      <div class="right-align">
        <button
          mat-flat-button
          color="primary"
          (click)="overrideMemberIdAge(); closeDialog()"
          class="Montserrat"
        >
          VERIFY
        </button>
      </div>
    </div>
  </div>
</div>

<!-- CREATE NEW OFFLINE TRANSACTION -->
<div
  *ngIf="dialogType === 'offlineTransactionDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <h2 class="whiteColor">
      {{
        selectedItem && policyService.selectedPolicy
          ? "OFFLINE RECEIPT " + dialogTitle
          : "UNIDENTIFIED OFFLINE RECEIPT"
      }}
    </h2>
  </header>
  <div class="pt15 pb15 offlineContainer">
    <form class="flex" [formGroup]="offLineReceiptForm">
      <div class="spaceBetween">
        <mat-form-field appearance="outline" class="offlineField flex">
          <mat-label>DATE</mat-label>
          <input
            autocomplete="off"
            matInput
            formControlName="transactionDate"
            [matDatepicker]="transactionDatePicker"
            [max]="maxDate"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="transactionDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #transactionDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="offlineField flex">
          <mat-label>RECEIPT NO</mat-label>
          <input
            #receiptReferenceNumberInput
            autocomplete="off"
            matInput
            formControlName="receiptReferenceNumber"
            numericOnly
          />
        </mat-form-field>
      </div>

      <div class="custom-form-field fadedWhiteText">
        <div class="spaceBetween">
          <span> NAME & SURNAME</span>
          <span>{{
            selectedItem && policyService.selectedPolicy
              ? primaryMember?.firstName + " " + primaryMember?.lastName
              : "NONE"
          }}</span>
        </div>
      </div>

      <div class="custom-form-field fadedWhiteText borderTop">
        <div class="spaceBetween">
          <span> ID NUMBER </span>
          <span>{{
            selectedItem && policyService.selectedPolicy
              ? primaryMember?.idNumber
              : "NONE"
          }}</span>
        </div>
      </div>

      <div class="custom-form-field fadedWhiteText borderTop">
        <div class="spaceBetween">
          <span> CONTACT NUMBER </span>
          <span>{{
            selectedItem && policyService.selectedPolicy
              ? primaryMember?.cellNumber
              : "NONE"
          }}</span>
        </div>
      </div>

      <div class="spaceBetween">
        <mat-form-field appearance="outline" class="offlineField flex">
          <mat-label>AMOUNT</mat-label>
          <input
            autocomplete="off"
            type="text"
            matInput
            formControlName="amount"
            numericOnly
            (input)="offlineReceiptOnAmountChange($event.target)"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="offlineField flex">
          <mat-label>METHOD</mat-label>
          <mat-select formControlName="method">
            <mat-option [value]="PaymentMethod.OFFLINE_CASH">CASH</mat-option>
            <mat-option [value]="PaymentMethod.OFFLINE_CARD">CARD</mat-option>
            <mat-option [value]="PaymentMethod.OFFLINE_EFT">EFT</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="custom-form-field fadedWhiteText borderBottom">
        <span class="breakWord" [ngClass]="offlineReceiptStatus.class">
          {{ offlineReceiptStatus.text }}
        </span>
        <span class="breakWord">
          {{ periodsPaid }}
        </span>
      </div>
    </form>
  </div>
  <div>
    <div mat-dialog-actions>
      <div>
        <button mat-flat-button color="primary" (click)="closeDialog()">
          CANCEL
        </button>
      </div>
      <button
        mat-flat-button
        color="primary"
        cdkFocusInitial
        class="right-align"
        [disabled]="offLineReceiptForm.invalid"
        (click)="updateOfflineTransactions()"
      >
        CAPTURE
      </button>
    </div>
  </div>
</div>

<!-- CREATE NEW CANCELLED TRANSACTION -->
<div
  *ngIf="dialogType === 'cancelledTransactionDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <h2 class="whiteColor">
      {{ "CANCELLED OFFLINE RECEIPT" }}
    </h2>
  </header>
  <div class="pt15 pb15 offlineContainer">
    <form class="flex" [formGroup]="cancelledReceiptForm">
      <div class="spaceBetween">
        <mat-form-field appearance="outline" class="offlineField flex">
          <mat-label>DATE</mat-label>
          <input
            autocomplete="off"
            matInput
            formControlName="transactionDate"
            [matDatepicker]="transactionDatePicker"
            [max]="maxDate"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="transactionDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #transactionDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="offlineField flex">
          <mat-label>RECEIPT NO</mat-label>
          <input
            #receiptReferenceNumberInput
            autocomplete="off"
            matInput
            formControlName="receiptReferenceNumber"
            numericOnly
          />
        </mat-form-field>
      </div>

      <div class="custom-form-field fadedWhiteText">
        <div class="spaceBetween">
          <span> NAME & SURNAME</span>
          <span>{{
            selectedItem && policyService.selectedPolicy
              ? primaryMember?.firstName + " " + primaryMember?.lastName
              : "NONE"
          }}</span>
        </div>
      </div>

      <div class="custom-form-field fadedWhiteText borderTop">
        <div class="spaceBetween">
          <span> ID NUMBER </span>
          <span>{{
            selectedItem && policyService.selectedPolicy
              ? primaryMember?.idNumber
              : "NONE"
          }}</span>
        </div>
      </div>

      <div class="custom-form-field fadedWhiteText borderTop">
        <div class="spaceBetween">
          <span> CONTACT NUMBER </span>
          <span>{{
            selectedItem && policyService.selectedPolicy
              ? primaryMember?.cellNumber
              : "NONE"
          }}</span>
        </div>
      </div>

      <div class="spaceBetween">
        <mat-form-field appearance="outline" class="offlineField flex">
          <mat-label>AMOUNT</mat-label>
          <input
            autocomplete="off"
            type="text"
            matInput
            formControlName="amount"
            numericOnly
            (input)="cancelledReceiptOnAmountChange($event.target)"
          />
        </mat-form-field>

        <div
          class="custom-form-field fadedWhiteText borderBottom borderTop flex"
        >
          <div class="spaceBetween">
            <span> METHOD </span>
            <span>
              {{ PaymentMethod.NONE }}
            </span>
          </div>
        </div>
      </div>

      <div class="custom-form-field fadedWhiteText borderBottom">
        <span class="breakWord" [ngClass]="cancelledReceiptStatus.class">
          {{ transactionStatus.CANCELLED }}
        </span>
      </div>
    </form>
  </div>
  <div>
    <div mat-dialog-actions>
      <div>
        <button mat-flat-button color="primary" (click)="closeDialog()">
          CANCEL
        </button>
      </div>
      <button
        mat-flat-button
        color="primary"
        cdkFocusInitial
        class="right-align"
        [disabled]="cancelledReceiptForm.invalid"
        (click)="updateCancelledTransactions()"
      >
        CAPTURE
      </button>
    </div>
  </div>
</div>

<!-- CREATE NEW POLICY MANUAL TRANSACTION -->
<div
  *ngIf="dialogType === 'manualReceiptDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <h2 class="whiteColor">NEW MANUAL RECEIPT</h2>
    <h3 class="whiteColor center-text">
      {{ maxDate | date : "dd/MM/yyyy HH:mm" }}<br />{{ "CASH" }}
    </h3>
  </header>
  <div class="pt15 pb15">
    <form class="flex" [formGroup]="manualReceiptForm">
      <mat-form-field appearance="outline">
        <mat-label>PAY UP TO</mat-label>
        <mat-select formControlName="amount">
          <ng-container *ngIf="periods">
            <mat-option *ngFor="let period of periods" [value]="period.premium"
              >{{ period.month }} - {{ period.premiumFormatted }}</mat-option
            >
          </ng-container>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <div>
    <div mat-dialog-actions>
      <div>
        <button mat-flat-button color="primary" (click)="closeDialog()">
          CANCEL
        </button>
      </div>
      <button
        mat-flat-button
        color="primary"
        cdkFocusInitial
        class="right-align"
        (click)="createManualTransaction()"
        [disabled]="manualReceiptForm.controls['amount'].invalid"
      >
        CAPTURE
      </button>
    </div>
  </div>
</div>

<!-- CREATE NEW DEBIT ORDER -->
<div
  *ngIf="dialogType === 'debitOrderDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <h2 class="whiteColor">DEBIT ORDER</h2>
  </header>

  <form class="flex" [formGroup]="debitOrderForm">
    <div class="spaceBetween">
      <!-- Bank Select -->
      <mat-form-field appearance="outline" class="flex">
        <mat-label>BANK</mat-label>
        <mat-select formControlName="bank">
          <mat-option *ngFor="let bank of banks" [value]="bank">{{
            bank
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Branch Code Select -->
      <mat-form-field appearance="outline" class="flex">
        <mat-label>BRANCH CODE</mat-label>
        <mat-select formControlName="branchCode">
          <mat-option *ngFor="let code of branchCodes" [value]="code">{{
            code
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="offlineContainer">
      <div class="spaceBetween">
        <mat-form-field appearance="outline" class="flex">
          <mat-label>ACCOUNT NUMBER</mat-label>
          <input
            autocomplete="off"
            matInput
            formControlName="accountNumber"
            numericOnly
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex">
          <mat-label>ACCOUNT TYPE</mat-label>
          <mat-select formControlName="accountType">
            <mat-option
              *ngFor="let type of bankAccountTypes; let i = index"
              [value]="i + 1"
            >
              {{ type }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="spaceBetween">
        <mat-form-field appearance="outline" class="flex">
          <mat-label>ACCOUNT NAME</mat-label>
          <input
            autocomplete="off"
            type="text"
            oninput="this.value = this.value.toUpperCase()"
            matInput
            formControlName="accountName"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex">
          <mat-label>ACCOUNT HOLDER ID</mat-label>
          <input
            autocomplete="off"
            type="text"
            matInput
            formControlName="accountHolderId"
            numericOnly
          />
        </mat-form-field>
      </div>

      <div class="spaceBetween">
        <mat-form-field appearance="outline" class="flex">
          <mat-label>DEDUCTION DAY</mat-label>
          <mat-select formControlName="deductionDay">
            <mat-option
              *ngFor="let day of allowedDebitOrderDeductionDays"
              [value]="day"
              >{{ day }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <!-- Hidden input to hold the value for the form control -->
        <input
          type="hidden"
          formControlName="changeImmediately"
          [value]="false"
        />

        <!-- Display static text to the user -->
        <mat-form-field appearance="outline" class="flex">
          <mat-label>CHANGE</mat-label>
          <input matInput [value]="'NEXT DEBIT RUN'" readonly />
        </mat-form-field>
      </div>

      <div class="spaceBetween">
        <mat-form-field appearance="outline" class="flex">
          <mat-label>STATUS</mat-label>
          <mat-select formControlName="status">
            <mat-option value="ACTIVE">ACTIVE</mat-option>
            <mat-option value="INACTIVE">INACTIVE</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex">
          <mat-label>REFERENCE NUMBER</mat-label>
          <input
            autocomplete="off"
            matInput
            formControlName="referenceNumber"
            alphanumericOnly
          />
        </mat-form-field>
      </div>
    </div>
  </form>
  <div mat-dialog-actions class="spaceBetween">
    <button mat-flat-button color="primary" (click)="closeDialog()">
      CANCEL
    </button>
    <button
      *ngIf="this.rolesRightsService.currentUserRole?.debitOrders?.update"
      mat-flat-button
      color="primary"
      cdkFocusInitial
      class="right-align"
      [disabled]="debitOrderForm.invalid || !hasDebitOrderFormChanged()"
      (click)="updateDebitOrder()"
    >
      UPDATE
    </button>
  </div>
</div>

<!-- RETRY NETCASH BATCH DIALOG -->
<div
  *ngIf="dialogType === 'retryNetcashBatchDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header>
    <div class="center-text">
      <div class="warningText">ARE YOU SURE YOU WANT TO</div>
      <div class="warningText pb15">RETRY NETCASH BATCH</div>
    </div>
    <h3 class="dialogText center-text">
      {{ selectedItem.name }}
    </h3>
  </header>
  <div>
    <div mat-dialog-actions>
      <div>
        <button mat-flat-button color="primary" (click)="closeDialog()">
          CANCEL
        </button>
      </div>
      <button
        mat-flat-button
        color="primary"
        (click)="retryNetcashBatch()"
        cdkFocusInitial
        class="right-align"
      >
        RETRY
      </button>
    </div>
  </div>
</div>

<!-- NETCASH BATH DETAILED ERRORS DIALOG -->
<div *ngIf="dialogType === 'netcashBatchErrorsDialog'" class="dialog-container">
  <header class="header">
    <div class="dialogText center-text">
      DETAILED ERRORS FOR NETCASH BATCH<br />{{ selectedItem.name }}
    </div>
  </header>
  <div *ngIf="selectedItem.errors" class="table-container">
    <table mat-table [dataSource]="selectedItem.errors">
      <!-- Account Reference Column -->
      <ng-container matColumnDef="accountRef">
        <th mat-header-cell *matHeaderCellDef>ACCOUNT</th>
        <td mat-cell *matCellDef="let element">{{ element.accountRef }}</td>
      </ng-container>

      <!-- Message Column -->
      <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef>ERROR MESSAGE</th>
        <td mat-cell *matCellDef="let element">
          {{ element.message }}
        </td>
      </ng-container>

      <!-- Amount Column -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>AMOUNT</th>
        <td mat-cell *matCellDef="let element">
          {{ element.amount | currencyToString }}
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="['accountRef', 'message', 'amount']"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['accountRef', 'message', 'amount']"
      ></tr>
    </table>
  </div>

  <div class="pt25 pageRow justify-right">
    <div mat-dialog-actions>
      <button
        mat-flat-button
        color="primary"
        (click)="closeDialog()"
        class="Montserrat"
      >
        CLOSE
      </button>
    </div>
  </div>
</div>

<!-- CHANGE OR TRANSFER UNALLOCATED FUNDS-->
<div
  *ngIf="
    dialogType === 'editUnallocatedFundsDialog' ||
    dialogType === 'transferUnallocatedFundsDialog'
  "
  (keydown.escape)="closeDialog()"
  class="dialog-container"
>
  <header class="center-text">
    <div
      *ngIf="dialogType === 'editUnallocatedFundsDialog'"
      class="dialogText pb15"
    >
      CHANGE UNALLOCATED FUNDS
    </div>
    <div
      *ngIf="dialogType === 'transferUnallocatedFundsDialog'"
      class="dialogText pb15"
    >
      TRANSFER<br />UNALLOCATED
    </div>
    <div class="dialogText">CURRENT AMOUNT</div>
    <div class="dialogText">
      {{
        policyService.selectedPolicy?.unallocatedBalance ?? 0 | currencyToString
      }}
    </div>
  </header>
  <div class="pt15">
    <form class="flex" [formGroup]="amendUnallocatedFundsForm">
      <div class="pageRow">
        <div *ngIf="dialogType === 'editUnallocatedFundsDialog'" class="pr15">
          <button color="primary" mat-fab (click)="toggleAddFunds()">
            <mat-icon>{{ addFunds ? "add" : "remove" }}</mat-icon>
          </button>
        </div>
        <div class="flex">
          <mat-form-field appearance="outline">
            <mat-label>AMOUNT</mat-label>
            <input
              autocomplete="off"
              type="number"
              matInput
              formControlName="amount"
              inputmode="numeric"
            />
          </mat-form-field>
        </div>
      </div>
      <div
        *ngIf="
          !transactionService.isValidUnallocatedFunds(
            getNewUnallocatedAmount()
          ) &&
          !addFunds &&
          amendUnallocatedFundsForm.controls['amount'].valid
        "
        class="center-text warningText"
      >
        ENTER A VALID AMOUNT
      </div>
      <div class="center-text dialogText">NEW AMOUNT</div>
      <div class="center-text dialogText">
        {{ getNewUnallocatedAmount() | currencyToString }}
      </div>
      <div
        *ngIf="dialogType !== 'transferUnallocatedFundsDialog'"
        class="flex pt15"
      >
        <mat-form-field *ngIf="!addFunds" appearance="outline" color="primary">
          <mat-label>REASON FOR REVERSAL</mat-label>
          <mat-select formControlName="reverseReason">
            <mat-option *ngFor="let reason of reversalReasons" [value]="reason">
              {{ reason }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="addFunds" appearance="outline" color="primary">
          <mat-label>REASON FOR ADDITION</mat-label>
          <mat-select formControlName="additionReason">
            <mat-option *ngFor="let reason of additionReasons" [value]="reason">
              {{ reason }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div>
    <div mat-dialog-actions>
      <div>
        <button mat-flat-button color="primary" (click)="closeDialog()">
          CANCEL
        </button>
      </div>
      <button
        *ngIf="dialogType === 'transferUnallocatedFundsDialog'"
        mat-flat-button
        color="primary"
        cdkFocusInitial
        class="right-align"
        (click)="handleUnallocatedFundsTransfer()"
        [disabled]="
          !transactionService.isValidUnallocatedFunds(
            getNewUnallocatedAmount()
          ) ||
          amendUnallocatedFundsForm.controls['amount'].invalid ||
          (amendUnallocatedFundsForm.get('amount')?.value &&
            amendUnallocatedFundsForm.get('amount')?.value <= 0)
        "
      >
        NEXT
      </button>
      <button
        *ngIf="dialogType === 'editUnallocatedFundsDialog'"
        mat-flat-button
        color="{{ addFunds ? 'primary' : 'warn' }}"
        cdkFocusInitial
        class="right-align"
        (click)="
          closeDialog();
          transactionService.amendUnallocatedFunds(
            { amount: amendUnallocatedFundsForm.get('amount')?.value },
            addFunds,
            amendUnallocatedFundsForm.get(
              addFunds ? 'additionReason' : 'reverseReason'
            )?.value
          )
        "
        [disabled]="shouldDisableAddReverseButton()"
      >
        {{ addFunds ? "ADD" : "REVERSE" }}
      </button>
    </div>
  </div>
</div>

<!-- USE UNALLOCATED FUNDS TO TRANSACT POLICY PREMIUM -->
<div
  *ngIf="dialogType === 'unallocatedFundsDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <div class="dialogText">ALLOCATE UNALLOCATED FUNDS</div>
    <div class="dialogText pb15">TO PAY POLICY PREMIUM(S)</div>
    <div class="dialogText"></div>
    <div class="dialogText">CURRENT AMOUNT LEFT</div>
    <div class="dialogText">
      {{
        policyService.selectedPolicy?.unallocatedBalance ?? 0 | currencyToString
      }}
    </div>
    <div *ngIf="noOptionsAvailable" class="warningText">
      INSUFFICIENT FUNDS TO COVER PREMIUM(S)
    </div>
  </header>
  <div class="pt15 pb15">
    <form class="flex" [formGroup]="manualReceiptForm">
      <mat-form-field appearance="outline">
        <mat-label>PAY UP TO</mat-label>
        <mat-select formControlName="amount">
          <ng-container *ngIf="periods">
            <ng-container *ngFor="let period of periods">
              <mat-option
                *ngIf="
                  period.premium <=
                  (policyService.selectedPolicy?.unallocatedBalance ?? 0)
                "
                [value]="period.premium"
              >
                {{ period.month }} - {{ period.premiumFormatted }}
              </mat-option>
            </ng-container>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <div>
    <div mat-dialog-actions>
      <div>
        <button mat-flat-button color="primary" (click)="closeDialog()">
          CANCEL
        </button>
      </div>
      <button
        mat-flat-button
        color="primary"
        cdkFocusInitial
        class="right-align"
        (click)="createManualTransaction(true)"
        [disabled]="manualReceiptForm.controls['amount'].invalid"
      >
        ALLOCATE
      </button>
    </div>
  </div>
</div>

<!-- EDIT THE LAST PERIOD PAID OF A PRODUCT -->
<div
  *ngIf="dialogType === 'productPaymentStatusDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <div class="dialogText">
      EDIT PAID UP TO DATE<br />OF
      {{
        transactionService.getProduct(selectedItem.id)?.name
          ? transactionService.getProduct(selectedItem.id)?.name
          : "-"
      }}
    </div>
  </header>
  <div class="pt15 pb15">
    <form class="flex" [formGroup]="lastPeriodPaidForm">
      <mat-form-field appearance="outline">
        <mat-label>PAID UP TO DATE</mat-label>
        <input
          autocomplete="off"
          matInput
          formControlName="lastPeriodPaid"
          [matDatepicker]="lastPeriodPaidPicker"
          [min]="minDate"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="lastPeriodPaidPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker
          #lastPeriodPaidPicker
          startView="multi-year"
          (monthSelected)="monthYearSelected($event, lastPeriodPaidPicker)"
        ></mat-datepicker>
      </mat-form-field>
      <mat-form-field *ngIf="!addFunds" appearance="outline" color="primary">
        <mat-label>REASON FOR CHANGE</mat-label>
        <mat-select formControlName="reason">
          <mat-option
            *ngFor="let reason of lastPeriodPaidAmendmentReasons"
            [value]="reason"
          >
            {{ reason }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <div>
    <div mat-dialog-actions>
      <div>
        <button mat-flat-button color="primary" (click)="closeDialog()">
          CANCEL
        </button>
      </div>
      <div class="right-align customRedButton">
        <button
          mat-flat-button
          cdkFocusInitial
          (click)="handleProductPaymentStatusOverride()"
          [disabled]="
            lastPeriodPaidForm.invalid ||
            !dateTimeService.hasDateChanged(
              lastPeriodPaidForm.get('lastPeriodPaid')?.value,
              selectedItem.lastPeriodPaid
            )
          "
        >
          SET
        </button>
      </div>
    </div>
  </div>
</div>

<!-- LAST PERIOD PAID EFFECT WARNING DIALOG -->
<div
  *ngIf="dialogType === 'lastPeriodPaidWarningDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <div class="dialogText pb15">THIS CHANGE WILL CAUSE THE FOLLOWING:</div>
    <div class="warningText pb15">{{ dialogTitle }}</div>
    <div class="dialogText">ARE YOU SURE YOU</div>
    <div class="dialogText pb15">WANT TO CONTINUE?</div>
  </header>
  <div class="dialogBottom">
    <div mat-dialog-actions class="spaceBetween">
      <button mat-flat-button color="primary" (click)="closeDialog()">
        CANCEL
      </button>
      <div class="customRedButton">
        <button
          mat-flat-button
          (click)="executeProductPaymentStatusOverride()"
          cdkFocusInitial
        >
          CONTINUE
        </button>
      </div>
    </div>
  </div>
</div>

<!-- EDIT THE INTENDED PAYMENT DAY OF THE SELECTED POLICY -->
<div
  *ngIf="dialogType === 'intendedPaymentDayDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <div class="dialogText">
      EDIT PAYMENT DAY OF
      {{ selectedItem.policyNumber ? selectedItem.policyNumber : "-" }}
    </div>
  </header>
  <div class="pt15 pb15">
    <form class="flex" [formGroup]="intendedPaymentDayForm">
      <mat-form-field appearance="outline">
        <mat-label>PAYMENT DAY</mat-label>
        <input
          formControlName="intendedPaymentDay"
          autocomplete="off"
          oninput="this.value = this.value.toUpperCase()"
          matInput
          cdkFocusInitial
          numericOnly
        />
        <mat-error
          *ngIf="
            intendedPaymentDayForm.get('intendedPaymentDay')?.hasError('min') ||
            intendedPaymentDayForm.get('intendedPaymentDay')?.hasError('max')
          "
          class="center-text"
        >
          PAYMENT DAY MUST BE BETWEEN 1 AND 31!
        </mat-error>
        <mat-error
          *ngIf="
            intendedPaymentDayForm
              .get('intendedPaymentDay')
              ?.hasError('invalidDay')
          "
          class="center-text"
        >
          PAYMENT DAY MUST BE:
          {{ allowedDebitOrderDeductionDays.join(", ") }}! (ACTIVE DEBIT ORDER)
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" color="primary">
        <mat-label>REASON FOR CHANGE</mat-label>
        <input
          formControlName="reason"
          autocomplete="off"
          oninput="this.value = this.value.toUpperCase()"
          matInput
        />
      </mat-form-field>
    </form>
  </div>
  <div>
    <div mat-dialog-actions>
      <div>
        <button mat-flat-button color="primary" (click)="closeDialog()">
          CANCEL
        </button>
      </div>
      <div class="right-align customRedButton">
        <button
          mat-flat-button
          cdkFocusInitial
          class="right-align"
          (click)="handleIntendedPaymentDayOverride()"
          [disabled]="
            intendedPaymentDayForm.invalid ||
            intendedPaymentDayForm.get('intendedPaymentDay')?.value ==
              selectedItem.intendedPaymentDay ||
            intendedPaymentDayForm
              .get('intendedPaymentDay')
              ?.hasError('invalidDay')
          "
        >
          SET
        </button>
      </div>
    </div>
  </div>
</div>

<!-- SEARCH FOR POLICY TO TRANSFER SELECTED TRANSACTION TO -->
<div *ngIf="dialogType === 'transferTransactionDialog'" class="dialog-table">
  <header class="header pageRow">
    <div class="dialogText center-text">
      SEARCH AND SELECT A POLICY TO<br />TRANSFER THE FOLLOWING TRANSACTION TO
    </div>
    <div
      *ngIf="
        dataSourceSelectedTransaction && dataSourceSelectedTransaction.data
      "
      class="table-container pb15"
    >
      <table
        *ngIf="
          dataSourceSelectedTransaction &&
          dataSourceSelectedTransaction.data.length > 0
        "
        mat-table
        [dataSource]="dataSourceSelectedTransaction"
      >
        <!-- Receipt Number Column -->
        <ng-container matColumnDef="receiptNumber">
          <th mat-header-cell *matHeaderCellDef>RECEIPT</th>
          <td mat-cell *matCellDef="let element">
            <a
              [routerLink]="['/transaction-summary', element.id]"
              class="custom-link"
            >
              {{ element.receiptNumber ?? "NEW" }}
            </a>
          </td>
        </ng-container>
        <!-- Date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>DATE</th>
          <td mat-cell *matCellDef="let element">
            <a
              [routerLink]="['/transaction-summary', element.id]"
              class="custom-link"
            >
              {{
                dateTimeService.timestampToDate(element.transactionDate)
                  | date : "dd/MM/yyyy"
              }}
            </a>
          </td>
        </ng-container>

        <!-- Amount Column -->
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>AMOUNT</th>
          <td mat-cell *matCellDef="let element">
            <a
              [routerLink]="['/transaction-summary', element.transactionId]"
              class="custom-link"
              [ngClass]="{
                redStrikethrough: element.status === 'REVERSED'
              }"
            >
              {{ element.amount | currencyToString }}
            </a>
          </td>
        </ng-container>

        <!-- Method Column -->
        <ng-container matColumnDef="method" class="minWidth">
          <th mat-header-cell *matHeaderCellDef class="minWidth">METHOD</th>
          <td mat-cell *matCellDef="let element" class="minWidth">
            <div class="custom-cell">
              <div class="tableIcon-container">
                <mat-icon>{{
                  element.status.startsWith("TRANSFER") ||
                  element.method.startsWith("SYSTEM")
                    ? "desktop_windows"
                    : element.method.startsWith("ONLINE")
                    ? "cloud_queue"
                    : "cloud_off"
                }}</mat-icon>
              </div>
              <div>
                <a
                  class="custom-link"
                  [routerLink]="['/transaction-summary', element.id]"
                >
                  {{
                    transactionService.formatMethod(
                      element.method,
                      element.status
                    )
                  }}
                </a>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status" class="statusWidth">
          <th mat-header-cell *matHeaderCellDef>STATUS</th>
          <td mat-cell *matCellDef="let element; let i = index">
            <a
              [ngClass]="
                transactionService.getTransactionStatusTextAndClass(
                  element.status
                ).class
              "
              >{{
                transactionService.getTransactionStatusTextAndClass(
                  element.status
                ).text
              }}</a
            >
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedSelectedTransactionColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedSelectedTransactionColumns"
        ></tr>
      </table>
    </div>
    <div class="flex pt15">
      <mat-form-field appearance="outline">
        <mat-label><mat-icon>search</mat-icon></mat-label>
        <input
          type="text"
          matInput
          oninput="this.value = this.value.toUpperCase()"
          (keydown.enter)="searchPolicies($event.target)"
          (focus)="mainService.selectInputText($event)"
          [(ngModel)]="searchService.searchText.moveTransaction"
        />
        <!-- Clear button -->
        <button
          color="primary"
          mat-icon-button
          matSuffix
          *ngIf="searchText"
          (click)="clearSearch()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </header>
  <div>
    <div
      *ngIf="
        searchService.dataSourceSearchMoveTransaction &&
        searchService.dataSourceSearchMoveTransaction.data
      "
      class="table-container pb15"
    >
      <table
        mat-table
        [dataSource]="searchService.dataSourceSearchMoveTransaction"
        matSort
        #policySearchTable
      >
        <!-- Policy Number Column -->
        <ng-container matColumnDef="policy">
          <th mat-header-cell *matHeaderCellDef>POLICY</th>
          <td
            mat-cell
            *matCellDef="let element"
            [innerHTML]="
              element.policyNumber
                | highlight : searchService.searchText.moveTransaction
            "
          ></td>
        </ng-container>
        <!-- Id Number Column -->
        <ng-container matColumnDef="idNumber">
          <th mat-header-cell *matHeaderCellDef>ID NUMBER</th>
          <td
            mat-cell
            *matCellDef="let element"
            [innerHTML]="
              element.idNumber
                | highlight : searchService.searchText.moveTransaction
            "
          ></td>
        </ng-container>
        <!-- Last Name Column -->
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef>LAST NAME</th>
          <td
            mat-cell
            *matCellDef="let element"
            [innerHTML]="
              element.lastName
                | highlight : searchService.searchText.moveTransaction
            "
          ></td>
        </ng-container>
        <!-- First Name Column -->
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef>FIRST NAME</th>
          <td
            mat-cell
            *matCellDef="let element"
            [innerHTML]="
              element.firstName
                | highlight : searchService.searchText.moveTransaction
            "
          ></td>
        </ng-container>
        <!-- Plan Column -->
        <ng-container matColumnDef="plan">
          <th mat-header-cell *matHeaderCellDef>PLAN</th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{
              italic: element.plan === '(NOT FOUND)'
            }"
            [innerHTML]="element.plan"
          ></td>
        </ng-container>
        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>STATUS</th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{
              activeColor: element.status === 'ACTIVE',
              warningColor:
                element.status === 'INACTIVE' || element.status === 'LAPSED',
              waitingColor: element.status === 'WAITING',
              inProgressColor: element.status === 'IN PROGRESS',
              requestedColor: element.status === 'REQUESTED'
            }"
          >
            {{ element.status }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedSearchPolicyColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedSearchPolicyColumns"
          [ngClass]="{ 'selected-row': selectedPolicy?.id === row?.id }"
          (click)="setSelectPolicy(row)"
        ></tr>
      </table>
    </div>
    <div mat-dialog-actions>
      <div>
        <button
          mat-flat-button
          color="primary"
          class="Montserrat"
          (click)="closeDialog()"
        >
          CANCEL
        </button>
      </div>
      <div class="right-align customRedButton">
        <button
          *ngIf="rolesRightsService.currentUserRole?.transactions?.transfer"
          mat-flat-button
          (click)="moveTransaction()"
          class="Montserrat"
          [disabled]="!selectedPolicy"
        >
          MOVE TRANSACTION
        </button>
      </div>
    </div>
  </div>
</div>

<!-- SELECTED DOCUMENTS SIZE ESTIMATION -->
<div *ngIf="dialogType === 'documentSizeDialog'" class="dialog-container">
  <header class="header">
    <div class="dialogText center-text">ESTIMATED DOCUMENT SIZES</div>
  </header>
  <div *ngIf="selectedItem" class="table-container">
    <table mat-table [dataSource]="selectedItem">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>DOCUMENT</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <!-- Size Column -->
      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef>SIZE (KB)</th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            whiteColor: element.size < 900,
            warningColor: element.size >= 900
          }"
        >
          {{ element.size }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['name', 'size']"></tr>
      <tr mat-row *matRowDef="let row; columns: ['name', 'size']"></tr>
    </table>
  </div>

  <div class="pt25 pageRow center">
    <div mat-dialog-actions>
      <button
        mat-flat-button
        color="primary"
        (click)="closeDialog()"
        class="Montserrat"
      >
        CLOSE
      </button>
    </div>
  </div>
</div>

<!-- CREATE NEW USER LOCATION -->
<div
  *ngIf="dialogType === 'newLocationDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <div class="dialogText">NEW LOCATION</div>
  </header>
  <div class="pt15 pb15">
    <form class="flex" [formGroup]="newLocationForm">
      <mat-form-field appearance="outline">
        <mat-label>FULL LOCATION NAME</mat-label>
        <input
          type="text"
          matInput
          formControlName="name"
          oninput="this.value = this.value.toUpperCase()"
          textOnly
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>LOCATION ABBREVIATION</mat-label>
        <input
          type="text"
          matInput
          formControlName="code"
          oninput="this.value = this.value.toUpperCase()"
          textOnly
        />
      </mat-form-field>
    </form>
  </div>
  <div>
    <div mat-dialog-actions>
      <div>
        <button mat-flat-button color="primary" (click)="closeDialog()">
          CANCEL
        </button>
      </div>
      <button
        mat-flat-button
        color="primary"
        cdkFocusInitial
        class="right-align"
        (click)="updateUserLocation()"
        [disabled]="newLocationForm.invalid"
      >
        CAPTURE
      </button>
    </div>
  </div>
</div>

<!-- DELETE USER LOCATION  -->
<div
  *ngIf="dialogType === 'deleteUserLocationDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <div class="warningText">ARE YOU SURE YOU</div>
    <div class="warningText pb15">WANT TO DELETE</div>
    <div class="dialogText pb15">
      {{ dialogTitle.toUpperCase() }}
    </div>
  </header>
  <div class="dialogBottom">
    <div mat-dialog-actions class="spaceBetween">
      <div class="">
        <button mat-flat-button color="primary" (click)="closeDialog()">
          CANCEL
        </button>
      </div>
      <div class="customRedButton right-align">
        <button mat-flat-button (click)="deleteUserLocation()" cdkFocusInitial>
          DELETE
        </button>
      </div>
    </div>
  </div>
</div>

<!-- EDIT A CASHUP -->
<div
  *ngIf="dialogType === 'cashUpDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <h2 class="whiteColor">
      {{ "CASHUP " + dateTimeService.formatDate(selectedItem.createdOn) }}
    </h2>
  </header>
  <div class="pt15 pb15">
    <form class="flex" [formGroup]="cashUpForm">
      <div>
        <mat-form-field appearance="outline">
          <mat-label>CASH DECLARED</mat-label>
          <input
            autocomplete="off"
            type="number"
            matInput
            formControlName="cashDeclared"
            inputmode="numeric"
            decimalOnly
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="pl15">
          <mat-label>CARD DECLARED</mat-label>
          <input
            autocomplete="off"
            type="number"
            matInput
            formControlName="cardDeclared"
            inputmode="numeric"
            decimalOnly
          />
        </mat-form-field>
      </div>
      <mat-form-field appearance="outline">
        <textarea
          matInput
          matTextareaAutosize
          placeholder="TYPE HERE"
          autocomplete="off"
          rows="6"
          formControlName="comment"
          [maxlength]="maxChars"
        ></textarea>
        <div class="flex pt5">
          <span class="fadedWhiteColor right-align"
            >{{ cashUpForm.get("comment")?.value?.length ?? 0 }} /
            {{ maxChars }}</span
          >
        </div>
      </mat-form-field>
    </form>
  </div>
  <div>
    <div mat-dialog-actions>
      <div>
        <button mat-flat-button color="primary" (click)="closeDialog()">
          CANCEL
        </button>
      </div>
      <div class="customRedButton right-align">
        <button mat-flat-button (click)="openDeleteCashUpDialog(selectedItem)">
          DELETE
        </button>
      </div>
      <button
        *ngIf="rolesRightsService.currentUserRole?.transactions?.userMonitoring"
        mat-flat-button
        color="primary"
        cdkFocusInitial
        class="right-align"
        (click)="
          transactionService.updateCashUp(cashUpForm.value, selectedItem.id);
          closeDialog()
        "
        [disabled]="cashUpForm.invalid || !hasCashUpFormChanged()"
      >
        SAVE
      </button>
    </div>
  </div>
</div>

<!-- DELETE A CASHUP  -->
<div
  *ngIf="dialogType === 'deleteCashUpDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <div class="warningText">ARE YOU SURE YOU</div>
    <div class="warningText pb15">WANT TO DELETE</div>
    <div class="dialogText pb15">
      {{ "CASHUP " + dateTimeService.formatDate(selectedItem.createdOn) }}
    </div>
  </header>
  <div class="dialogBottom">
    <div mat-dialog-actions class="spaceBetween">
      <div>
        <button mat-flat-button color="primary" (click)="closeDialog()">
          CANCEL
        </button>
      </div>
      <div
        *ngIf="this.rolesRightsService.currentUserRole?.transactions?.delete"
        class="customRedButton right-align"
      >
        <button
          mat-flat-button
          (click)="transactionService.deleteCashUp(selectedItem); closeDialog()"
          cdkFocusInitial
        >
          DELETE
        </button>
      </div>
    </div>
  </div>
</div>

<!-- NEW BACKUP -->
<div
  *ngIf="dialogType === 'newBackupDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="header">
    <div class="dialogText">NEW BACKUP</div>
  </header>
  <div class="pt15">
    <form class="flex" [formGroup]="backupAndRestoreForm">
      <mat-form-field appearance="outline" color="primary">
        <mat-label>COLLECTIONS TO BACKUP</mat-label>
        <mat-select formControlName="collections" multiple>
          <mat-option
            *ngFor="let collection of collections"
            [value]="collection"
          >
            {{ backupService.getCollectionNameString(collection) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions>
    <div>
      <button
        mat-flat-button
        color="primary"
        class="Montserrat"
        (click)="closeDialog()"
      >
        CANCEL
      </button>
    </div>
    <div class="right-align">
      <button
        mat-flat-button
        color="primary"
        class="Montserrat"
        (click)="
          closeDialog();
          backupService.newBackup(
            backupAndRestoreForm.get('collections')?.value
          )
        "
        [disabled]="backupAndRestoreForm.invalid"
      >
        BACKUP
      </button>
    </div>
  </div>
</div>

<!-- RESTORE BACKUP DIALOG -->
<div
  *ngIf="dialogType === 'restoreBackupDialog'"
  class="dialog-container"
  (keydown.escape)="closeDialog()"
>
  <header class="center-text">
    <h2 class="dialogText">
      ARE YOU SURE YOU WANT TO<br />
      RESTORE THE BACK UP MADE ON<br />
      {{
        dateTimeService.timestampToDate(selectedItem.createdOn)
          | date : "dd/MM/yyyy HH:mm:ss"
      }}?
    </h2>
    <h3 class="warningColor">
      CHANGES MADE AFTER THIS<br />
      DATE IN THE FOLLOWING<br />
      COLLECTIONS WILL BE LOST:
    </h3>
  </header>
  <div>
    <form class="flex" [formGroup]="backupAndRestoreForm">
      <mat-form-field appearance="outline" color="primary">
        <mat-label>COLLECTIONS TO RESTORE</mat-label>
        <mat-select formControlName="collections" multiple>
          <mat-option
            *ngFor="let collection of selectedItem.collections"
            [value]="collection"
          >
            {{ backupService.getCollectionNameString(collection.name) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions>
    <div>
      <button
        mat-flat-button
        color="primary"
        class="Montserrat"
        (click)="closeDialog()"
      >
        CANCEL
      </button>
    </div>
    <div class="customRedButton right-align">
      <button
        mat-flat-button
        class="Montserrat"
        (click)="
          closeDialog();
          backupService.restoreFirestore(
            backupAndRestoreForm.get('collections')?.value
          )
        "
        [disabled]="backupAndRestoreForm.invalid"
      >
        RESTORE
      </button>
    </div>
  </div>
</div>
