import { EventEmitter, Injectable, Output } from '@angular/core';
import { SnackBarService } from './snack-bar.service';
import { Timestamp } from '@firebase/firestore';
import { UserService } from './user.service';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import {
  ImportCount,
  ImportError,
  ImportResult,
  ImportStatus,
  ImportType,
} from '../models/import.model';
import { MatTableDataSource } from '@angular/material/table';
import {
  addDoc,
  collection,
  doc,
  DocumentData,
  DocumentReference,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  startAfter,
  updateDoc,
} from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class ImportService {
  private dbModular = getFirestore();

  selectedImportResult: ImportResult | undefined;
  progressPercentage: number | undefined;
  selectedFile: File | undefined;
  elapsedTime: number | undefined;
  errorMessage: string | undefined;

  uploadingFile: File | undefined;
  isUploading: boolean = false;
  uploadObject: any;

  loading: boolean = true;
  isPaginatingImports: boolean = false;
  isImportsBehind: boolean = false;
  importsLoading: boolean = false;
  loadImportsCalled: boolean = false;

  loadedImports: ImportResult[];
  importResultDoc: DocumentReference<ImportResult>;
  selectedImportResult$: Observable<ImportResult | undefined>;
  importCount: ImportCount | undefined;
  importCountDoc: DocumentReference<ImportCount>;

  dataSourceImports: MatTableDataSource<ImportResult>;
  dataSourceImportResultErrors: MatTableDataSource<ImportError>;

  private importsSubject = new BehaviorSubject<ImportResult[]>([]);
  public imports$ = this.importsSubject.asObservable();
  importCount$: Observable<ImportCount | undefined>;

  lastLoadedImportDoc: DocumentReference<ImportResult> | null = null;

  unsubscribeFromImportsSnapshot: (() => void) | undefined = undefined;

  @Output() loadedImportsUpdated = new EventEmitter<void>();
  @Output() selectedImportResultUpdated = new EventEmitter<void>();

  destroy$ = new Subject<void>();

  constructor(
    private snackBarService: SnackBarService,
    private userService: UserService
  ) {
    this.userService.destroy$.pipe().subscribe(() => this.cleanUp());
  }

  get totalImportCount(): number {
    if (!this.importCount?.count) return 0;
    return Object.entries(this.importCount.count).reduce(
      (a, [, value]) => a + (value || 0),
      0
    );
  }

  get totalLinesString(): string {
    if (
      this.selectedImportResult?.succeeded !== undefined &&
      this.selectedImportResult?.failed !== undefined
    ) {
      return String(
        this.selectedImportResult.succeeded + this.selectedImportResult.failed
      );
    }
    return '';
  }

  get isImportInProgress(): boolean {
    if (!this.loadedImports) {
      return false;
    }
    return this.loadedImports.some(
      (importResult) =>
        importResult.status === ImportStatus.IN_PROGRESS ||
        importResult.status === ImportStatus.READY ||
        importResult.status === ImportStatus.HANDING_OVER
    );
  }

  // Sets the selectedImportResult property to the given importId
  async setSelectedImportResult(importId: string): Promise<void> {
    try {
      const importResultDocRef = doc(this.dbModular, 'imports', importId);

      this.selectedImportResult$ = new Observable<ImportResult>((observer) => {
        const unsubscribe = onSnapshot(importResultDocRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            observer.next({
              ...(docSnapshot.data() as ImportResult),
              id: docSnapshot.id,
            });
          } else {
            observer.error(new Error('No import result found'));
          }
        });

        return () => unsubscribe();
      });

      return new Promise<void>((resolve, reject) => {
        this.selectedImportResult$.pipe(takeUntil(this.destroy$)).subscribe({
          next: (importResult) => {
            if (importResult) {
              this.selectedImportResult = importResult;
              this.selectedImportResultUpdated.emit();
              resolve();
            } else {
              reject(new Error('No import result found'));
            }
          },
          error: (err) => {
            if (err instanceof Error) {
              this.snackBarService.latestError = err.message;
              this.snackBarService.openRedSnackBar(
                'SETTING THE SELECTED IMPORT RESULT FAILED!'
              );
              reject(err);
            }
          },
        });
      });
    } catch (err) {
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
        this.snackBarService.openRedSnackBar(
          'SETTING THE SELECTED IMPORT RESULT FAILED!'
        );
        throw err;
      }
    }
  }

  loadImports(pageIndex: number = 0, pageSize: number = 20): Promise<void> {
    this.loadImportsCalled = true;
    return new Promise<void>((resolve, reject) => {
      this.loading = true;

      if (this.importsSubject.closed) {
        this.importsSubject = new BehaviorSubject<ImportResult[]>([]);
      }

      const importsRef = collection(this.dbModular, 'imports');
      let q = query(importsRef, orderBy('createdOn', 'desc'));

      if (this.lastLoadedImportDoc) {
        q = query(q, startAfter(this.lastLoadedImportDoc));
      }

      q = query(q, limit(pageSize));

      this.unsubscribeFromImportsSnapshot = onSnapshot(
        q,
        async (querySnapshot: QuerySnapshot<DocumentData>) => {
          try {
            const isFromServer = !querySnapshot.metadata.fromCache;
            const hasPendingWrites = querySnapshot.metadata.hasPendingWrites;

            if (isFromServer || (!hasPendingWrites && this.loadImportsCalled)) {
              this.loadImportsCalled = false;
              const isIntentionalPagination =
                this.loadedImports &&
                pageIndex > 0 &&
                (pageIndex + 1) * pageSize > this.loadedImports.length;

              if (isIntentionalPagination) {
                this.isPaginatingImports = true;
              }

              if (!this.importCount) await this.loadImportCount();

              if (this.isPaginatingImports && !isIntentionalPagination) {
                this.isImportsBehind = true;
                this.loading = false;
                resolve();
                return;
              }

              this.importsLoading = true;

              let imports: ImportResult[] = [];
              let lastDoc = null;

              querySnapshot.forEach((doc) => {
                const importData = doc.data() as ImportResult;
                importData.id = doc.id;
                imports.push(importData);
                lastDoc = doc;
              });

              this.lastLoadedImportDoc = lastDoc;

              this.loadedImports = [
                ...(this.loadedImports || []).slice(0, pageIndex * pageSize),
                ...imports,
              ];

              const startIndex = pageIndex * pageSize;
              const endIndex = startIndex + pageSize;
              const importsForPage = this.loadedImports.slice(
                startIndex,
                endIndex
              );

              this.importsSubject.next(this.loadedImports);

              this.updateCurrentImports(importsForPage);
              this.loadedImportsUpdated.emit();
            }
            this.importsLoading = false;
            this.loading = false;
            resolve();
          } catch (err) {
            console.error('Error in loadImports:', err);
            this.importsLoading = false;
            this.loading = false;
            reject(err);
          }
        },
        (error) => {
          console.error('Error fetching snapshot:', error);
          this.loading = false;
          reject(error);
        }
      );
    });
  }

  async loadImportCount(): Promise<void> {
    try {
      const importCountDocRef = doc(this.dbModular, 'metaData', 'imports');

      this.importCount$ = new Observable<ImportCount>((observer) => {
        const unsubscribe = onSnapshot(importCountDocRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            observer.next({
              id: docSnapshot.id,
              ...(docSnapshot.data() as ImportCount),
            });
          } else {
            this.snackBarService.openRedSnackBar(
              'IMPORT COUNT DOCUMENT NOT FOUND!'
            );
            observer.error(new Error('IMPORT COUNT DOCUMENT NOT FOUND!'));
          }
        });

        return () => unsubscribe();
      });

      await new Promise<void>((resolve, reject) => {
        this.importCount$.pipe(takeUntil(this.destroy$)).subscribe({
          next: (imports) => {
            if (imports) {
              this.importCount = imports;
              resolve();
            }
          },
          error: (err) => {
            reject(err);
          },
        });
      });
    } catch (err) {
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
        throw err;
      }
    }
  }

  onFileSelected(event: Event) {
    const allowedExtensions = ['csv'];

    const target = event.target as HTMLInputElement;
    const file = (target.files as FileList)[0];

    if (file) {
      const fileExtension = file.name?.split('.').pop()?.toLowerCase() ?? '';
      const fileType = file.type?.split('/')[1]?.toLowerCase() ?? '';

      if (
        allowedExtensions.includes(fileExtension) ||
        allowedExtensions.includes(fileType)
      ) {
        this.selectedFile = file;
      } else {
        target.value = '';
        this.selectedFile = undefined;
        this.snackBarService.openRedSnackBar('INVALID FILE TYPE');
      }
    }
  }

  async addImportDoc(
    fileId: string,
    fileName: string,
    customName: string,
    importType: ImportType,
    downloadUrl: string
  ): Promise<void> {
    const importData: ImportResult = {
      csvFileData: {
        id: fileId,
        name: fileName,
        customName,
        url: downloadUrl,
      },
      succeeded: 0,
      failed: 0,
      errors: [],
      type: importType,
      status: ImportStatus.READY,
      statusInfo: '',
      createdOn: Timestamp.now(),
      createdBy: {
        uid: this.userService.userData?.uid,
        displayName: this.userService.userData?.displayName,
        email: this.userService.userData?.email,
        userLocationId: this.userService.userData?.currentUserLocationId,
        cellNumber: this.userService.userData?.cellNumber,
      },
    };

    try {
      const importsRef = collection(this.dbModular, 'imports');
      await addDoc(importsRef, importData);
      this.snackBarService.openBlueSnackBar(`IMPORT INITIATED SUCCESSFULLY`);
    } catch (error) {
      if (error instanceof Error) {
        this.snackBarService.latestError = error.message;
        this.snackBarService.openRedSnackBar('FAILED TO INITIATE IMPORT');
      }
    }
  }

  async retryImport(importResult: ImportResult) {
    if (!importResult) {
      this.snackBarService.openRedSnackBar('IMPORT RESULT NOT FOUND');
      return;
    }

    if (
      importResult.status !== ImportStatus.FAILED &&
      importResult.status !== ImportStatus.CANCELLED
    ) {
      this.snackBarService.openRedSnackBar(
        'IMPORT WAS SUCCESSFUL, NO NEED TO RETRY'
      );
      return;
    }

    if (
      importResult.csvFileData?.id === undefined ||
      importResult.csvFileData.name === undefined ||
      importResult.csvFileData.url === undefined
    ) {
      this.snackBarService.openRedSnackBar('IMPORT FILE DATA NOT FOUND');
      return;
    }

    const importData: ImportResult = {
      csvFileData: {
        id: importResult.csvFileData?.id,
        name: importResult.csvFileData?.name,
        customName: importResult.csvFileData?.customName,
        url: importResult.csvFileData?.url,
      },
      succeeded: 0,
      failed: 0,
      errors: [],
      type: importResult.type,
      status: ImportStatus.READY,
      statusInfo: '',
      createdOn: Timestamp.now(),
      createdBy: {
        uid: this.userService.userData?.uid,
        displayName: this.userService.userData?.displayName,
        email: this.userService.userData?.email,
        userLocationId: this.userService.userData?.currentUserLocationId,
        cellNumber: this.userService.userData?.cellNumber,
      },
    };

    try {
      const importsRef = collection(this.dbModular, 'imports');
      await addDoc(importsRef, importData);
      this.snackBarService.openBlueSnackBar(
        `IMPORT RETRY INITIATED SUCCESSFULLY`
      );
    } catch (error) {
      if (error instanceof Error) {
        this.snackBarService.latestError = error.message;
        this.snackBarService.openRedSnackBar('FAILED TO RETRY IMPORT');
      }
    }
  }

  async cancelImport(importId: string): Promise<void> {
    if (!importId) {
      this.snackBarService.openRedSnackBar('IMPORT ID NOT FOUND');
      return;
    }

    if (
      !this.selectedImportResult ||
      this.selectedImportResult.id !== importId
    ) {
      await this.setSelectedImportResult(importId);
    }

    try {
      if (this.selectedImportResult?.status !== ImportStatus.IN_PROGRESS) {
        this.snackBarService.openRedSnackBar('IMPORT NOT IN PROGRESS');
        return;
      }

      const importDocRef = doc(this.dbModular, 'imports', importId);
      await updateDoc(importDocRef, { status: ImportStatus.CANCELLED });
      this.snackBarService.openBlueSnackBar(`IMPORT CANCELLED SUCCESSFULLY`);
    } catch (error) {
      if (error instanceof Error) {
        this.snackBarService.latestError = error.message;
        this.snackBarService.openRedSnackBar('FAILED TO CANCEL IMPORT');
      }
    }
  }

  getAppropriateImportStatus(importResult?: ImportResult): {
    text: string;
    styleClass: string;
  } {
    const defaultResult = { text: '', styleClass: '' };

    switch (importResult?.status) {
      case ImportStatus.READY:
        return { text: 'READY', styleClass: 'waitingColor' };

      case ImportStatus.PARTIAL:
        return { text: 'PARTIAL', styleClass: 'requestedColor' };

      case ImportStatus.SUCCESS:
        return { text: 'SUCCESS', styleClass: 'activeColor' };

      case ImportStatus.FAILED:
        return { text: 'FAILED', styleClass: 'warningColor' };

      case ImportStatus.IN_PROGRESS:
        return { text: 'IN PROGRESS', styleClass: 'inProgressColor' };

      case ImportStatus.HANDING_OVER:
        return { text: 'HANDING OVER', styleClass: 'inProgressColor' };

      case ImportStatus.TIMED_OUT:
        return { text: 'TIMED OUT', styleClass: 'warningColor' };

      case ImportStatus.CANCELLED:
        return { text: 'CANCELLED', styleClass: 'warningColor' };

      default:
        return defaultResult;
    }
  }

  updateCurrentImports(importResults: ImportResult[]) {
    if (!this.dataSourceImports) {
      this.dataSourceImports = new MatTableDataSource<ImportResult>(
        importResults
      );
    } else {
      this.dataSourceImports.data = importResults;
      this.dataSourceImports._updateChangeSubscription();
    }
  }

  updateCurrentImportResultErrors(importErrors: ImportError[]) {
    if (!this.dataSourceImportResultErrors) {
      this.dataSourceImportResultErrors = new MatTableDataSource<ImportError>(
        importErrors
      );
    } else {
      this.dataSourceImportResultErrors.data = importErrors;
      this.dataSourceImports._updateChangeSubscription();
    }
  }

  convertMemberType(memberType: string): string {
    const fieldMapping: { [key: string]: string } = {
      FEX: 'EXTENDED',
      MEM: 'MAIN MEMBER',
      SPS: 'SPOUSE',
    };
    return fieldMapping[memberType];
  }

  convertMemberStatus(memberType: string): string {
    const fieldMapping: { [key: string]: string } = {
      LPS: 'LAPSED',
      ACT: 'ACTIVE',
      NEW: 'ACTIVE',
    };
    return fieldMapping[memberType];
  }

  getFilePathFromImportType(importType: ImportType): string {
    let filePath = '';
    switch (importType) {
      case ImportType.MEMBER:
        filePath += 'pol360Member';
        break;
      case ImportType.ADD_ON:
        filePath += 'pol360AddOn';
        break;
      case ImportType.POL360_TRANSACTION:
        filePath += 'pol360Transaction';
        break;
      case ImportType.NETCASH_TRANSACTION:
        filePath += 'netcashTransaction';
        break;
      case ImportType.EFT_TRANSACTION:
        filePath += 'eftTransaction';
        break;
      case ImportType.PAY_AT_TRANSACTION:
        filePath += 'payAtTransaction';
        break;
      default:
        filePath += '';
    }
    return (filePath += 'Imports');
  }

  unsubscribe() {
    if (this.unsubscribeFromImportsSnapshot) {
      this.unsubscribeFromImportsSnapshot();
      this.unsubscribeFromImportsSnapshot = undefined;
      this.lastLoadedImportDoc = null;
    }

    this.selectedImportResult = undefined;
  }

  cleanUp() {
    this.importCount = undefined;
    this.unsubscribe();
    this.destroy$.next();
  }
}
