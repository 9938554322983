import { EventEmitter, Injectable, Output } from '@angular/core';
import { faker } from '@faker-js/faker';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { PolicyService } from './policy.service';
import { PlanService } from './plan.service';
import { AddOnService } from './add-on.service';
import { SnackBarService } from './snack-bar.service';
import { Router } from '@angular/router';
import { DateTimeService } from './date-time.service';
import { orderBy, Timestamp } from '@firebase/firestore';
import { PolicyLogService } from './policy-log.service';
import { TransactionService } from './transaction.service';
import { MainService } from './main.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../components/miscellaneous/dialog/dialog.component';
import { ValidationService } from './validation.service';
import { TransactionLogService } from './transaction-log.service';
import { UserService } from './user.service';
import { RolesRightsService } from './roles-rights.service';
import { MessageService } from './message.service';
import {
  Change,
  EventType,
  PolicyEvent,
  PolicyLogIndexFilters,
} from '../models/log.model';
import { ReportService } from './report.service';
import { Policy, PolicyStatus } from '../models/policy.model';
import { AddOnStatus } from '../models/addOn.model';
import { uuidv4 } from '@firebase/util';
import {
  PaymentMethod,
  PaymentSource,
  Transaction,
} from '../models/transaction.model';
import { ImportService } from './import.service';
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class FakerService {
  private dbModular = getFirestore();

  private _fakeData = new BehaviorSubject<any>(null);
  _fakeData$ = this._fakeData.asObservable();
  options: string[] = [];
  selectedLocale: string = 'zu_ZA';
  addOns: any;
  memberTypes: any;

  latestTransactionCount: any = undefined;

  periods: any[];

  @Output() submitFakerData = new EventEmitter<void>();

  constructor(
    private policyService: PolicyService,
    private planService: PlanService,
    private addOnService: AddOnService,
    private transactionService: TransactionService,
    private dateTimeService: DateTimeService,
    private snackBarService: SnackBarService,
    private policyLogService: PolicyLogService,
    private mainService: MainService,
    public dialog: MatDialog,
    private validationService: ValidationService,
    private transactionLogService: TransactionLogService,
    private userService: UserService,
    private rolesRightsService: RolesRightsService,
    private reportService: ReportService,
    private messageService: MessageService,
    private importService: ImportService
  ) {}

  get currentFakeDataValue() {
    return this._fakeData.getValue();
  }

  setFakerLocale(locale: string) {
    this.selectedLocale = locale;
    faker.setLocale(locale);
  }

  async generateCompletePolicies(
    includeTransactions: boolean,
    amount: number = 1
  ): Promise<void> {
    try {
      this.mainService.setLoading(true);
      this.mainService.setLoadingInfo('LOADING PREREQUISITES');

      if (!this.planService.allPlans || this.planService.plansUnsubscribed)
        await this.planService.loadPlans();
      if (!this.addOnService.allAddOns || this.addOnService.addOnsUnsubscribed)
        await this.addOnService.loadAddOns();

      let takenPolicyNumbers: string[] = [];

      const policiesQuery = query(collection(this.dbModular, 'policy'));
      const policiesSnapshot = await getDocs(policiesQuery);

      takenPolicyNumbers = policiesSnapshot.docs
        .map((doc) => (doc.data() as Policy).policyNumber)
        .filter(
          (policyNumber): policyNumber is string => policyNumber !== undefined
        );

      let totalTimeTaken = 0;

      this.mainService.setLoadingInfo(`POLICY 1/${amount}`);

      for (let i = 1; i <= amount; i++) {
        const startTime = performance.now();

        await this.generateCompletePolicy(
          includeTransactions,
          takenPolicyNumbers
        );

        if (this.policyService.selectedPolicy?.policyNumber) {
          takenPolicyNumbers.push(
            this.policyService.selectedPolicy.policyNumber
          );
        }

        const endTime = performance.now();
        totalTimeTaken += endTime - startTime;

        const averageTimePerPolicy = totalTimeTaken / i;
        const estimatedTimeLeft = averageTimePerPolicy * (amount - i);
        const minutesLeft = Math.floor(estimatedTimeLeft / 60000);
        const secondsLeft = Math.floor((estimatedTimeLeft % 60000) / 1000);

        let timeLeftMessage = `POLICY ${i + 1}/${amount}`;
        if (minutesLeft > 0) {
          timeLeftMessage += `  (${minutesLeft} ${this.mainService.pluralize(
            'MINUTE',
            minutesLeft
          )} ${secondsLeft} ${this.mainService.pluralize(
            'SECOND',
            secondsLeft
          )} LEFT)`;
        } else {
          timeLeftMessage += ` (${secondsLeft} ${this.mainService.pluralize(
            'SECOND',
            secondsLeft
          )} LEFT)`;
        }

        this.mainService.setLoadingInfo(timeLeftMessage);
      }
    } catch (err) {
      console.error('An error occurred:', err);
      this.mainService.setLoadingInfo('AN ERROR OCCURRED. PLEASE TRY AGAIN.');
    } finally {
      this.mainService.setLoading(false);
    }
  }

  private async generateCompletePolicy(
    includeTransactions: boolean,
    takenPolicyNumbers?: string[]
  ) {
    this.snackBarService.openRedSnackBar('FEATURE NOT WORKING ATM');
    return;

    // let policy: Policy = {};
    // let types: EventType[] = [];

    // let counter = 0;

    // do {
    //   this.generateFakePolicyDetailsData();
    // } while (
    //   !takenPolicyNumbers ||
    //   takenPolicyNumbers.includes(this.currentFakeDataValue.policyNumber)
    // );

    // policy.policyNumber = this.currentFakeDataValue.policyNumber;
    // policy.planId = this.currentFakeDataValue.planId;
    // policy.inceptionDate =
    //   this.dateTimeService.dateToTimestamp(
    //     this.currentFakeDataValue.inceptionDate
    //   ) ?? this.currentFakeDataValue.inceptionDate;
    // policy.status = PolicyStatus.IN_PROGRESS;
    // policy.addOns = [];
    // policy.members = [];
    // policy.comments = [];
    // policy.files = [];
    // policy.productsPaymentStatus = [];
    // policy.intendedPaymentDay = Number(
    //   this.currentFakeDataValue.inceptionDate.getDate()
    // );
    // policy.unallocatedBalance = 0;
    // policy.payAtNumber = this.policyService.generatePayAtNumber();
    // policy.createdBy = {
    //   uid: this.userService.userData?.uid,
    //   displayName: this.userService.userData?.displayName,
    //   email: this.userService.userData?.email,
    //   userLocationId: this.userService.userData?.currentUserLocationId,
    // };
    // policy.createdOn = Timestamp.now();
    // policy.updatedBy = policy.createdBy;
    // policy.updatedOn = policy.createdOn;

    // const policiesCollectionRef = collection(this.dbModular, 'policy');
    // const docRef = await addDoc(policiesCollectionRef, policy);
    // const docId = docRef.id;

    // await this.policyService.setSelectedPolicy(docId);

    // const messagesCollectionRef = collection(this.dbModular, 'message');
    // await addDoc(messagesCollectionRef, { policyId: docId, messages: [] });

    // await this.policyLogService.createPolicyLog({ ...policy, id: docId });
    // // await this.policyLogService.setSelectedPolicyLog(docId);

    // types.push({ object: 'policy', type: 'create' });

    // let events: PolicyEvent[] =
    //   this.policyLogService.selectedPolicyLog?.events ?? [];

    // const numOfAllowedAddOns = this.generateRandomNumber(
    //   0,
    //   this.policyService.getAllowedAddOns().length
    // );

    // if (numOfAllowedAddOns > 0) types.push({ object: 'addOn', type: 'create' });

    // while (counter < numOfAllowedAddOns) {
    //   const oldPolicy = JSON.parse(JSON.stringify(policy));
    //   this.generateFakePolicyAddOnDetailsData();

    //   policy.addOns?.push({
    //     ...this.currentFakeDataValue,
    //     id: uuidv4(),
    //     inceptionDate:
    //       this.dateTimeService.dateToTimestamp(
    //         this.currentFakeDataValue.inceptionDate
    //       ) ?? this.currentFakeDataValue.inceptionDate,
    //     waitingDate:
    //       this.dateTimeService.dateToTimestamp(
    //         this.currentFakeDataValue.inceptionDate
    //       ) ?? this.currentFakeDataValue.inceptionDate,
    //     status: AddOnStatus.ACTIVE,
    //     createdBy: policy.createdBy,
    //     createdOn: policy.createdOn,
    //     updatedBy: policy.createdBy,
    //     updatedOn: policy.createdOn,
    //   });
    //   counter++;

    //   const changes: Change[] = this.policyLogService.compareNestedFields(
    //     oldPolicy,
    //     policy
    //   );
    //   const id = uuidv4();
    //   const createdBy = policy.createdBy;
    //   const createdOn = Timestamp.now();

    //   events.push({
    //     ...{ object: 'addOn', type: 'create' },
    //     id,
    //     changes,
    //     objectInfo: this.policyLogService.objectInfo ?? '',
    //     reason: '',
    //     createdOn,
    //     createdBy,
    //   });

    //   this.policyService.selectedPolicy = {
    //     ...this.policyService.selectedPolicy,
    //     ...policy,
    //   };
    // }

    // let numOfAllowedMemberTypes: number = 0;

    // const planMemberTypes =
    //   this.planService.getPlanMemberTypes(policy.planId ?? '') ?? [];

    // planMemberTypes.forEach((memberType) => {
    //   const maxAllowed = Number(memberType.maxAllowed);
    //   numOfAllowedMemberTypes += isNaN(maxAllowed) ? 0 : maxAllowed;
    // });

    // numOfAllowedMemberTypes = this.generateRandomNumber(
    //   1,
    //   numOfAllowedMemberTypes
    // );
    // counter = 0;

    // types.push({ object: 'member', type: 'create' });
    // while (counter < numOfAllowedMemberTypes) {
    //   const oldPolicy = JSON.parse(JSON.stringify(policy));
    //   this.generateFakePolicyMemberDetailsData();

    //   policy.members?.push({
    //     ...this.currentFakeDataValue,
    //     id: uuidv4(),
    //     inceptionDate:
    //       this.dateTimeService.dateToTimestamp(
    //         this.currentFakeDataValue.inceptionDate
    //       ) ?? this.currentFakeDataValue.inceptionDate,
    //     waitingDate:
    //       this.dateTimeService.dateToTimestamp(
    //         this.currentFakeDataValue.inceptionDate
    //       ) ?? this.currentFakeDataValue.inceptionDate,
    //     status: AddOnStatus.ACTIVE,
    //     createdBy: policy.createdBy,
    //     createdOn: policy.createdOn,
    //     updatedBy: policy.createdBy,
    //     updatedOn: policy.createdOn,
    //   });
    //   counter++;

    //   const changes: Change[] = this.policyLogService.compareNestedFields(
    //     oldPolicy,
    //     policy
    //   );
    //   const id = uuidv4();
    //   const createdBy = policy.createdBy;
    //   const createdOn = Timestamp.now();

    //   events.push({
    //     ...{ object: 'member', type: 'create' },
    //     id,
    //     changes,
    //     objectInfo: this.policyLogService.objectInfo ?? '',
    //     reason: '',
    //     createdOn,
    //     createdBy,
    //   });

    //   this.policyService.selectedPolicy = {
    //     ...this.policyService.selectedPolicy,
    //     ...policy,
    //   };
    // }

    // policy.addOns = this.policyService.sortPolicyAddOns(policy.addOns);
    // policy.members = this.policyService.sortMembers(policy.members);
    // policy.status = PolicyStatus.PENDING;
    // const policyDocRef = doc(this.dbModular, 'policy', docId);
    // await setDoc(policyDocRef, policy, { merge: true });

    // const referenceNumber =
    //   faker.random.alpha({ count: 4 }).toUpperCase() +
    //   this.generateRandomNumber(1000, 9999).toString();

    // const referenceNumbers: string[] =
    //   this.policyLogService.selectedPolicyLog?.referenceNumbers ?? [];
    // for (let i = 0; i < events.length - 1; i++) {
    //   referenceNumbers.push(referenceNumber);
    // }

    // if (this.policyLogService.selectedPolicyLog?.id) {
    //   const policyLogDocRef = doc(
    //     this.dbModular,
    //     'policyLog',
    //     this.policyLogService.selectedPolicyLog?.id
    //   );
    //   await updateDoc(policyLogDocRef, {
    //     events,
    //     referenceNumbers,
    //     policyNumber: policy.policyNumber,
    //   });

    //   const today = new Date();
    //   today.setHours(0, 0, 0, 0);

    //   const date = Timestamp.fromDate(today);

    //   if (includeTransactions) {
    //     const numOfTransactions = this.generateRandomNumber(2, 5);

    //     for (
    //       let i = 0;
    //       i < numOfTransactions &&
    //       this.policyService.selectedPolicy?.status !== PolicyStatus.LAPSED;
    //       i++
    //     ) {
    //       const nextPremium =
    //         this.transactionService.getNextPolicyPremium(policy);

    //       const isUnallocated = Math.random() > 0.8;

    //       const amount: number =
    //         !isUnallocated || i === 0
    //           ? nextPremium.amount
    //           : nextPremium.amount - 10 * this.generateRandomNumber(2, 6);
    //       const source: PaymentSource =
    //         i === 0
    //           ? PaymentSource.SYSTEM
    //           : isUnallocated
    //           ? PaymentSource.PAY_AT
    //           : Math.random() > 0.5
    //           ? PaymentSource.NETCASH
    //           : PaymentSource.PAY_AT;
    //       const method: PaymentMethod =
    //         i === 0
    //           ? PaymentMethod.OFFLINE_CASH
    //           : source === PaymentSource.NETCASH
    //           ? PaymentMethod.ONLINE_DEBIT_ORDER
    //           : PaymentMethod.ONLINE_CARD;

    //       const receiptReferenceNumber = this.generateRandomNumber(
    //         10000000000,
    //         99999999999
    //       ).toLocaleString();

    //       const transaction: Transaction = {
    //         amount,
    //         source,
    //         method,
    //       };

    //       if (!PaymentSource.SYSTEM)
    //         transaction.receiptReferenceNumber = receiptReferenceNumber;

    //       await this.transactionService.updateTransactions(
    //         transaction,
    //         nextPremium.periods
    //       );
    //     }
    //   }
    // }
  }

  generateFakePolicyDetailsData(submitData: boolean = false) {
    this.options = [];

    const plans = this.planService.allPlans;
    for (let i = 0; i < plans.length; i++) {
      const id = plans[i].id;
      const status = plans[i].status;
      if (id && status !== 'INACTIVE') {
        this.options.push(id);
      }
    }

    const planId = this.selectRandomOption();
    const policyNumber =
      'WIS' + this.generateRandomNumber(10000, 999999).toString();

    let inceptionDate = faker.date.recent(
      Math.random() > 0.4
        ? this.generateRandomNumber(1, 90)
        : this.generateRandomNumber(110, 150)
    );
    inceptionDate.setHours(0, 0, 0, 0);

    const status = 'ACTIVE';

    this._fakeData.next({
      planId,
      policyNumber,
      inceptionDate,
      status,
    });

    if (submitData) {
      setTimeout(() => {
        this.submitFakerData.emit();
      }, 100);
    }
  }

  generateFakePolicyAddOnDetailsData(submitData: boolean = false) {
    this.options = [];

    const addOns = this.policyService.getAllowedAddOns();
    this.addOns = addOns;

    if (addOns.length > 0) {
      for (let i = 0; i < addOns.length; i++) {
        const id = addOns[i].id;
        if (id) {
          this.options.push(id);
        }
      }

      const addOnId = this.selectRandomOption();
      const inceptionDate = faker.date.recent(
        this.generateRandomNumber(
          0,
          this.dateTimeService.getDaysDifference(
            Timestamp.now(),
            this.policyService.selectedPolicy?.inceptionDate
          )
        )
      );
      inceptionDate.setHours(0, 0, 0, 0);
      const status = 'ACTIVE';

      this._fakeData.next({
        addOnId,
        inceptionDate,
        status,
      });

      if (!this.policyLogService.currentRefNum)
        this.policyLogService.currentRefNum =
          faker.random.alpha({ count: 3 }).toUpperCase() +
          this.generateRandomNumber(100, 9999).toString();
      if (submitData) {
        setTimeout(() => {
          this.submitFakerData.emit();
        }, 100);
      }
    } else {
      this.snackBarService.openRedSnackBar('NO ADD-ONS LEFT TO ADD');
    }
  }

  generateFakePolicyMemberDetailsData(submitData: boolean = false) {
    this.policyService.refreshAllowedMemberTypes();

    if (
      this.policyService.allowedMemberTypes &&
      this.policyService.allowedMemberTypes.length > 0
    ) {
      this.options = [];

      for (let i = 0; i < this.policyService.allowedMemberTypes.length; i++) {
        const id = this.policyService.allowedMemberTypes[i].id;
        if (id) {
          this.options.push(id);
        }
      }

      const selectedPolicy = this.policyService.selectedPolicy;
      let memberTypeId;
      let inceptionDate;
      let idNumber;
      let cellNumber;
      const sex = faker.name.sexType();
      if (selectedPolicy?.members?.length === 0 && selectedPolicy.planId) {
        memberTypeId = this.planService.getPrimaryMemberTypeByPlanId(
          selectedPolicy.planId
        )?.id;
        inceptionDate = this.dateTimeService.timestampToDate(
          selectedPolicy.inceptionDate
        );
        idNumber = this.generateFakeSouthAfricanIDNumber(sex, true);
        // cellNumber = Math.random() < 0.5 ? '0636917207' : '0727655122';
        cellNumber = this.userService.userData?.cellNumber ?? '0727655122';
      } else {
        memberTypeId = this.selectRandomOption();
        inceptionDate = faker.date.recent(
          this.generateRandomNumber(
            0,
            this.dateTimeService.getDaysDifference(
              Timestamp.now(),
              this.policyService.selectedPolicy?.inceptionDate
            )
          )
        );
        idNumber = this.generateFakeSouthAfricanIDNumber(sex);
        cellNumber = faker.phone.number('0#########');
      }
      inceptionDate?.setHours(0, 0, 0, 0);
      const firstName = faker.name.firstName(sex).toUpperCase();
      const lastName = faker.name.lastName().toUpperCase();
      const altNumber = faker.phone.number('0#########');
      const email = faker.internet
        .email(firstName, lastName, 'gmail.com')
        .toUpperCase();
      const street = faker.address.streetAddress(false).toUpperCase();
      const city = faker.address.cityName().toUpperCase();
      const province = faker.address.state().toUpperCase();
      const postalCode = faker.address.zipCode('####');

      this._fakeData.next({
        memberTypeId,
        idNumber,
        firstName,
        lastName,
        cellNumber,
        altNumber,
        inceptionDate,
        email,
        address: { street, city, province, postalCode },
      });

      if (!this.policyLogService.currentRefNum)
        this.policyLogService.currentRefNum =
          faker.random.alpha({ count: 3 }).toUpperCase() +
          this.generateRandomNumber(100, 9999).toString();

      setTimeout(() => {
        if (submitData) {
          this.submitFakerData.emit();
        }
      }, 100);
    } else {
      this.snackBarService.openRedSnackBar('NO ALLOWED MEMBER TYPES LEFT');
    }
  }

  generateFakePlan(submitData: boolean = false) {
    const name = this.generateFakePlanName();
    const status = 'ACTIVE';

    this._fakeData.next({
      name,
      status,
    });

    if (!this.policyLogService.currentRefNum)
      this.policyLogService.currentRefNum =
        faker.random.alpha({ count: 3 }).toUpperCase() +
        this.generateRandomNumber(100, 9999).toString();
    if (submitData) {
      setTimeout(() => {
        this.submitFakerData.emit();
      }, 100);
    }
  }

  generateFakePlanMember(submitData: boolean = false) {
    interface PlanMember {
      name: string;
      waitingPeriod: number;
      standardAmount: number;
      waitingAmount: number;
      ageFrom: number;
      ageTo: number;
      maxAllowed: number;
      primaryMember: boolean;
    }

    interface SpecificPlanMembers {
      [key: string]: PlanMember[];
    }

    const specificPlanMembers: SpecificPlanMembers = {
      'PLAN A': [
        {
          name: 'SPOUSE',
          waitingPeriod: 90,
          standardAmount: 0,
          waitingAmount: 0,
          ageFrom: 18,
          ageTo: 150,
          maxAllowed: 1,
          primaryMember: false,
        },
        {
          name: 'BENEFICIARY',
          waitingPeriod: 90,
          standardAmount: 0,
          waitingAmount: 0,
          ageFrom: 0,
          ageTo: 150,
          maxAllowed: 7,
          primaryMember: false,
        },
        {
          name: 'EXTENDED',
          waitingPeriod: 90,
          standardAmount: 0,
          waitingAmount: 0,
          ageFrom: 0,
          ageTo: 150,
          maxAllowed: 4,
          primaryMember: false,
        },
      ],
      'PLAN B': [
        {
          name: 'SPOUSE',
          waitingPeriod: 90,
          standardAmount: 0,
          waitingAmount: 0,
          ageFrom: 18,
          ageTo: 150,
          maxAllowed: 1,
          primaryMember: false,
        },
        {
          name: 'BENEFICIARY',
          waitingPeriod: 90,
          standardAmount: 0,
          waitingAmount: 0,
          ageFrom: 0,
          ageTo: 150,
          maxAllowed: 16,
          primaryMember: false,
        },
      ],
      'PLAN E': [
        {
          name: 'SPOUSE',
          waitingPeriod: 90,
          standardAmount: 0,
          waitingAmount: 0,
          ageFrom: 18,
          ageTo: 150,
          maxAllowed: 1,
          primaryMember: false,
        },
        {
          name: 'BENEFICIARY',
          waitingPeriod: 90,
          standardAmount: 0,
          waitingAmount: 0,
          ageFrom: 0,
          ageTo: 150,
          maxAllowed: 10,
          primaryMember: false,
        },
      ],
    };

    const selectedPlanName = this.planService.selectedPlan?.name;
    const selectedPlanMemberTypes =
      this.planService.selectedPlan?.memberType?.map(
        (memberType) => memberType.name
      ) || [];

    let name,
      maxAllowed,
      standardAmount,
      waitingAmount,
      waitingPeriod,
      status,
      ageFrom,
      ageTo,
      primaryMember;

    if (!selectedPlanMemberTypes.includes('MAIN MEMBER')) {
      name = 'MAIN MEMBER';
      maxAllowed = 1;
      standardAmount = 220;
      waitingAmount = 100;
      waitingPeriod = 90;
      status = 'ACTIVE';
      ageFrom = 18;
      ageTo = 150;
      primaryMember = true;
    } else if (selectedPlanName && specificPlanMembers[selectedPlanName]) {
      for (let member of specificPlanMembers[selectedPlanName]) {
        if (!selectedPlanMemberTypes.includes(member.name)) {
          name = member.name;
          maxAllowed = member.maxAllowed;
          standardAmount = member.standardAmount;
          waitingAmount = member.waitingAmount;
          waitingPeriod = member.waitingPeriod.toString();
          status = 'ACTIVE';
          ageFrom = member.ageFrom;
          ageTo = member.ageTo;
          break;
        }
      }
    }

    if (!name) {
      name = this.generateFakeMemberType();
      maxAllowed = this.generateRandomNumber(1, 8);
      (standardAmount = 0),
        (waitingAmount = 0),
        (waitingPeriod = (30 * this.generateRandomNumber(1, 3))
          .toString()
          .toUpperCase());
      status = 'ACTIVE';
      ageFrom = this.generateRandomNumber(18, 21);
      ageTo = 10 * this.generateRandomNumber(8, 15);
      primaryMember = false;
    }

    this._fakeData.next({
      name,
      waitingPeriod,
      standardAmount,
      waitingAmount,
      status,
      ageFrom,
      ageTo,
      maxAllowed,
      primaryMember,
    });

    if (!this.policyLogService.currentRefNum)
      this.policyLogService.currentRefNum =
        faker.random.alpha({ count: 3 }).toUpperCase() +
        this.generateRandomNumber(100, 9999).toString();
    if (submitData) {
      setTimeout(() => {
        this.submitFakerData.emit();
      }, 100);
    }
  }

  generateFakeAddOn(submitData: boolean = false) {
    const name = this.generateFakeAddOnName();
    const status = 'ACTIVE';

    this._fakeData.next({
      name,
      status,
    });

    if (!this.policyLogService.currentRefNum)
      this.policyLogService.currentRefNum =
        faker.random.alpha({ count: 3 }).toUpperCase() +
        this.generateRandomNumber(100, 9999).toString();
    if (submitData) {
      setTimeout(() => {
        this.submitFakerData.emit();
      }, 100);
    }
  }

  generateFakeAddOnPlan(submitData: boolean = false) {
    interface AddOnPlan {
      planId: string;
      waitingPeriod: number;
      standardAmount: number;
      waitingAmount: number;
      status: string;
    }

    const plans = this.addOnService.getAllowedAddOnPlans();
    interface SpecificAddOnPlans {
      [key: string]: AddOnPlan[];
    }

    const specificAddOnPlans: SpecificAddOnPlans = {
      'TOP UP': [
        {
          planId: 'PLAN A',
          waitingPeriod: 90,
          standardAmount: 180,
          waitingAmount: 100,
          status: 'ACTIVE',
        },
        {
          planId: 'PLAN B',
          waitingPeriod: 90,
          standardAmount: 180,
          waitingAmount: 100,
          status: 'ACTIVE',
        },
        {
          planId: 'PLAN E',
          waitingPeriod: 90,
          standardAmount: 180,
          waitingAmount: 100,
          status: 'ACTIVE',
        },
      ],
      TOMBSTONE: [
        {
          planId: 'PLAN A',
          waitingPeriod: 180,
          standardAmount: 120,
          waitingAmount: 100,
          status: 'ACTIVE',
        },
        {
          planId: 'PLAN B',
          waitingPeriod: 180,
          standardAmount: 120,
          waitingAmount: 100,
          status: 'ACTIVE',
        },
        {
          planId: 'PLAN E',
          waitingPeriod: 180,
          standardAmount: 120,
          waitingAmount: 100,
          status: 'ACTIVE',
        },
      ],
    };

    if (
      this.addOnService.selectedAddOn?.name &&
      specificAddOnPlans[this.addOnService.selectedAddOn.name]
    ) {
      for (let addOnPlan of specificAddOnPlans[
        this.addOnService.selectedAddOn.name
      ]) {
        if (plans.some((plan) => plan.name === addOnPlan.planId)) {
          this._fakeData.next(addOnPlan);
          if (submitData) {
            setTimeout(() => {
              this.submitFakerData.emit();
            }, 100);
          }
          return;
        }
      }
    }

    if (plans.length > 0) {
      const planId = plans[this.generateRandomNumber(0, plans.length - 1)].name;
      const waitingPeriod = (30 * this.generateRandomNumber(1, 3))
        .toString()
        .toUpperCase();
      const standardAmount = 10 * this.generateRandomNumber(10, 30);
      const waitingAmount = 100;
      const status = 'ACTIVE';

      this._fakeData.next({
        planId,
        waitingPeriod,
        standardAmount,
        waitingAmount,
        status,
      });

      if (!this.policyLogService.currentRefNum)
        this.policyLogService.currentRefNum =
          faker.random.alpha({ count: 3 }).toUpperCase() +
          this.generateRandomNumber(100, 9999).toString();
      if (submitData) {
        setTimeout(() => {
          this.submitFakerData.emit();
        }, 100);
      }
    } else {
      this.snackBarService.openRedSnackBar('NO ACTIVE PLANS LEFT TO ADD');
    }
  }

  generateFakeOnlineTransaction(
    method: string,
    amount: number,
    transactionDate: Date
  ) {
    this.periods = this.transactionService.getPolicyPremiumPeriods(60);
    const receiptReferenceNumber = this.generateRandomNumber(
      10000000000,
      99999999999
    ).toLocaleString;
    const allocated = this.periods.some((period) => period.premium === amount);
    const status = allocated ? 'PAID' : 'UNALLOCATED';

    const now = new Date();
    transactionDate.setHours(now.getHours());
    transactionDate.setMinutes(now.getMinutes());
    transactionDate.setSeconds(now.getSeconds());

    this.transactionService.updateTransactions(
      { method, amount, transactionDate, receiptReferenceNumber, status },
      this.periods
    );
  }

  generateFakeSouthAfricanIDNumber(sex: 'female' | 'male', adult = false) {
    const today = new Date();
    const adultMinimumBirthDate = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    const childMinimumBirthDate = new Date(
      today.getFullYear() - 1,
      today.getMonth(),
      today.getDate()
    );
    const oldestBirthDate = new Date('1970-01-01');

    let birthDate = adult
      ? faker.date.between(oldestBirthDate, adultMinimumBirthDate)
      : faker.date.between(oldestBirthDate, childMinimumBirthDate);

    const year = birthDate.getFullYear().toString().substr(-2);
    const month = (birthDate.getMonth() + 1).toString().padStart(2, '0');
    const day = birthDate.getDate().toString().padStart(2, '0');

    const genderMin = sex === 'female' ? 0 : 5000;
    const genderMax = sex === 'female' ? 4999 : 9999;
    const gender = this.generateRandomNumber(genderMin, genderMax)
      .toString()
      .padStart(4, '0');

    const citizen = '0'; // 0 denotes a citizen
    const randomDigit = this.generateRandomNumber(0, 9).toString();

    // generate the first 12 digits
    let firstTwelveDigits = `${year}${month}${day}${gender}${citizen}${randomDigit}`;

    // Calculate the Luhn check digit
    let sum = 0;
    for (let i = 0; i < 12; i++) {
      let num = parseInt(firstTwelveDigits.charAt(i), 10);
      if (i % 2 !== 0) {
        num *= 2;
        if (num > 9) {
          num -= 9;
        }
      }
      sum += num;
    }

    const checkDigit = (10 - (sum % 10)) % 10; // Luhn check digit

    // Return the complete 13-digit ID number
    return `${firstTwelveDigits}${checkDigit}`;
  }

  generateFakePlanName() {
    const priorityPlanNames = ['PLAN A', 'PLAN B', 'PLAN E'];
    const allPlanNames = [
      'PLAN UNIQUE',
      'PLAN A',
      'PLAN B',
      'PLAN C',
      'PLAN D',
      'PLAN E',
      'PLAN F',
      'PLAN INDUNA',
      'PLAN PREMIUM',
      'PLAN STANDARD',
      'PLAN DELUXE',
      'PLAN ADVANCED',
      'PLAN EXECUTIVE',
      'PLAN ROYAL',
      'PLAN SPECIAL',
      'PLAN ESSENTIAL',
      'PLAN SELECT',
      'PLAN PREFERRED',
      'PLAN HARMONY',
      'PLAN UNITY',
      'PLAN PEACE',
      'PLAN SERENITY',
      'PLAN DIGNITY',
      'PLAN ELEGANCE',
      'PLAN GRACE',
      'PLAN SOLACE',
      'PLAN COMFORT',
      'PLAN SECURITY',
      'PLAN TRANQUILITY',
      'PLAN PROSPERITY',
      'PLAN EXCELLENCE',
      'PLAN PRIME',
      'PLAN ULTIMATE',
      'PLAN SUPERIOR',
      'PLAN SUPREME',
      'PLAN TRIUMPH',
      'PLAN VICTORY',
      'PLAN GLORY',
    ];

    const existingPlanNames = this.planService.allPlans.map(
      (plan) => plan.name
    );

    for (let plan of priorityPlanNames) {
      if (!existingPlanNames.includes(plan)) {
        return plan;
      }
    }

    const availablePlanNames = allPlanNames.filter(
      (plan) => !existingPlanNames.includes(plan)
    );

    return availablePlanNames[
      this.generateRandomNumber(0, availablePlanNames.length - 1)
    ];
  }

  generateFakeMemberType() {
    const allMemberTypes = [
      'BENEFICIARY',
      'SPOUSE',
      'DEPENDENT',
      'CHILD',
      'ADULT DEPENDENT',
      'GRANDPARENT',
      'IN-LAW',
      'DOMESTIC PARTNER',
      'STEP CHILD',
      'ADOPTED CHILD',
      'GUARDIAN',
      'FAMILY FRIEND',
      'CAREGIVER',
      'EXTENDED FAMILY MEMBER',
      'FAMILY MEMBER',
      'RELATIVE',
      'NEIGHBOR',
      'ASSOCIATE',
      'COLLEAGUE',
      'EMPLOYEE',
      'PARTNER',
      'SHAREHOLDER',
      'COMMUNITY MEMBER',
      'CHURCH MEMBER',
      'GROUP MEMBER',
      'CLUB MEMBER',
      'SOCIAL MEMBER',
      'FRIEND',
      'ACQUAINTANCE',
      'SPONSOR',
      'MENTOR',
      'PATRON',
      'PHILANTHROPIST',
      'BENEFACTOR',
      'DONOR',
      'VOLUNTEER',
      'SUPPORTER',
      'ASSISTANT',
      'COOPERATIVE MEMBER',
      'NON-MEMBER',
    ];

    const existingMemberTypeNames =
      this.planService.selectedPlan?.memberType?.map(
        (memberType) => memberType.name
      );
    const availableMemberTypes = allMemberTypes.filter(
      (memberType) => !existingMemberTypeNames?.includes(memberType)
    );

    return availableMemberTypes[
      this.generateRandomNumber(0, availableMemberTypes.length - 1)
    ];
  }

  generateFakeAddOnName() {
    const preferredAddOnNames = ['TOP UP', 'TOMBSTONE'];

    const existingAddOnNames = this.addOnService.allAddOns.map(
      (addOn) => addOn.name
    );

    for (let addOnName of preferredAddOnNames) {
      if (!existingAddOnNames.includes(addOnName)) {
        return addOnName;
      }
    }

    const allAddOns = [
      'TOMBSTONE',
      'TOP UP',
      'FLOWER ARRANGEMENT',
      'CATERING SERVICE',
      'TRANSPORTATION',
      'VIEWING ROOM',
      'GRAVESIDE SERVICE',
      'MEMORIAL BOOKLET',
      'ADDITIONAL MOURNERS',
      'UPGRADED CASKET',
      'REPATRIATION',
      'BURIAL PLOT',
      'CREMATION SERVICE',
      'VIDEO MEMORIAL',
      'ONLINE TRIBUTE',
      'GRIEF COUNSELING',
      'ESTATE PLANNING',
    ];

    const availableAddOnNames = allAddOns.filter(
      (addOn) => !existingAddOnNames.includes(addOn)
    );

    return availableAddOnNames[
      this.generateRandomNumber(0, availableAddOnNames.length - 1)
    ];
  }

  selectRandomOption() {
    const randomIndex = this.generateRandomNumber(0, this.options.length - 1);
    return this.options[randomIndex];
  }

  generateRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  resetFakeData() {
    this._fakeData.next(null);
  }

  async openDocumentSizeDialog() {
    this.mainService.setLoading(true);

    const items = [
      {
        service: this.policyService,
        collection: 'policy',
        id: this.policyService.selectedPolicy?.id,
        key: 'selectedPolicy',
      },
      {
        service: this.policyLogService,
        collection: 'policyLog',
        id: this.policyLogService.selectedPolicyLog?.id,
        key: 'selectedPolicyLogs',
      },
      {
        service: this.transactionLogService,
        collection: 'transactionLog',
        id: this.transactionLogService.selectedTransactionLog?.id,
        key: 'selectedTransactionLogs',
      },
      {
        service: this.transactionService,
        collection: 'transaction',
        id: this.transactionService.selectedTransaction?.id,
        key: 'selectedTransaction',
      },
      {
        service: this.planService,
        collection: 'plan',
        id: this.planService.selectedPlan?.id,
        key: 'selectedPlan',
      },
      {
        service: this.addOnService,
        collection: 'addOn',
        id: this.addOnService.selectedAddOn?.id,
        key: 'selectedAddOn',
      },
      {
        service: this.userService,
        collection: 'users',
        id: this.userService.selectedUser?.uid,
        key: 'selectedUser',
      },
      {
        service: this.rolesRightsService,
        collection: 'roles',
        id: this.rolesRightsService.selectedRole?.id,
        key: 'selectedRole',
      },
      {
        service: this.messageService,
        collection: 'message',
        id: this.messageService.selectedPolicyMessages?.id,
        key: 'selectedPolicyMessages',
      },
      {
        service: this.reportService,
        collection: 'report',
        id: 'd88UCAwKzfcUpi0FhhLh',
        key: 'selectedReports',
      },
      {
        service: this.importService,
        collection: 'imports',
        id: this.importService.selectedImportResult?.id,
        key: 'selectedImportResult',
      },
      {
        service: this.reportService,
        collection: 'generatedReport',
        id: this.reportService.selectedGeneratedReports?.id,
        key: 'selectedGeneratedReports',
      },
    ];

    const promises = items.map(async (item) => {
      if (item.id) {
        const size = await this.validationService.estimateDocumentSize(
          item.collection,
          item.id
        );
        return { [item.key]: size };
      }
      return { [item.key]: undefined };
    });

    const sizes = await Promise.all(promises);

    const selectedItem: { [key: string]: number | undefined } = {};

    for (let result of sizes) {
      for (let key in result) {
        selectedItem[key] = result[key];
      }
    }

    const selectedItemArray = Object.entries(selectedItem)
      .filter(([key, value]) => value !== undefined)
      .map(([key, value]) => ({
        name: key,
        size: value,
      }));

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      dialogType: 'documentSizeDialog',
      selectedItem: selectedItemArray,
    };

    this.mainService.setLoading(false);
    this.dialog.open(DialogComponent, dialogConfig);
  }

  async reinitializeTransactionCount(): Promise<void> {
    try {
      const selectedPolicyId = this.policyService.selectedPolicy?.id;

      if (!selectedPolicyId) {
        throw new Error('Selected policy ID is undefined');
      }

      const transactionsQuery = query(
        collection(this.dbModular, 'transaction'),
        where('policyId', '==', selectedPolicyId)
      );

      const transactionsSnapshot = await getDocs(transactionsQuery);

      const transactionCounts: { [status: string]: number } = {};

      transactionsSnapshot.forEach((doc) => {
        const transaction = doc.data() as Transaction;
        const status = transaction.status || 'unknown';

        if (transactionCounts[status]) {
          transactionCounts[status]++;
        } else {
          transactionCounts[status] = 1;
        }
      });

      const transactionCountObj: any = {};
      for (const [status, count] of Object.entries(transactionCounts)) {
        transactionCountObj[`transactionCount.${status}`] = count;
      }

      this.latestTransactionCount =
        this.policyService.selectedPolicy?.transactionCount;

      const policyDocRef = doc(this.dbModular, 'policy', selectedPolicyId);
      await updateDoc(policyDocRef, transactionCountObj);
      this.snackBarService.openBlueSnackBar(
        `TRANSACTION COUNTS REINITIALIZED FOR ${this.policyService.selectedPolicy?.policyNumber}`
      );
    } catch (err) {
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
      }
      this.snackBarService.openRedSnackBar('REINITIALIZATION FAILED');
    }
  }

  async undoReinitializeTransactionCount(): Promise<void> {
    try {
      const selectedPolicyId = this.policyService.selectedPolicy?.id;

      if (!selectedPolicyId) {
        throw new Error('Selected policy ID is undefined');
      }

      if (!this.latestTransactionCount) {
        throw new Error('No previous transaction count to restore');
      }

      const policyDocRef = doc(this.dbModular, 'policy', selectedPolicyId);
      await updateDoc(policyDocRef, {
        transactionCount: this.latestTransactionCount,
      });
      this.latestTransactionCount = undefined;
      this.snackBarService.openBlueSnackBar(
        `TRANSACTION COUNTS RESTORED FOR ${this.policyService.selectedPolicy?.policyNumber}`
      );
    } catch (err) {
      if (err instanceof Error) {
        this.snackBarService.latestError = err.message;
      }
      this.snackBarService.openRedSnackBar('UNDO FAILED');
    }
  }

  async testLoadFilteredPolicyEventChanges() {
    const start = new Date('2024-11-13T00:00:00+02:00');
    const end = new Date('2024-11-13T23:59:59.999+02:00');
    console.log('START:', start);
    console.log('END:', end);
    const startDate = Timestamp.fromDate(start);
    const endDate = Timestamp.fromDate(end);

    const filters: PolicyLogIndexFilters = {
      startDate: startDate,
      endDate: endDate,
      type: { object: 'member', type: 'update', key: 'lastName' },
      // type: { object: 'policy', type: 'update', key: 'status' },
    };

    console.log('FILTERS:', filters);

    const collectionRef = collection(this.dbModular, 'policyEvent');

    const querySnapshot = query(
      collectionRef,
      ...[
        where('eventTypes', 'array-contains', filters.type),
        where('createdOn', '>=', startDate),
        where('createdOn', '<=', endDate),
        orderBy('createdOn', 'desc'),
      ]
    );

    try {
      const snapshot = await getDocs(querySnapshot);

      const policyEvents: PolicyEvent[] = [];

      snapshot.forEach((doc) => {
        const data = doc.data();
        policyEvents.push(data as PolicyEvent);
      });

      const changes = await this.policyLogService.filterPolicyEventChanges(
        policyEvents,
        filters
      );
      console.log(changes);
    } catch (err) {
      console.error(err);
    }
  }
}
