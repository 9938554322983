import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyToString',
})
export class CurrencyToStringPipe implements PipeTransform {
  transform(value: number | undefined): string {
    if (value === undefined) value = 0;
    return value.toLocaleString('en-ZA', {
      style: 'currency',
      currency: 'ZAR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    });
  }
}
