import { Timestamp } from '@firebase/firestore';
import { CRUD, CreatedBy, UpdatedBy } from './general.model';
export interface User {
  uid?: string | null;
  email?: string | null;
  displayName?: string | null;
  photoURL?: string | null;
  emailVerified?: boolean;
  status?: string;
  roleId?: string;
  cellNumber?: string;
  updatedOn?: Timestamp;
  updatedBy?: UpdatedBy;
  userLocationIds?: string[];
  currentUserLocationId?: string;
  dailyTotals?: boolean;
  netcashAuth?: boolean;
  importResult?: boolean;
}

export interface PolicyCRUD extends CRUD {
  approveRequest?: boolean;
  approveUnconfirmed?: boolean;
  override?: boolean;
  claim?: boolean;
  export?: boolean;
}

export interface TransactionCRUD extends CRUD {
  override?: boolean;
  export?: boolean;
  transfer?: boolean;
  reverse?: boolean;
  allocate?: boolean;
  amendUnallocated?: boolean;
  amendLastPeriodPaid?: boolean;
  userMonitoring?: boolean;
  receiptReprint?: boolean;
}

export interface DebitOrderCRUD extends CRUD {
  retryBatch?: boolean;
}

export interface UserCRUD extends CRUD {
  createPublicReport?: boolean;
  locationManagement?: boolean;
  roles?: boolean;
}

export interface MobileRights {
  payments?: boolean;
  cashup?: boolean;
  reprint?: boolean;
}

export interface ImportRights {
  payAt?: boolean;
  eft?: boolean;
  netcash?: boolean;
}
export interface RoleRights {
  users?: UserCRUD;
  policies?: PolicyCRUD;
  products?: CRUD;
  transactions?: TransactionCRUD;
  debitOrders?: DebitOrderCRUD;
  mobile?: MobileRights;
  imports?: ImportRights;
}

export interface Role extends RoleRights {
  id?: string;
  name?: string;
  status?: string;
  createdOn?: Timestamp;
  updatedOn?: Timestamp;
  createdBy?: CreatedBy;
  updatedBy?: UpdatedBy;
}

export type RoleWithRightsIndex = Role & {
  [category in keyof RoleRights]?: RoleRights[category];
};

export interface Preset {
  users: UserPreset[];
}

export interface UserPreset {
  email?: string;
  displayName?: string;
  userLocationIds?: string[];
  roleId?: string;
  cellNumber?: string;
  dailyTotals?: boolean;
  netcashAuth?: boolean;
  importResult?: boolean;
  createdOn?: Timestamp;
  createdBy?: CreatedBy;
}

export enum UserStatus {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface UserCount {
  id?: string;
  count?: {
    [key in UserStatus]?: number;
  };
}

export interface SnakeDoc {
  user: { uid: string; displayName: string; highScore: number }[];
}

export interface DailyCashUp {
  id?: string;
  day?: Timestamp;
  cashUps?: CashUp[];
}

export interface CashUp {
  id?: string;
  cashExpected?: number;
  cashDeclared?: number;
  cashVariance?: number;
  cardExpected?: number;
  cardDeclared?: number;
  cardVariance?: number;
  comment?: string;
  createdOn?: Timestamp;
  createdBy?: CreatedBy;
  updatedBy?: UpdatedBy;
  updatedOn?: Timestamp;
}
