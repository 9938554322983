import { EventEmitter, Injectable, ViewChild } from '@angular/core';
import { AddOn, AddOnPlan, AddOnStatus } from '../models/addOn.model';
import { SnackBarService } from './snack-bar.service';
import { uuidv4 } from '@firebase/util';
import { UserService } from './user.service';
import { Timestamp } from '@firebase/firestore';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Observable, Subject, catchError, map, takeUntil } from 'rxjs';
import { FilterService } from './filter.service';
import { PlanService } from './plan.service';
import { DateTimeService } from './date-time.service';
import { MainService } from './main.service';
import { Router } from '@angular/router';
import { Plan } from '../models/plan.model';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentReference,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
} from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class AddOnService {
  private dbModular = getFirestore();

  selectedAddOn: AddOn | undefined;
  selectedAddOnPlan: AddOnPlan;
  allAddOns: AddOn[];

  selectedAddOnPlanIndex: number;

  dataSourceAddOns: MatTableDataSource<any>;
  dataSourceAddOnPlans: MatTableDataSource<any>;
  dataSourceAddOnPlanPremiums: MatTableDataSource<any>;

  selectedAddOn$: Observable<AddOn | undefined>;
  addOnDoc: DocumentReference<AddOn>;

  selectedAddOnUpdated = new EventEmitter<void>();
  selectedPlansUpdated = new EventEmitter<void>();
  selectedAddOnPlanUpdated = new EventEmitter<void>();
  selectedPlanPremiumsUpdated = new EventEmitter<void>();

  @ViewChild(MatSort) sort: MatSort;

  destroy$ = new Subject<void>();

  // Form state
  loading = false;
  success = false;

  addOnsUnsubscribed: boolean = false;

  constructor(
    private userService: UserService,
    private filterService: FilterService,
    public snackBarService: SnackBarService,
    private planService: PlanService,
    private dateTimeService: DateTimeService,
    private router: Router,
    private mainService: MainService
  ) {
    this.userService.destroy$.pipe().subscribe(() => this.cleanUp());
  }

  loadAddOns(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const addOnsCollectionRef = collection(this.dbModular, 'addOn');
      const q = query(addOnsCollectionRef, orderBy('name'));

      const unsubscribe = onSnapshot(q, {
        next: (querySnapshot) => {
          const addOns: AddOn[] = [];
          querySnapshot.forEach((doc) => {
            addOns.push({ ...(doc.data() as AddOn), id: doc.id });
          });

          // Sort the add-ons so active ones appear first
          const sortedAddOns = addOns.sort((a, b) => {
            if (
              (a.status === AddOnStatus.ACTIVE) ===
              (b.status === AddOnStatus.ACTIVE)
            ) {
              return 0;
            }
            return a.status === AddOnStatus.ACTIVE ? -1 : 1;
          });

          this.dataSourceAddOns = new MatTableDataSource(sortedAddOns);
          this.dataSourceAddOns.sort = this.sort;
          this.allAddOns = sortedAddOns;
          this.filterService.filterClicked.addOns = false;
          this.filterService.toggleInactiveFilter(
            'addOns',
            this.dataSourceAddOns
          );
          this.addOnsUnsubscribed = false;
          resolve();
        },
        error: (err) => {
          if (err instanceof Error) {
            this.snackBarService.latestError = err.message;
            this.snackBarService.openRedSnackBar('LOADING ADD-ONS FAILED!');
          }
          reject(err);
        },
      });

      // Use the takeUntil pattern to unsubscribe when needed
      this.destroy$.subscribe(() => unsubscribe());
    });
  }

  /**
   * Sets the selected add-on based on a given add-on ID with real-time subscription.
   * @param addOnId - The ID of the add-on to fetch.
   * @returns A promise that resolves when the selected add-on is set.
   */
  async setSelectedAddOn(addOnId?: string): Promise<void> {
    if (!addOnId) {
      this.router.navigate(['/products']);
      throw new Error('ADD-ON ID NOT PROVIDED!');
    }

    sessionStorage.setItem('selectedAddOnId', addOnId);

    const addOnDocRef = doc(this.dbModular, 'addOn', addOnId);

    return new Promise<void>((resolve, reject) => {
      const unsubscribe = onSnapshot(
        addOnDocRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            this.selectedAddOn = {
              ...(docSnapshot.data() as AddOn),
              id: docSnapshot.id,
            };
            this.refreshAddOnPlans();
            this.refreshSelectedAddOnPlan();
            this.selectedAddOnUpdated.next();
            resolve();
          } else {
            this.snackBarService.openRedSnackBar('ADD-ON DOCUMENT NOT FOUND!');
            reject(new Error('ADD-ON DOCUMENT NOT FOUND!'));
          }
        },
        (error) => {
          if (error instanceof Error) {
            this.snackBarService.latestError = error.message;
            this.snackBarService.openRedSnackBar(
              'ERROR SETTING SELECTED ADD-ON'
            );
            reject(error);
          }
        }
      );

      this.destroy$.subscribe(() => unsubscribe());
    });
  }

  // Set the array of all add-ons
  setAllAddOns(addOns: AddOn[]) {
    this.allAddOns = addOns;
  }

  // finds the ID of an add-on by name
  getAddOnById(addOnId: string) {
    if (this.allAddOns) {
      return this.allAddOns.find((addOn) => addOn.id === addOnId);
    } else {
      return {};
    }
  }

  // finds the ID of an add-on by name
  getAddOnIdByName(addOnName: string) {
    return this.allAddOns.find((addOn) => addOn.name === addOnName)?.id;
  }

  getCurrentAddOnPlanStandardPremiumById(
    planId: string,
    addOnId: string,
    timestamp?: Timestamp
  ): number | undefined {
    const addOnPlanPremiumHistory = (
      this.selectedAddOn?.id === addOnId
        ? this.selectedAddOn.plans
        : this.getAddOnPlans(addOnId)
    )?.find((addOnPlan) => addOnPlan.planId === planId)?.premium;

    if (!addOnPlanPremiumHistory) return undefined;

    const sortedPremiums = addOnPlanPremiumHistory.sort(
      (a, b) =>
        this.dateTimeService.verifyTimestamp(b.startDate).toMillis() -
        this.dateTimeService.verifyTimestamp(a.startDate).toMillis()
    );

    const nowAtMidnight = this.dateTimeService
      .setHoursToMidnight(Timestamp.now())
      .toMillis();
    let adjustedTimestamp = nowAtMidnight;

    if (timestamp) {
      const providedTimestampAtMidnight = this.dateTimeService
        .setHoursToMidnight(timestamp)
        .toMillis();
      if (providedTimestampAtMidnight <= nowAtMidnight) {
        adjustedTimestamp = providedTimestampAtMidnight;
      }
    }

    const currentPremium = sortedPremiums.find(
      (premium) =>
        this.dateTimeService.setHoursToMidnight(premium.startDate).toMillis() <=
        adjustedTimestamp
    );

    return currentPremium ? currentPremium.standardAmount : undefined;
  }

  getCurrentAddOnPlanWaitingPremiumById(
    planId: string,
    addOnId: string,
    timestamp?: Timestamp
  ): number | undefined {
    const addOnPlanPremiumHistory = (
      this.selectedAddOn?.id === addOnId
        ? this.selectedAddOn.plans
        : this.getAddOnPlans(addOnId)
    )?.find((addOnPlan) => addOnPlan.planId === planId)?.premium;

    if (!addOnPlanPremiumHistory) return undefined;

    const sortedPremiums = addOnPlanPremiumHistory.sort(
      (a, b) =>
        this.dateTimeService.verifyTimestamp(b.startDate).toMillis() -
        this.dateTimeService.verifyTimestamp(a.startDate).toMillis()
    );

    const nowAtMidnight = this.dateTimeService
      .setHoursToMidnight(Timestamp.now())
      .toMillis();
    let adjustedTimestamp = nowAtMidnight;

    if (timestamp) {
      const providedTimestampAtMidnight = this.dateTimeService
        .setHoursToMidnight(timestamp)
        .toMillis();
      if (providedTimestampAtMidnight <= nowAtMidnight) {
        adjustedTimestamp = providedTimestampAtMidnight;
      }
    }

    const currentPremium = sortedPremiums.find(
      (premium) =>
        this.dateTimeService.setHoursToMidnight(premium.startDate).toMillis() <=
        adjustedTimestamp
    );

    return currentPremium ? currentPremium.waitingAmount : undefined;
  }

  // Get the name of an add-on by its ID
  getAddOnNameById(addOnId: string) {
    return this.allAddOns.find((addOn) => addOn.id === addOnId)?.name
      ? {
          text: this.allAddOns.find((addOn) => addOn.id === addOnId)?.name,
          class: [''],
        }
      : { text: '(NOT FOUND)', class: ['italic'] };
  }

  // Returns an array of addOn plans for the addOn with the given ID
  getAddOnPlans(addOnId: string) {
    return this.allAddOns.find((addOn) => addOn.id === addOnId)?.plans;
  }

  // Get the AddOn based on the add-on ID received
  getAddOnPlanById(planId: string, addOnId: string) {
    const addOn = this.allAddOns.find((addOn) => addOn.id === addOnId);
    return addOn?.plans?.find((addOnPlan) => addOnPlan.planId === planId);
  }

  addOnHasSelectedPlanId(addOn: AddOn, planId: string): boolean {
    return (
      addOn.status !== 'INACTIVE' &&
      (addOn.plans?.some(
        (addOnPlan) =>
          addOnPlan.planId === planId && addOnPlan.status !== 'INACTIVE'
      ) ??
        false)
    );
  }

  getAllowedAddOnPlans(currentAddOnPlanId?: string) {
    const plans = this.planService.allPlans;

    return plans.filter((plan) =>
      this.shouldIncludePlan(plan, currentAddOnPlanId)
    );
  }

  shouldIncludePlan(plan: Plan, currentAddOnPlanId?: string) {
    const isPlanNotAdded = !this.selectedAddOn?.plans?.some(
      (addOnPlan) => addOnPlan.planId === plan.id
    );

    const hasNoActiveVersion =
      this.selectedAddOn?.plans?.some(
        (addOnPlan) => addOnPlan.planId === plan.id
      ) &&
      !this.selectedAddOn?.plans?.some(
        (addOnPlan) =>
          addOnPlan.planId === plan.id && addOnPlan.status === 'ACTIVE'
      );

    const isCurrentAddonPlan = plan.id === currentAddOnPlanId;

    return (
      isPlanNotAdded ||
      (hasNoActiveVersion && plan.status !== 'INACTIVE') ||
      isCurrentAddonPlan
    );
  }

  /**
   * Creates a new add-on and adds it to the specified collection.
   * @param formValues - The form values containing the add-on details.
   * @param collectionName - The Firestore collection name where the add-on will be added.
   */
  async createAddOn(formValues: any, collectionName: string) {
    this.loading = true;
    try {
      const plans: never[] = [];
      const createdBy = {
        uid: this.userService.userData?.uid,
        displayName: this.userService.userData?.displayName,
        email: this.userService.userData?.email,
        userLocationId: this.userService.userData?.currentUserLocationId,
      };
      const createdOn = Timestamp.now();

      const modifiedFormValues = { ...formValues, plans, createdBy, createdOn };
      const docRef = await addDoc(
        collection(this.dbModular, collectionName),
        modifiedFormValues
      );
      await this.setSelectedAddOn(docRef.id);
      this.success = true;
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('ADD-ON CREATION FAILED!');
    } finally {
      this.loading = false;
    }
  }

  /**
   * Updates an existing add-on with the provided form values.
   * @param formValues - The form values containing the updated add-on details.
   * @param addOnDocId - The document ID of the add-on to update.
   */
  async updateAddOn(formValues: any, addOnDocId?: string) {
    this.loading = true;
    try {
      if (!addOnDocId) {
        throw new Error('NO ADD-ON SELECTED');
      }

      const updatedBy = {
        uid: this.userService.userData?.uid,
        displayName:
          this.userService.userData?.displayName ??
          this.userService.userData?.email,
        userLocationId: this.userService.userData?.currentUserLocationId,
      };
      const updatedOn = Timestamp.now();

      await updateDoc(doc(this.dbModular, 'addOn', addOnDocId), {
        ...formValues,
        updatedBy,
        updatedOn,
      });
      this.success = true;
      this.snackBarService.openBlueSnackBar('ADD-ON UPDATED SUCCESSFULLY!');
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('ADD-ON UPDATE FAILED!');
    } finally {
      this.loading = false;
    }
  }

  /**
   * Updates the plans of the given add-on document.
   * @param addOnDoc - The add-on document to update.
   * @param formValues - The form values containing the updated plan details.
   * @param i - The index of the plan to update.
   * @param remove - Optional flag indicating if the plan should be removed.
   */
  async updateAddOnPlans(
    addOnDoc: AddOn,
    formValues: any,
    i: number,
    remove?: boolean
  ) {
    this.loading = true;
    try {
      if (!addOnDoc.id) {
        throw new Error('NO ADD-ON SELECTED');
      }

      if (i === -1) {
        const id = uuidv4();
        const createdBy = {
          uid: this.userService.userData?.uid,
          displayName: this.userService.userData?.displayName,
          email: this.userService.userData?.email,
          userLocationId: this.userService.userData?.currentUserLocationId,
        };
        const createdOn = Timestamp.now();
        addOnDoc.plans?.push({ ...formValues, id, createdBy, createdOn });
      } else if (remove) {
        addOnDoc.plans?.splice(i, 1);
      } else {
        const updatedBy = {
          uid: this.userService.userData?.uid,
          displayName:
            this.userService.userData?.displayName ??
            this.userService.userData?.email,
          userLocationId: this.userService.userData?.currentUserLocationId,
        };
        const updatedOn = Timestamp.now();

        addOnDoc.plans![i] = {
          ...addOnDoc.plans![i],
          ...formValues,
          updatedBy,
          updatedOn,
        };
      }

      await updateDoc(doc(this.dbModular, 'addOn', addOnDoc.id), {
        plans: addOnDoc.plans,
      });
      this.selectedPlansUpdated.emit();
      this.success = true;
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('ADD-ON PLAN UPDATE FAILED!');
    } finally {
      this.loading = false;
    }
  }

  /**
   * Updates the premium of the selected add-on plan.
   * @param formValues - The form values containing the updated premium details.
   * @param i - The index of the premium to update.
   * @param remove - Optional flag indicating if the premium should be removed.
   */
  async updateSelectedAddOnPlanPremium(
    formValues: any,
    i: number,
    remove?: boolean
  ) {
    this.loading = true;
    try {
      if (!this.selectedAddOn?.id) {
        throw new Error('NO ADD-ON SELECTED');
      }

      const updatedBy = {
        uid: this.userService.userData?.uid,
        displayName:
          this.userService.userData?.displayName ??
          this.userService.userData?.email,
        userLocationId: this.userService.userData?.currentUserLocationId,
      };
      const updatedOn = Timestamp.now();

      if (formValues.startDate instanceof Date) {
        formValues.startDate = this.dateTimeService.dateToTimestamp(
          formValues.startDate
        );
      }

      if (i === -1) {
        const createdBy = {
          uid: this.userService.userData?.uid,
          displayName: this.userService.userData?.displayName,
          email: this.userService.userData?.email,
          userLocationId: this.userService.userData?.currentUserLocationId,
        };
        const createdOn = Timestamp.now();
        this.selectedAddOnPlan.premium?.push({
          ...formValues,
          createdBy,
          createdOn,
        });
      } else if (remove) {
        this.selectedAddOnPlan.premium?.splice(i, 1);
      } else {
        this.selectedAddOnPlan.premium![i] = {
          ...this.selectedAddOnPlan.premium![i],
          ...formValues,
          updatedBy,
          updatedOn,
        };
      }

      this.selectedAddOnPlan.premium?.sort((a, b) => {
        if (a.startDate.seconds > b.startDate.seconds) return -1;
        if (a.startDate.seconds < b.startDate.seconds) return 1;
        return 0;
      });

      this.selectedAddOn.plans![this.selectedAddOnPlanIndex] = {
        ...this.selectedAddOn.plans![this.selectedAddOnPlanIndex],
        ...this.selectedAddOnPlan,
        updatedBy,
        updatedOn,
      };

      await updateDoc(doc(this.dbModular, 'addOn', this.selectedAddOn.id), {
        plans: this.selectedAddOn.plans,
      });
      this.selectedPlanPremiumsUpdated.emit();
      this.success = true;
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('ADD-ON PLAN UPDATE FAILED!');
    } finally {
      this.loading = false;
    }
  }

  /**
   * Deletes an add-on from the Firestore database.
   * @param docId - The document ID of the add-on to delete.
   */
  async deleteAddOn(docId: string) {
    this.loading = true;
    try {
      await deleteDoc(doc(this.dbModular, 'addOn', docId));
      this.success = true;
      this.snackBarService.openBlueSnackBar('ADD-ON DELETED SUCCESSFULLY!');
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('ADD-ON DELETE FAILED!');
    } finally {
      this.loading = false;
    }
  }

  public refreshAddOnPlans() {
    this.dataSourceAddOnPlans = new MatTableDataSource(
      this.selectedAddOn?.plans
    );
    this.filterService.filterClicked.addOnPlans = false;
    this.filterService.toggleInactiveFilter(
      'addOnPlans',
      this.dataSourceAddOnPlans
    );
  }

  public refreshSelectedAddOnPlan() {
    if (
      this.selectedAddOn?.plans &&
      this.selectedAddOnPlanIndex >= 0 &&
      this.selectedAddOn?.plans[this.selectedAddOnPlanIndex]
    ) {
      this.selectedAddOnPlan =
        this.selectedAddOn.plans[this.selectedAddOnPlanIndex];
      this.dataSourceAddOnPlanPremiums = new MatTableDataSource(
        this.selectedAddOnPlan?.premium
      );
      this.selectedAddOnPlanUpdated.emit();
    }
  }

  resetSelectedAddOn() {
    this.selectedAddOn = undefined;
    this.dataSourceAddOnPlans = new MatTableDataSource<any>([]);
  }

  cleanUp() {
    this.destroy$.next();
    this.addOnsUnsubscribed = true;
  }
}
