import { EventEmitter, Injectable, ViewChild } from '@angular/core';
import { uuidv4 } from '@firebase/util';
import { Observable, Subject } from 'rxjs';
import { MemberType, Plan, PlanStatus } from '../models/plan.model';
import { SnackBarService } from './snack-bar.service';
import { UserService } from './user.service';
import { Timestamp } from '@firebase/firestore';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { FilterService } from './filter.service';
import { Member, Policy, PolicyStatus } from '../models/policy.model';
import { DateTimeService } from './date-time.service';
import { Router } from '@angular/router';
import { MainService } from './main.service';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
@Injectable({
  providedIn: 'root',
})
export class PlanService {
  private dbModular = getFirestore();

  selectedPlan: Plan | undefined;
  allPlans: Plan[];

  dataSourcePlans: MatTableDataSource<any>;
  dataSourceMemberTypes: MatTableDataSource<any>;
  dataSourceMemberTypePremiums: MatTableDataSource<any>;

  planDoc: DocumentReference<Plan>;
  selectedPlan$: Observable<Plan | undefined>;
  selectedPlanMemberType: MemberType;

  selectedPlanMemberTypeIndex: number;

  selectedPlanUpdated = new EventEmitter<void>();
  selectedMemberTypesUpdated = new EventEmitter<void>();
  selectedPlanMemberTypeUpdated = new EventEmitter<void>();
  selectedMemberTypePremiumsUpdated = new EventEmitter<void>();

  @ViewChild(MatSort) sort: MatSort;

  destroy$ = new Subject<void>();

  public overrideMemberIdAge: boolean = false;
  public requestOverrideMemberIdAge: boolean = false;

  // Form state
  loading = false;
  success = false;

  plansUnsubscribed: boolean = false;

  constructor(
    private userService: UserService,
    private filterService: FilterService,
    private snackBarService: SnackBarService,
    private dateTimeService: DateTimeService,
    private router: Router
  ) {
    this.userService.destroy$?.pipe()?.subscribe(() => this.cleanUp());
  }

  /**
   * Loads all plans from Firestore with real-time subscription.
   * @returns A promise that resolves when the plans are loaded.
   */
  loadPlans(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const plansCollectionRef = collection(this.dbModular, 'plan');
      const plansQuery = query(plansCollectionRef, orderBy('name'));

      const unsubscribe = onSnapshot(
        plansQuery,
        (snapshot) => {
          const plans = snapshot.docs.map((doc) => ({
            ...(doc.data() as Plan),
            id: doc.id,
          }));

          this.dataSourcePlans = new MatTableDataSource(plans);
          this.dataSourcePlans.sort = this.sort;
          this.setAllPlans(plans);
          this.filterService.filterClicked.plans = false;
          this.filterService.toggleInactiveFilter(
            'plans',
            this.dataSourcePlans
          );
          this.plansUnsubscribed = false;
          resolve();
        },
        (error) => {
          this.snackBarService.latestError = error.message;
          this.snackBarService.openRedSnackBar('LOADING PLANS FAILED!');
          reject(error);
        }
      );

      this.destroy$.subscribe(() => unsubscribe());
    });
  }

  /**
   * Sets the selected plan based on a given plan ID with real-time subscription.
   * @param planId - The ID of the plan to fetch.
   * @returns A promise that resolves when the selected plan is set.
   */
  async setSelectedPlan(planId?: string): Promise<void> {
    if (!planId) {
      this.router.navigate(['/products']);
      throw new Error('PLAN ID NOT PROVIDED!');
    }

    sessionStorage.setItem('selectedPlanId', planId);

    const planDocRef = doc(this.dbModular, 'plan', planId);

    return new Promise<void>((resolve, reject) => {
      const unsubscribe = onSnapshot(
        planDocRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            this.selectedPlan = {
              ...(docSnapshot.data() as Plan),
              id: docSnapshot.id,
            };
            this.refreshPlanMembers();
            this.refreshSelectedPlanMemberType();
            this.selectedPlanUpdated.emit();
            resolve();
          } else {
            this.snackBarService.openRedSnackBar('PLAN DOCUMENT NOT FOUND!');
            reject(new Error('PLAN DOCUMENT NOT FOUND!'));
          }
        },
        (error) => {
          if (error instanceof Error) {
            this.snackBarService.latestError = error.message;
            this.snackBarService.openRedSnackBar('ERROR SETTING SELECTED PLAN');
            reject(error);
          }
        }
      );

      this.destroy$.subscribe(() => unsubscribe());
    });
  }

  setAllPlans(plans: Plan[]) {
    // Sort the plans so active ones appear first
    const sortedPlans = plans.sort((a, b) => {
      // If both have the same 'active' status, maintain their order
      if (
        (a.status === PlanStatus.ACTIVE) ===
        (b.status === PlanStatus.ACTIVE)
      ) {
        return 0;
      }
      // If 'a' is active and 'b' is not, 'a' should come first
      return a.status === PlanStatus.ACTIVE ? -1 : 1;
    });

    this.allPlans = sortedPlans;
  }

  /**
   * Retrieves a list of allowed plans, filtered based on their status or the provided current plan ID.
   *
   * @param {string} [currentPlanId] - The ID of the current plan to include even if inactive.
   * @returns {Plan[]} An array of allowed plans that are active or match the current plan ID.
   */
  getAllowedPlans(currentPlanId?: string): Plan[] {
    return (this.allPlans ?? []).filter(
      (plan) => plan.status === PlanStatus.ACTIVE || plan.id === currentPlanId
    );
  }

  // Returns the ID of the plan with the given name.
  getPlanIdByName(planName: string) {
    return this.allPlans.find((plan) => plan.name === planName)?.id;
  }

  getCurrentMemberStandardPremiumById(
    planId: string,
    memberTypeId: string,
    timestamp?: Timestamp
  ): number | undefined {
    const memberPremiumHistory = (
      this.selectedPlan?.id === planId
        ? this.selectedPlan.memberType
        : this.getPlanMemberTypes(planId)
    )?.find((memberType) => memberType.id === memberTypeId)?.premium;

    if (!memberPremiumHistory) return undefined;

    const sortedPremiums = memberPremiumHistory.sort(
      (a, b) =>
        this.dateTimeService.verifyTimestamp(b.startDate).toMillis() -
        this.dateTimeService.verifyTimestamp(a.startDate).toMillis()
    );

    const now = this.dateTimeService
      .setHoursToMidnight(Timestamp.now())
      .toMillis();
    let adjustedTimestamp = now;

    if (timestamp) {
      const providedTimestamp = this.dateTimeService
        .setHoursToMidnight(timestamp)
        .toMillis();
      if (providedTimestamp <= now) {
        adjustedTimestamp = providedTimestamp;
      }
    }

    const currentPremium = sortedPremiums.find(
      (premium) =>
        this.dateTimeService.setHoursToMidnight(premium.startDate).toMillis() <=
        adjustedTimestamp
    );

    return currentPremium ? currentPremium.standardAmount : undefined;
  }

  getCurrentMemberWaitingPremiumById(
    planId: string,
    memberTypeId: string,
    timestamp?: Timestamp
  ): number | undefined {
    const memberPremiumHistory = (
      this.selectedPlan?.id === planId
        ? this.selectedPlan.memberType
        : this.getPlanMemberTypes(planId)
    )?.find((memberType) => memberType.id === memberTypeId)?.premium;

    if (!memberPremiumHistory) return undefined;

    const sortedPremiums = memberPremiumHistory.sort(
      (a, b) =>
        this.dateTimeService.verifyTimestamp(b.startDate).toMillis() -
        this.dateTimeService.verifyTimestamp(a.startDate).toMillis()
    );

    const now = this.dateTimeService
      .setHoursToMidnight(Timestamp.now())
      .toMillis();
    let adjustedTimestamp = now;

    if (timestamp) {
      const providedTimestamp = this.dateTimeService
        .setHoursToMidnight(timestamp)
        .toMillis();
      if (providedTimestamp <= now) {
        adjustedTimestamp = providedTimestamp;
      }
    }

    const currentPremium = sortedPremiums.find(
      (premium) =>
        this.dateTimeService.setHoursToMidnight(premium.startDate).toMillis() <=
        adjustedTimestamp
    );

    return currentPremium ? currentPremium.waitingAmount : undefined;
  }

  // Returns the name of the plan with the given ID
  getPlanNameById(planId: string) {
    return this.allPlans.find((plan) => plan.id === planId)?.name
      ? {
          text: this.allPlans.find((plan) => plan.id === planId)?.name,
          class: [''],
        }
      : { text: '(NOT FOUND)', class: ['italic'] };
  }

  // Returns the plan with the given ID
  getPlanById(planId: string) {
    return this.allPlans.find((plan) => plan.id === planId);
  }

  // Returns an array of member types for the plan with the given ID
  getPlanMemberTypes(planId: string) {
    return this.allPlans.find((plan) => plan.id === planId)?.memberType;
  }

  getAllPlanMemberTypes(): MemberType[] {
    const memberTypesSet = new Set<MemberType>();

    this.allPlans.forEach((plan) => {
      if (plan.memberType && Array.isArray(plan.memberType)) {
        plan.memberType.forEach((memberType) => {
          memberTypesSet.add(memberType);
        });
      }
    });

    return Array.from(memberTypesSet);
  }

  getPrimaryMemberTypeByPlanId(planId: string) {
    // Use .find to locate the plan with the given planId
    const planData = this.allPlans.find((plan) => plan.id === planId);

    if (!planData) {
      return undefined;
    }

    // Find the primary member within the found plan
    const primaryMember = (planData.memberType || []).find(
      (mt) => mt.primaryMember
    );

    if (!primaryMember) {
      return undefined;
    }

    return primaryMember;
  }

  // Returns the ID of the member type with the given name, for the plan with the given ID
  getMemberTypeIdByName(planId: string, memberTypeName: string) {
    return this.getPlanMemberTypes(planId)?.find(
      (memberType) => memberType.name === memberTypeName
    )?.id;
  }

  getAllPrimaryMemberTypes() {
    let primaryMemberTypes: string[] = [];

    this.allPlans.forEach((plan) => {
      if (plan.id) {
        const primaryMemberId = this.getPrimaryMemberTypeByPlanId(plan.id)?.id;

        if (primaryMemberId) primaryMemberTypes.push(primaryMemberId);
      }
    });

    return primaryMemberTypes;
  }

  // Returns the name of the member type with the given ID.
  // If a planId is provided, it searches only within that plan. Otherwise, it searches all plans.
  getMemberTypeNameById(memberTypeId: string, planId: any = null) {
    if (planId) {
      return (
        this.getPlanMemberTypes(planId)?.find(
          (memberType) => memberType.id === memberTypeId
        )?.name ?? { text: '(NOT FOUND)', class: ['italic'] }
      );
    } else {
      for (const plan of this.allPlans) {
        const memberType = plan.memberType?.find(
          (type) => type.id === memberTypeId
        );
        if (memberType) {
          return memberType.name ?? { text: '(NOT FOUND)', class: ['italic'] };
        }
      }
    }
    return '';
  }

  getPreviousPlanToolTip(memberTypeId: string, planId?: string) {
    if (memberTypeId && planId) {
      let foundPlan = '';
      for (const plan of this.allPlans) {
        const memberType = plan.memberType?.find(
          (type) => type.id === memberTypeId
        );
        if (memberType) {
          if (plan.id !== planId && plan.name) {
            foundPlan = 'WAS ON ' + plan.name;
          }
          break;
        }
      }
      return foundPlan;
    }
    return '';
  }

  // Returns the member type with the given ID
  getMemberTypeById(memberTypeId: string) {
    if (memberTypeId) {
      for (const plan of this.allPlans) {
        const memberType = plan.memberType?.find(
          (type) => type.id === memberTypeId
        );
        if (memberType) {
          return memberType;
        }
      }
    }
    return {};
  }

  /**
   * Retrieves the age range for a specific member type within a plan.
   * @param planId - The ID of the plan.
   * @param memberTypeId - The ID of the member type.
   * @returns An object with the age range or null if not found.
   */
  async getMemberTypeAgeRange(planId: string, memberTypeId: string) {
    const plan = this.allPlans.find((p) => p.id === planId);

    if (plan) {
      const memberType = plan.memberType?.find((mt) => mt.id === memberTypeId);
      if (memberType) {
        return {
          ageFrom: memberType.ageFrom,
          ageTo: memberType.ageTo,
        };
      }
    } else {
      const planDocRef = doc(this.dbModular, 'plans', planId);
      const planSnapshot = await getDoc(planDocRef);

      if (planSnapshot.exists()) {
        const planData = planSnapshot.data() as Plan;
        const memberType = planData?.memberType?.find(
          (mt) => mt.id === memberTypeId
        );
        if (memberType) {
          return {
            ageFrom: memberType.ageFrom,
            ageTo: memberType.ageTo,
          };
        }
      }
    }

    return null;
  }

  /**
   * Retrieves the active primary member of a policy for a given plan.
   * @param planId - The ID of the plan.
   * @returns A promise that resolves with the active primary member or undefined.
   */
  async getActivePolicyPrimaryMember(
    planId: string
  ): Promise<Member | undefined> {
    try {
      const policiesCollectionRef = collection(this.dbModular, 'policy');
      const policiesQuery = query(
        policiesCollectionRef,
        where('planId', '==', planId),
        where('status', 'in', [
          PolicyStatus.ACTIVE,
          PolicyStatus.PENDING,
          PolicyStatus.ARREARS,
          PolicyStatus.IN_PROGRESS,
          PolicyStatus.UNCONFIRMED,
        ]),
        limit(20)
      );

      const snapshot = await getDocs(policiesQuery);

      const plan = this.allPlans?.find((p) => p.id === planId);
      if (plan) {
        const primaryMemberType = plan.memberType?.find(
          (mt) => mt.primaryMember
        );

        if (primaryMemberType) {
          for (let doc of snapshot.docs) {
            const policy = doc.data() as Policy;
            const activePrimaryMember = policy.members?.find(
              (member) =>
                member.status === 'ACTIVE' &&
                member.memberTypeId === primaryMemberType.id
            );

            if (activePrimaryMember) {
              return activePrimaryMember;
            }
          }
        }
      }

      return undefined;
    } catch (error) {
      if (error instanceof Error) {
        this.snackBarService.latestError = error.message;
        this.snackBarService.openRedSnackBar(
          'ERROR FINDING AN ACTIVE PRIMARY MEMBER!'
        );
        throw error;
      } else {
        throw new Error('An unexpected error occurred');
      }
    }
  }

  /**
   * Creates a new plan in the specified collection.
   * @param formValues - The form values containing the plan details.
   * @param collectionName - The Firestore collection name where the plan will be added.
   */
  async createPlan(formValues: any, collectionName: string) {
    this.loading = true;

    try {
      const memberType: never[] = [];
      const createdBy = {
        uid: this.userService.userData?.uid,
        displayName: this.userService.userData?.displayName,
        email: this.userService.userData?.email,
        userLocationId: this.userService.userData?.currentUserLocationId,
      };
      const createdOn = Timestamp.now();

      const modifiedFormValues = {
        ...formValues,
        memberType,
        createdBy,
        createdOn,
      };

      const docRef = await addDoc(
        collection(this.dbModular, collectionName),
        modifiedFormValues
      );
      const docId = docRef.id;

      this.setSelectedPlan(docId);
      this.success = true;
      this.router.navigate(['/plan-member-management', docId]);
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('PLAN CREATION FAILED!');
    } finally {
      this.loading = false;
    }
  }

  /**
   * Updates an existing plan with the provided form values.
   * @param formValues - The form values containing the updated plan details.
   * @param planDocId - The document ID of the plan to update.
   */
  async updatePlan(formValues: any, planDocId?: string) {
    this.loading = true;

    try {
      if (!planDocId) {
        throw new Error('PLAN ID IS UNDEFINED');
      }

      const updatedBy = {
        uid: this.userService.userData?.uid,
        displayName:
          this.userService.userData?.displayName ??
          this.userService.userData?.email,
        userLocationId: this.userService.userData?.currentUserLocationId,
      };
      const updatedOn = Timestamp.now();

      await updateDoc(doc(this.dbModular, 'plan', planDocId), {
        ...formValues,
        updatedBy,
        updatedOn,
      });
      this.success = true;
      this.snackBarService.openBlueSnackBar('PLAN UPDATED SUCCESSFULLY!');
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('PLAN UPDATE FAILED!');
    } finally {
      this.loading = false;
    }
  }

  /**
   * Updates the member types of the given plan.
   * @param planDoc - The plan document to update.
   * @param formValues - The form values containing the updated member type details.
   * @param i - The index of the member type to update.
   * @param remove - Optional flag indicating if the member type should be removed.
   */
  async updatePlanMembers(
    planDoc: Plan,
    formValues: any,
    i: number,
    remove?: boolean
  ) {
    this.loading = true;

    try {
      if (!planDoc.id) {
        throw new Error('PLAN ID IS UNDEFINED');
      }

      if (i === -1) {
        const id = uuidv4();
        const createdBy = {
          uid: this.userService.userData?.uid,
          displayName: this.userService.userData?.displayName,
          email: this.userService.userData?.email,
          userLocationId: this.userService.userData?.currentUserLocationId,
        };
        const createdOn = Timestamp.now();
        planDoc.memberType?.push({ ...formValues, id, createdBy, createdOn });
      } else if (remove) {
        planDoc.memberType?.splice(i, 1);
      } else {
        const updatedBy = {
          uid: this.userService.userData?.uid,
          displayName:
            this.userService.userData?.displayName ??
            this.userService.userData?.email,
          userLocationId: this.userService.userData?.currentUserLocationId,
        };
        const updatedOn = Timestamp.now();

        if (formValues.primaryMember) {
          planDoc.memberType!.forEach((member, index) => {
            if (i !== index) {
              member.primaryMember = false;
            }
          });
        }

        planDoc.memberType![i] = {
          ...planDoc.memberType![i],
          ...formValues,
          updatedBy,
          updatedOn,
        };
      }

      await updateDoc(doc(this.dbModular, 'plan', planDoc.id), {
        memberType: planDoc.memberType,
      });
      this.selectedMemberTypesUpdated.emit();
      this.success = true;
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('PLAN MEMBER UPDATE FAILED!');
    } finally {
      this.loading = false;
    }
  }

  /**
   * Updates the premium of the selected plan member type.
   * @param formValues - The form values containing the updated premium details.
   * @param i - The index of the premium to update.
   * @param remove - Optional flag indicating if the premium should be removed.
   */
  async updateSelectedPlanMemberTypePremium(
    formValues: any,
    i: number,
    remove?: boolean
  ) {
    this.loading = true;

    try {
      if (!this.selectedPlan?.id) {
        throw new Error('NO PLAN SELECTED');
      }

      const updatedBy = {
        uid: this.userService.userData?.uid,
        displayName:
          this.userService.userData?.displayName ??
          this.userService.userData?.email,
        userLocationId: this.userService.userData?.currentUserLocationId,
      };
      const updatedOn = Timestamp.now();

      if (formValues.startDate instanceof Date)
        formValues.startDate = this.dateTimeService.dateToTimestamp(
          formValues.startDate
        );

      if (i === -1) {
        const createdBy = {
          uid: this.userService.userData?.uid,
          displayName: this.userService.userData?.displayName,
          email: this.userService.userData?.email,
          userLocationId: this.userService.userData?.currentUserLocationId,
        };
        const createdOn = Timestamp.now();
        this.selectedPlanMemberType.premium?.push({
          ...formValues,
          createdBy,
          createdOn,
        });
      } else if (remove) {
        this.selectedPlanMemberType.premium?.splice(i, 1);
      } else {
        this.selectedPlanMemberType.premium![i] = {
          ...this.selectedPlanMemberType.premium![i],
          ...formValues,
          updatedBy,
          updatedOn,
        };
      }

      this.selectedPlanMemberType.premium?.sort((a, b) => {
        if (a.startDate.seconds > b.startDate.seconds) return -1;
        if (a.startDate.seconds < b.startDate.seconds) return 1;
        return 0;
      });

      this.selectedPlan.memberType![this.selectedPlanMemberTypeIndex] = {
        ...this.selectedPlan.memberType![this.selectedPlanMemberTypeIndex],
        ...this.selectedPlanMemberType,
        updatedBy,
        updatedOn,
      };

      await updateDoc(doc(this.dbModular, 'plan', this.selectedPlan.id), {
        memberType: this.selectedPlan.memberType,
      });
      this.selectedMemberTypePremiumsUpdated.emit();
      this.success = true;
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar(
        'PLAN MEMBER SCHEDULED UPDATE FAILED!'
      );
    } finally {
      this.loading = false;
    }
  }

  /**
   * Deletes a plan document from Firestore.
   * @param docId - The ID of the plan document to delete.
   */
  async deletePlan(docId: string) {
    this.loading = true;

    try {
      await deleteDoc(doc(this.dbModular, 'plan', docId));
      this.success = true;
      this.snackBarService.openBlueSnackBar('PLAN DELETED SUCCESSFULLY!');
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('PLAN DELETE FAILED!');
    } finally {
      this.loading = false;
    }
  }

  //Filter {

  public refreshPlanMembers() {
    if (this.selectedPlan)
      this.dataSourceMemberTypes = new MatTableDataSource(
        this.selectedPlan.memberType
      );
    this.filterService.filterClicked.planMembers = false;
    this.filterService.toggleInactiveFilter(
      'planMembers',
      this.dataSourceMemberTypes
    );
  }

  public refreshSelectedPlanMemberType() {
    if (
      this.selectedPlan?.memberType &&
      this.selectedPlanMemberTypeIndex >= 0 &&
      this.selectedPlan?.memberType[this.selectedPlanMemberTypeIndex]
    ) {
      this.selectedPlanMemberType =
        this.selectedPlan.memberType[this.selectedPlanMemberTypeIndex];
      this.dataSourceMemberTypePremiums = new MatTableDataSource(
        this.selectedPlanMemberType?.premium
      );
      this.selectedPlanMemberTypeUpdated.emit();
    }
  }

  resetSelectedPlan() {
    this.selectedPlan = undefined;
    this.dataSourceMemberTypes = new MatTableDataSource<any>([]);
  }

  cleanUp() {
    this.destroy$.next();
    this.plansUnsubscribed = true;
  }
}
