import { Timestamp } from '@firebase/firestore';
import { CreatedBy, UpdatedBy } from './general.model';
export interface Plan {
  id?: string;
  name?: string;
  status?: String;
  createdOn?: Timestamp;
  updatedOn?: Timestamp;
  createdBy?: CreatedBy;
  updatedBy?: UpdatedBy;
  memberType?: MemberType[];
}

export interface MemberType {
  id?: string;
  name?: string;
  primaryMember?: boolean;
  premium?: MemberPremium[];
  status?: string;
  maxAllowed?: number;
  waitingPeriod?: number;
  ageFrom?: number;
  ageTo?: number;
  createdOn?: Timestamp;
  updatedOn?: Timestamp;
  createdBy?: CreatedBy;
  updatedBy?: UpdatedBy;
}

export interface MemberPremium {
  standardAmount: number;
  waitingAmount: number;
  startDate: Timestamp;
  createdOn?: Timestamp;
  updatedOn?: Timestamp;
  createdBy?: CreatedBy;
  updatedBy?: UpdatedBy;
}

export enum PlanStatus {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
