<html [@fadeIn] [@fadeOut]>
  <div>
    <div
      class="logo-container"
      [ngStyle]="{
        left: !(screenSize.width <= 767 && screenSize.height <= 1023)
          ? userService.loggingIn
            ? '50%'
            : '20%'
          : '50%',
        top:
          !userService.loggingIn &&
          screenSize.width <= 767 &&
          screenSize.height <= 1023
            ? '20%'
            : '50%'
      }"
    >
      <img
        class="logo"
        src="../../../../../assets/images/logo.svg"
        alt="Logo"
      />
      <div
        [ngClass]="{
          'shadow-loading': userService.loggingIn,
          shadow: !userService.loggingIn
        }"
      ></div>
    </div>

    <div class="parent-container">
      <div class="form-container">
        <form
          *ngIf="
            !userService.isSignUpPassword &&
            !userService.isForgotPassword &&
            !userService.isSelectingLocation &&
            !userService.initialLoadingDone &&
            !userService.loggingIn
          "
          [formGroup]="loginForm"
          (keydown.enter)="submitLoginForm($any($event))"
        >
          <mat-form-field appearance="fill" color="primary" class="maxWidth">
            <mat-label>EMAIL</mat-label>
            <input
              matInput
              formControlName="email"
              type="email"
              #email="matInput"
              autofocus
            />
          </mat-form-field>
          <mat-form-field appearance="fill" color="primary" class="maxWidth">
            <mat-label>PASSWORD</mat-label>
            <input
              matInput
              formControlName="password"
              [type]="showPassword ? 'text' : 'password'"
              #password="matInput"
            />
            <button
              mat-icon-button
              matSuffix
              (click)="togglePasswordVisibility()"
            >
              <mat-icon class="whiteColor">{{
                showPassword ? "visibility_off" : "visibility"
              }}</mat-icon>
            </button>
          </mat-form-field>
          <button
            mat-raised-button
            color="primary"
            class="loginButton Montserrat"
            (click)="userService.logIn(email.value, password.value)"
            [disabled]="!loginForm.valid"
          >
            LOGIN
          </button>
          <button
            mat-button
            color="primary"
            class="forgotPasswordButton Montserrat"
            (click)="toggleForgotPassword()"
          >
            FORGOT PASSWORD?
          </button>
          <div>
            <h3 class="version-text">
              {{ environment.homeTitle }} V{{ version }}
            </h3>
          </div>
        </form>

        <!-- SIGN UP WITH EMAIL LINK FORM -->
        <form *ngIf="userService.isSignUpPassword" [formGroup]="signUpForm">
          <mat-form-field appearance="fill" color="primary" class="maxWidth">
            <mat-label>EMAIL</mat-label>
            <input
              matInput
              formControlName="email"
              type="email"
              #email="matInput"
            />
          </mat-form-field>
          <mat-form-field appearance="fill" color="primary" class="maxWidth">
            <mat-label>PASSWORD</mat-label>
            <input
              matInput
              formControlName="password"
              [type]="showPassword ? 'text' : 'password'"
              #password="matInput"
            />
            <button
              mat-icon-button
              matSuffix
              (click)="togglePasswordVisibility()"
            >
              <mat-icon class="whiteColor">{{
                showPassword ? "visibility_off" : "visibility"
              }}</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field appearance="fill" color="primary" class="maxWidth">
            <mat-label>CONFIRM PASSWORD</mat-label>
            <input
              matInput
              formControlName="passwordConfirm"
              [type]="showConfirmPassword ? 'text' : 'password'"
              #passwordConfirm
            />
            <button
              mat-icon-button
              matSuffix
              (click)="toggleConfirmPasswordVisibility()"
            >
              <mat-icon class="whiteColor">{{
                showConfirmPassword ? "visibility_off" : "visibility"
              }}</mat-icon>
            </button>
          </mat-form-field>
          <button
            mat-raised-button
            color="primary"
            class="loginButton Montserrat"
            (click)="
              userService.completeSignInWithPassword(
                email.value,
                password.value
              )
            "
            [disabled]="!passwordDoesMatch || !signUpForm.valid"
          >
            CREATE ACCOUNT
          </button>
        </form>

        <!-- SEND FORGOT PASSWORD LINK FORM -->
        <form
          *ngIf="userService.isForgotPassword"
          [formGroup]="forgotPasswordForm"
        >
          <mat-form-field appearance="fill" color="primary" class="maxWidth">
            <mat-label>EMAIL</mat-label>
            <input
              oninput="this.value = this.value.toUpperCase()"
              matInput
              formControlName="email"
              type="email"
              #email="matInput"
              autofocus
            />
          </mat-form-field>

          <div class="pb15">
            <button
              mat-raised-button
              color="primary"
              class="forgotPasswordButton Montserrat"
              (click)="forgotPassword(email.value)"
              [disabled]="!forgotPasswordForm.valid"
            >
              SEND PASSWORD RESET LINK
            </button>
          </div>
          <div>
            <button
              mat-raised-button
              color="primary"
              class="forgotPasswordButton Montserrat"
              (click)="toggleForgotPassword()"
            >
              CANCEL
            </button>
          </div>
        </form>

        <!-- SET CURRENT USER LOCATION -->
        <form
          *ngIf="
            !userService.isSignUpPassword &&
            !userService.isForgotPassword &&
            userService.isSelectingLocation &&
            userService.userData?.userLocationIds
          "
          [formGroup]="setCurrentLocationForm"
        >
          <mat-form-field appearance="fill" color="primary" class="maxWidth">
            <mat-label>LOCATION</mat-label>
            <mat-select
              formControlName="userLocationId"
              [(ngModel)]="userService.selectedUserLocationId"
            >
              <ng-container
                *ngFor="let locationId of userService.userData?.userLocationIds"
              >
                <ng-container
                  *ngIf="
                    userService.getUserLocationById(locationId)?.status !=
                    LocationStatus.INACTIVE
                  "
                >
                  <mat-option [value]="locationId">
                    {{ userService.getUserLocationById(locationId)?.name }}
                  </mat-option>
                </ng-container>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <div class="pb15">
            <button
              mat-raised-button
              color="primary"
              class="forgotPasswordButton Montserrat"
              (click)="setUserLocation()"
              [disabled]="!setCurrentLocationForm.valid"
            >
              CONFIRM
            </button>
          </div>
          <div>
            <button
              mat-raised-button
              color="primary"
              class="forgotPasswordButton Montserrat"
              (click)="logOut()"
            >
              LOGOUT
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</html>
