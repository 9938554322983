<ng-container
  *ngTemplateOutlet="navContainer; context: { $implicit: isHandset$ | async }"
></ng-container>
<ng-template #navContainer let-isHandset
  ><mat-sidenav-container
    class="sidenav-container"
    [ngClass]="{ isDisabled: !rolesRightsService.connected }"
  >
    <mat-sidenav
      #drawer
      class="sidenav maxHeight"
      fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="'side'"
      [opened]="(isHandset$ | async) ? 'false' : 'true'"
    >
      <mat-nav-list>
        <a
          mat-list-item
          routerLink="/home"
          routerLinkActive="is-active"
          (click)="isHandset ? closeDrawerIfHandset(drawer) : null"
          [@fadeIn]
          matTooltip="Home"
          matTooltipPosition="right"
          ><mat-icon class="mat-icon">home</mat-icon></a
        >
        <a
          *ngIf="rolesRightsService.currentUserRole?.policies?.read"
          mat-list-item
          routerLink="/policies"
          [class.is-active]="routeIsActive.policies"
          (click)="isHandset ? closeDrawerIfHandset(drawer) : null"
          [@fadeIn]
          matTooltip="Policies"
          matTooltipPosition="right"
          ><mat-icon class="mat-icon">perm_identity</mat-icon></a
        >
        <a
          *ngIf="rolesRightsService.currentUserRole?.transactions?.read"
          mat-list-item
          routerLink="/transactions"
          [class.is-active]="routeIsActive.transactions"
          (click)="isHandset ? closeDrawerIfHandset(drawer) : null"
          [@fadeIn]
          matTooltip="Transactions"
          matTooltipPosition="right"
          ><mat-icon class="mat-icon">credit_card</mat-icon></a
        >
        <a
          *ngIf="
            rolesRightsService.currentUserRole?.policies?.export ||
            rolesRightsService.currentUserRole?.transactions?.export
          "
          mat-list-item
          routerLink="/reports"
          [class.is-active]="routeIsActive.reports"
          (click)="isHandset ? closeDrawerIfHandset(drawer) : null"
          [@fadeIn]
          matTooltip="Reports"
          matTooltipPosition="right"
          ><mat-icon class="mat-icon">feed</mat-icon></a
        >
        <a
          *ngIf="
            rolesRightsService.currentUserRole?.products?.read ||
            rolesRightsService.currentUserRole?.users?.read
          "
          mat-list-item
          class="bottom settings"
          routerLink="/settings"
          [class.is-active]="routeIsActive.settings"
          (click)="isHandset ? closeDrawerIfHandset(drawer) : null"
          [@fadeIn]
          matTooltip="Settings"
          matTooltipPosition="right"
          ><mat-icon class="mat-icon">settings</mat-icon></a
        >
        <a
          mat-list-item
          class="bottom"
          (click)="openLogoutDialog()"
          [@fadeIn]
          matTooltip="Logout"
          matTooltipPosition="right"
          ><mat-icon class="mat-icon">logout</mat-icon></a
        >
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content (click)="onDocumentClick($event)">
      <div class="header-container">
        <div>
          <button
            mat-icon-button
            (click)="drawer.toggle()"
            *ngIf="isHandset$ | async"
            color="primary"
          >
            <div class="mat-icon"><mat-icon>menu</mat-icon></div>
          </button>
        </div>
        <div class="top-right-container">
          <div
            class="user-info isClickable"
            *ngIf="userService.userData"
            [matTooltip]="
              (rolesRightsService.currentUserRole?.name | properCase) ??
              'No Role'
            "
            (click)="openLocationSelection()"
            [@fadeIn]
          >
            {{
              (userService.userData.displayName
                ? userService.userData.displayName
                : this.userService.userData.email) +
                (userService.getUserLocationById(
                  userService.userData.currentUserLocationId
                )?.code
                  ? " - " +
                    userService.getUserLocationById(
                      userService.userData.currentUserLocationId
                    )?.code
                  : "")
            }}
          </div>

          <!-- Primary condition -->
          <ng-container
            *ngIf="
              rolesRightsService.copyEmailDomain(
                userService.userData?.email
              ) === '@ioio.co.za';
              else showAlternateIcon
            "
          >
            <div>
              <div class="rgbButton" (click)="toggleMenu($event)" #fakerButton>
                <mat-icon>auto_awesome</mat-icon>
              </div>
            </div>
          </ng-container>

          <!-- Alternate condition -->
          <ng-template #showAlternateIcon>
            <div>
              <!-- If it's a text icon -->
              <mat-icon
                class="Montserrat smallText"
                *ngIf="iconContent.isTextIcon"
              >
                {{ iconContent.text }}
              </mat-icon>

              <!-- If it's an image icon -->
              <mat-icon *ngIf="!iconContent.isTextIcon">
                <img
                  [class]="iconContent.imgClass"
                  [src]="iconContent.imgSrc"
                />
              </mat-icon>
            </div>
          </ng-template>
        </div>
      </div>
      <div>
        <faker-menu *ngIf="fakerMenuOpen" #fakerMenu></faker-menu>
      </div>
      <policy-stepper></policy-stepper>
      <report-stepper></report-stepper>
      <main-report-stepper></main-report-stepper>
      <router-outlet *ngIf="userService.initialLoadingDone"></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-template>
