export interface FileData {
  id?: string;
  policyId?: string;
  name: string;
  customName?: string;
  url: string;
}

export interface FileToDelete extends Omit<FileData, 'id'> {}

export enum UploadType {
  POLICY_FILE = 'policyFile',
  REPORT = 'report',
  IMPORT_ERROR_CSV = 'importErrorCsv',
}

export interface GetDownloadUrlResponse {
  url: string;
}
