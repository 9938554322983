import { Component, ElementRef, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatSidenav } from '@angular/material/sidenav';

import { NavigationEnd, Router } from '@angular/router';
import { PolicyService } from 'src/app/services/policy.service';
import { FakerMenuComponent } from '../../miscellaneous/faker-menu/faker-menu.component';
import { RolesRightsService } from 'src/app/services/roles-rights.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../../miscellaneous/dialog/dialog.component';
import { MainService } from 'src/app/services/main.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      transition('void <=> *', animate('500ms ease-in-out')),
    ]),
    trigger('fadeOut', [
      state('*', style({ opacity: 1 })),
      transition(
        '* => void',
        animate('500ms ease-in-out', style({ opacity: 0 }))
      ),
    ]),
  ],
})
export class MainNavComponent {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  hasData: boolean = false;
  routeIsActive: {
    policies: boolean;
    transactions: boolean;
    settings: boolean;
    reports: boolean;
  };
  fakerMenuOpen: boolean = false;
  @ViewChild(FakerMenuComponent, { read: ElementRef, static: false })
  fakerMenuRef: ElementRef;
  env = environment.name;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public userService: UserService,
    public policyService: PolicyService,
    public rolesRightsService: RolesRightsService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    this.routeIsActive = {
      policies: false,
      transactions: false,
      settings: false,
      reports: false,
    };

    this.reloadRouteIsActive();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.reloadRouteIsActive();
      });
  }

  reloadRouteIsActive() {
    const currentRoute = this.router.url;

    const hasPolicyRoutes = [
      '/policies',
      '/policy-details',
      '/policy-member-details',
      '/policy-confirm',
      '/policy-summary',
      '/policy-logs',
      '/comments',
      '/messages',
      '/policy-files',
      '/files',
      '/policy-pdf',
    ];

    const hasTransactionRoutes = [
      '/transaction',
      '/transaction-history',
      '/transaction-summary',
      '/transaction-history-pdf',
      '/transaction-logs',
      '/daily-transactions',
      '/offline-transactions',
      '/debit-order-history',
    ];

    const hasSettingsRoute = [
      '/settings',
      '/users',
      '/user-management',
      '/products',
      '/plan-member-management',
      '/add-on-management',
      '/roles-rights-management',
      '/import',
      '/backups',
    ];

    const hasReportRoute = [
      '/reports',
      '/report-details',
      '/report-fields',
      '/report-extraction',
      '/report-extracts',
    ];

    this.routeIsActive.policies = hasPolicyRoutes.some((route) =>
      currentRoute.startsWith(route)
    );

    this.routeIsActive.transactions = hasTransactionRoutes.some((route) =>
      currentRoute.startsWith(route)
    );

    this.routeIsActive.settings = hasSettingsRoute.some((route) =>
      currentRoute.startsWith(route)
    );

    this.routeIsActive.reports = hasReportRoute.some((route) =>
      currentRoute.startsWith(route)
    );
  }

  closeDrawerIfHandset(drawer: MatSidenav): void {
    this.isHandset$
      .subscribe((isHandset) => {
        if (isHandset) {
          drawer.close();
        }
      })
      .unsubscribe();
  }

  toggleMenu(event?: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }
    this.fakerMenuOpen = !this.fakerMenuOpen;
  }

  onDocumentClick(event: MouseEvent) {
    if (this.fakerMenuOpen) {
      const target = event.target as HTMLElement;
      if (!this.fakerMenuRef.nativeElement.contains(target)) {
        this.fakerMenuOpen = false;
      }
    }
  }

  openLocationSelection() {
    this.userService.isSelectingLocation = true;
  }

  openLogoutDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      dialogType: 'logoutDialog',
      dialogTitle: 'ARE YOU SURE YOU WANT TO LOG OUT?',
    };
    this.dialog.open(DialogComponent, dialogConfig);
  }

  getSelectedPolicyId(): string | null {
    return localStorage.getItem('selectedPolicyId');
  }

  get iconContent(): {
    isTextIcon: boolean;
    text?: string;
    imgSrc?: string;
    imgClass?: string;
  } {
    switch (this.env) {
      case 'Dev':
        return { isTextIcon: true, text: 'DEV' };
      case 'Qa':
        return { isTextIcon: true, text: 'QA' };
      case 'Prod':
        return {
          isTextIcon: false,
          imgSrc: '../../../../../assets/images/WisDove.svg',
          imgClass: 'wisLogo',
        };
      case 'NLA':
        return {
          isTextIcon: false,
          imgSrc: '../../../../../assets/images/nlaFlower.svg',
          imgClass: 'nlaLogo',
        };
      default:
        return {
          isTextIcon: false,
          imgSrc: '../../../../../assets/images/WisDove.svg',
          imgClass: 'wisLogo',
        };
    }
  }
}
