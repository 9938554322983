import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[noMultipleSpaces]',
})
export class NoMultipleSpacesDirective {
  constructor(private el: ElementRef, private ngControl: NgControl) {}

  @HostListener('input', ['$event'])
  onInput(event: InputEvent): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Remove multiple consecutive spaces
    value = value.replace(/\s{2,}/g, ' ');

    // Update the input's value
    input.value = value;

    // If using Reactive Forms, update the form control value
    if (this.ngControl && this.ngControl.control) {
      this.ngControl.control.setValue(value, { emitEvent: false });
    }
  }
}
