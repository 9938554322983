<div class="parent-container" cdkDrag>
  <div class="justify-content" [@fadeIn] [@zoomIn]>
    <h1 class="glitch">
      <span aria-hidden="true">FAKER</span>
      FAKER
      <span aria-hidden="true">FAKER</span>
    </h1>
  </div>
  <form [formGroup]="fakerMenuForm" class="form-container">
    <div *ngIf="router.url.startsWith('/policies')">
      <mat-form-field appearance="outline">
        <mat-label>POLICY AMOUNT</mat-label>
        <input
          autocomplete="off"
          type="number"
          matInput
          formControlName="policyAmount"
          inputmode="numeric"
        />
        <mat-error
          *ngIf="fakerMenuForm.controls['policyAmount'].errors?.['min']"
          >AMOUNT MUST BE A POSITIVE NUMBER</mat-error
        >
        <mat-error
          *ngIf="fakerMenuForm.controls['policyAmount'].errors?.['max']"
          >AMOUNT MUST BE BELOW 100</mat-error
        >
      </mat-form-field>
    </div>
    <div
      *ngIf="router.url.startsWith('/policies')"
      class="form-item-container"
      [@fadeIn]
      [@zoomIn]
    >
      <button
        mat-stroked-button
        (click)="
          fakerService.generateCompletePolicies(
            fakerMenuForm.get('generateTransactions')?.value,
            fakerMenuForm.get('policyAmount')?.value
          )
        "
        class="border"
        [disabled]="
          policyService.loading ||
          fakerMenuForm.controls['policyAmount'].invalid
        "
      >
        GENERATE COMPLETE POLICY
      </button>
    </div>
    <div
      *ngIf="router.url.startsWith('/policies')"
      [@fadeIn]
      [@zoomIn]
      class="form-item-container"
    >
      <mat-checkbox formControlName="generateTransactions"
        >INCLUDE TRANSACTIONS</mat-checkbox
      >
    </div>
    <div
      *ngIf="
        router.url.startsWith('/policy-details') &&
        !policyService.selectedPolicy
      "
      class="form-item-container"
      [@fadeIn]
      [@zoomIn]
    >
      <button
        mat-stroked-button
        (click)="
          fakerService.generateFakePolicyDetailsData(
            fakerMenuForm.get('submit')?.value
          )
        "
        class="border"
        [disabled]="policyService.loading"
      >
        GENERATE POLICY DETAILS
      </button>
    </div>
    <div
      *ngIf="router.url.startsWith('/policy-details')"
      class="form-item-container"
      [@fadeIn]
      [@zoomIn]
    >
      <button
        mat-stroked-button
        (click)="
          fakerService.generateFakePolicyAddOnDetailsData(
            fakerMenuForm.get('submit')?.value
          )
        "
        class="border"
        [disabled]="policyService.loading"
      >
        GENERATE POLICY ADD-ON DETAILS
      </button>
    </div>
    <div
      *ngIf="router.url.startsWith('/policy-member-details')"
      class="form-item-container"
      [@fadeIn]
      [@zoomIn]
    >
      <button
        mat-stroked-button
        (click)="
          fakerService.generateFakePolicyMemberDetailsData(
            fakerMenuForm.get('submit')?.value
          )
        "
        class="border"
        [disabled]="policyService.loading"
      >
        GENERATE POLICY MEMBER DETAILS
      </button>
    </div>
    <div
      *ngIf="
        router.url.startsWith('/plan-member-management/') &&
        !planService.selectedPlan
      "
      class="form-item-container"
      [@fadeIn]
      [@zoomIn]
    >
      <button
        mat-stroked-button
        (click)="
          fakerService.generateFakePlan(fakerMenuForm.get('submit')?.value)
        "
        class="border"
        [disabled]="planService.loading"
      >
        GENERATE PLAN
      </button>
    </div>
    <div
      *ngIf="
        planService.selectedPlan &&
        router.url.startsWith('/plan-member-management/')
      "
      class="form-item-container"
      [@fadeIn]
      [@zoomIn]
    >
      <button
        mat-stroked-button
        (click)="
          fakerService.generateFakePlanMember(
            fakerMenuForm.get('submit')?.value
          )
        "
        class="border"
        [disabled]="planService.loading"
      >
        GENERATE PLAN MEMBER
      </button>
    </div>
    <div
      *ngIf="router.url.startsWith('/add-on-management/')"
      class="form-item-container"
      [@fadeIn]
      [@zoomIn]
    >
      <button
        mat-stroked-button
        (click)="
          fakerService.generateFakeAddOn(fakerMenuForm.get('submit')?.value)
        "
        class="border"
        [disabled]="addOnService.loading"
      >
        GENERATE ADD-ON
      </button>
    </div>
    <div
      *ngIf="
        policyService.selectedPolicy &&
        router.url.startsWith('/transaction-history/')
      "
    >
      <mat-form-field appearance="outline">
        <mat-label>ONLINE METHOD</mat-label>
        <mat-select formControlName="method">
          <mat-option value="ONLINE EFT">EFT</mat-option>
          <mat-option value="ONLINE DEBIT ORDER">DEBIT ORDER</mat-option>
          <mat-option value="ONLINE CARD">CARD</mat-option>
          <mat-option value="ONLINE CASH">CASH</mat-option>
          <mat-option value="ONLINE PAY@">PAY&#64;</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      *ngIf="
        policyService.selectedPolicy &&
        router.url.startsWith('/transaction-history/')
      "
    >
      <mat-form-field appearance="outline">
        <mat-label>AMOUNT</mat-label>
        <input
          autocomplete="off"
          type="number"
          matInput
          formControlName="amount"
          inputmode="numeric"
        />
        <mat-error *ngIf="fakerMenuForm.controls['amount'].errors?.['min']"
          >AMOUNT MUST BE A POSITIVE NUMBER</mat-error
        >
      </mat-form-field>
    </div>
    <div
      *ngIf="
        policyService.selectedPolicy &&
        router.url.startsWith('/transaction-history/')
      "
    >
      <mat-form-field appearance="outline">
        <mat-label>TRANSACTION DATE</mat-label>
        <input
          autocomplete="off"
          matInput
          formControlName="transactionDate"
          [matDatepicker]="transactionDatePicker"
          [min]="
            policyService.selectedPolicy
              ? dateTimeService.timestampToDate(
                  policyService.selectedPolicy.inceptionDate
                )
              : null
          "
          [max]="maxDate"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="transactionDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #transactionDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div
      *ngIf="
        policyService.selectedPolicy &&
        router.url.startsWith('/transaction-history/')
      "
      class="form-item-container"
      [@fadeIn]
      [@zoomIn]
    >
      <button
        mat-stroked-button
        (click)="
          fakerService.generateFakeOnlineTransaction(
            fakerMenuForm.get('method')?.value,
            fakerMenuForm.get('amount')?.value,
            fakerMenuForm.get('transactionDate')?.value
          )
        "
        class="border"
        [disabled]="
          transactionService.loading ||
          fakerMenuForm.controls['method'].invalid ||
          fakerMenuForm.controls['amount'].invalid ||
          fakerMenuForm.controls['transactionDate'].invalid
        "
      >
        GENERATE ONLINE TRANSACTION
      </button>
    </div>
    <div
      *ngIf="
        addOnService.selectedAddOn &&
        router.url.startsWith('/add-on-management/')
      "
      class="form-item-container"
      [@fadeIn]
      [@zoomIn]
    >
      <button
        mat-stroked-button
        (click)="
          fakerService.generateFakeAddOnPlan(fakerMenuForm.get('submit')?.value)
        "
        class="border"
        [disabled]="addOnService.loading"
      >
        GENERATE ADD-ON PLAN
      </button>
    </div>

    <div
      *ngIf="
        policyService.selectedPolicy &&
        userService.userData?.email === 'eben@ioio.co.za'
      "
      class="form-item-container"
      [@fadeIn]
      [@zoomIn]
    >
      <button
        mat-stroked-button
        (click)="fakerService.reinitializeTransactionCount()"
        class="border"
      >
        REINITIALIZE TRANSACTION COUNT
      </button>
    </div>

    <div
      *ngIf="
        policyService.selectedPolicy &&
        userService.userData?.email === 'eben@ioio.co.za' &&
        fakerService.latestTransactionCount
      "
      class="form-item-container"
      [@fadeIn]
      [@zoomIn]
    >
      <button
        mat-stroked-button
        (click)="fakerService.undoReinitializeTransactionCount()"
        class="border"
      >
        ^ UNDO REINITIALIZE ^
      </button>
    </div>

    <div class="form-item-container" [@fadeIn] [@zoomIn]>
      <button
        mat-stroked-button
        (click)="fakerService.openDocumentSizeDialog()"
        class="border"
      >
        CHECK SELECTED DOCUMENT SIZES
      </button>
    </div>

    <div [@fadeIn] [@zoomIn] class="form-item-container">
      <mat-checkbox formControlName="submit">AUTO SUBMIT</mat-checkbox>
    </div>
    <div class="form-item-container" [@fadeIn] [@zoomIn]>
      <mat-form-field appearance="outline">
        <mat-label>LANGUAGE</mat-label>
        <mat-select
          (selectionChange)="fakerService.setFakerLocale($event.value)"
          formControlName="language"
        >
          <mat-option value="en_ZA">ENGLISH</mat-option>
          <mat-option value="af_ZA">AFRIKAANS</mat-option>
          <mat-option value="zu_ZA">ZULU</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      class="form-item-container"
      [@fadeIn]
      [@zoomIn]
      *ngIf="rolesRightsService.allRoles"
    >
      <mat-form-field appearance="outline">
        <mat-label>ROLE</mat-label>
        <mat-select
          (selectionChange)="
            rolesRightsService.setCurrentUserRole(
              fakerMenuForm.get('role')?.value
            )
          "
          formControlName="role"
        >
          <mat-option
            *ngFor="let role of rolesRightsService.allRoles"
            [value]="role.id"
            >{{ role.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</div>
