import { Timestamp } from 'firebase/firestore';
import { Member } from './policy.model';
import { Message, Messages } from './message.model';
import { CreatedBy, UpdatedBy } from './general.model';

export interface ReportConfigs {
  id?: string;
  config?: ReportConfig[];
  createdOn?: Timestamp;
  updatedOn?: Timestamp;
  createdBy?: CreatedBy;
  updatedBy?: UpdatedBy;
}

export interface ReportConfig {
  id?: string;
  reportName?: string;
  description?: string;
  selectedCreatedByUser?: string;
  createdOnDateRange?: { start?: Timestamp; end?: Timestamp };
  reportType?: ReportType;
  reportFormat?: ReportFormat;
  isPrivate?: boolean;
  checkboxConfig?: CheckboxConfig[];
  lastExtractionDate?: Timestamp;
  editType?: EditType;
  createdOn?: Timestamp;
  updatedOn?: Timestamp;
  createdBy?: CreatedBy;
  updatedBy?: UpdatedBy;
}

export enum ReportFormat {
  SUMMARY = 'SUMMARY',
  DETAILED = 'DETAILED',
}

export enum ReportType {
  ALL = 'ALL',
  MEMBER = 'MEMBER',
  POLICY = 'POLICY',
  TRANSACTION = 'TRANSACTION',
  POLICY_LOG = 'POLICY LOG',
  MESSAGE = 'MESSAGE',
}

export enum EditType {
  CREATE = 'CREATED BY/ON',
  UPDATE = 'UPDATED BY/ON',
}

export interface MemberReport extends Member {
  planId: string;
  policyNumber: string;
}

export interface MessageReport extends Messages {
  policyNumber: string;
  idNumber: string;
  firstName: string;
  lastName: string;
  cellNumber: string;
}

export interface CheckboxConfig {
  value?: string;
  checked?: boolean;
  subCheckboxes?: CheckboxConfig[];
}

export interface UserReports {
  id?: string;
  generatedReports: GeneratedReport[];
}

export interface GeneratedReport {
  id?: string;
  reportName?: string;
  reportType?: ReportType;
  reportFormat?: ReportFormat;
  selectedCreatedByUser?: string;
  createdOnDateRange?: { start?: Timestamp; end?: Timestamp };
  reportUrl?: string;
  reportSize?: number;
  status?: string;
  csvLines?: number;
  editType?: EditType;
  checkboxConfig?: CheckboxConfig[];
  isCancelled?: boolean;
  createdOn?: Timestamp;
  createdBy?: CreatedBy;
}

export enum GeneratedReportStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  TIMED_OUT = 'TIMED OUT',
}

export const detailedTransactionCheckboxLabelMap: { [key: string]: string } = {
  transactionType: 'TRANSACTION TYPE',
  receiptNumber: 'RECEIPT NUMBER',
  receiptReferenceNumber: 'RECEIPT REFERENCE NUMBER',
  amount: 'AMOUNT',
  source: 'SOURCE',
  method: 'METHOD',
  status: 'STATUS',
  policyNumber: 'POLICY',
  product: 'PRODUCTS',
  productPremium: 'PRODUCT PREMIUM',
  productInceptionDate: 'PRODUCT INCEPTION DATE',
  productWaitingPeriod: 'PRODUCT WAITING PERIOD',
  productStatus: 'PRODUCT STATUS',
  memberIdNumber: 'MEMBER ID NUMBER',
  memberFirstName: 'MEMBER FIRST NAME',
  memberLastName: 'MEMBER LAST NAME',
  memberCellNumber: 'MEMBER CELL NUMBER',
  memberAltNumber: 'ALT. CELL NUMBER',
  memberEmail: 'MEMBER EMAIL',
  transactionDate: 'TRANSACTION DATE',
  createdOn: 'CREATED ON',
  createdBy: 'CREATED BY',
  userLocationId: 'LOCATION',
};

export const summaryTransactionCheckboxLabelMap: { [key: string]: string } = {
  transactionType: 'TRANSACTION TYPE',
  receiptNumber: 'RECEIPT NUMBER',
  receiptReferenceNumber: 'RECEIPT REFERENCE NUMBER',
  amount: 'AMOUNT',
  source: 'SOURCE',
  method: 'METHOD',
  status: 'STATUS',
  policyNumber: 'POLICY',
  product: 'PRODUCTS',
  memberIdNumber: 'MEMBER ID NUMBER',
  memberFirstName: 'MEMBER FIRST NAME',
  memberLastName: 'MEMBER LAST NAME',
  memberCellNumber: 'MEMBER CELL NUMBER',
  memberAltNumber: 'ALT. CELL NUMBER',
  memberEmail: 'MEMBER EMAIL',
  transactionDate: 'TRANSACTION DATE',
  createdOn: 'CREATED ON',
  createdBy: 'CREATED BY',
  userLocationId: 'LOCATION',
};

export const detailedPolicyCheckboxLabelMap: { [key: string]: string } = {
  policyNumber: 'POLICY',
  status: 'POLICY STATUS', // Has subCheckboxes
  product: 'PRODUCT', // Has subCheckboxes
  productInceptionDate: 'PRODUCT INCEPTION DATE',
  productStatus: 'PRODUCT STATUS', // Has subCheckboxes
  createdOn: 'POLICY CREATED ON',
  updatedOn: 'POLICY UPDATED ON',
  createdBy: 'POLICY CREATED BY',
  updatedBy: 'POLICY UPDATED BY',
  userLocationId: 'LOCATION',
  productPremium: 'STANDARD PREMIUM',
  nextPremium: 'NEXT TOTAL PREMIUM',
  intendedPaymentDay: 'INTENDED PAYMENT DAY',
  productsPaymentStatus: 'PRODUCT PAID UP TO',
  unallocatedBalance: 'UNALLOCATED BALANCE',
  payAtNumber: 'PAY@ NUMBER',
  bank: 'BANK',
  accountType: 'ACCOUNT TYPE',
  accountNumber: 'ACCOUNT NUMBER',
  memberTypeId: 'MEMBER TYPE',
  memberStatus: 'MEMBER STATUS',
  memberInceptionDate: 'MEMBER INCEPTION DATE',
  memberWaitingPeriod: 'MEMBER WAITING PERIOD',
  memberPremium: 'MEMBER PREMIUM',
  memberIdNumber: 'MEMBER ID NUMBER',
  memberFirstName: 'MEMBER FIRST NAME',
  memberLastName: 'MEMBER LAST NAME',
  memberGender: 'MEMBER GENDER',
  memberAge: 'MEMBER AGE',
  memberStreet: 'MEMBER STREET',
  memberCity: 'MEMBER CITY',
  memberProvince: 'MEMBER PROVINCE',
  memberPostalCode: 'MEMBER POSTAL CODE',
  memberCellNumber: 'MEMBER CELL NUMBER',
  memberAltNumber: 'ALT. CELL NUMBER',
  memberEmail: 'MEMBER EMAIL',
};

export const summaryPolicyCheckboxLabelMap: { [key: string]: string } = {
  policyNumber: 'POLICY',
  status: 'POLICY STATUS', // Has subCheckboxes
  product: 'PRODUCT',
  productInceptionDate: 'PRODUCT INCEPTION DATE',
  createdOn: 'POLICY CREATED ON',
  updatedOn: 'POLICY UPDATED ON',
  createdBy: 'POLICY CREATED BY',
  updatedBy: 'POLICY UPDATED BY',
  userLocationId: 'LOCATION',
  productPremium: 'STANDARD PREMIUM',
  nextPremium: 'NEXT TOTAL PREMIUM',
  intendedPaymentDay: 'INTENDED PAYMENT DAY',
  productsPaymentStatus: 'PRODUCT PAID UP TO',
  unallocatedBalance: 'UNALLOCATED BALANCE',
  payAtNumber: 'PAY@ NUMBER',
  bank: 'BANK',
  accountType: 'ACCOUNT TYPE',
  accountNumber: 'ACCOUNT NUMBER',
  memberStatus: 'MEMBER STATUS',
  memberInceptionDate: 'MEMBER INCEPTION DATE',
  memberWaitingPeriod: 'MEMBER WAITING PERIOD',
  memberPremium: 'MEMBER PREMIUM',
  memberIdNumber: 'MEMBER ID NUMBER',
  memberFirstName: 'MEMBER FIRST NAME',
  memberLastName: 'MEMBER LAST NAME',
  memberGender: 'MEMBER GENDER',
  memberAge: 'MEMBER AGE',
  memberStreet: 'MEMBER STREET',
  memberCity: 'MEMBER CITY',
  memberProvince: 'MEMBER PROVINCE',
  memberPostalCode: 'MEMBER POSTAL CODE',
  memberCellNumber: 'MEMBER CELL NUMBER',
  memberAltNumber: 'ALT. CELL NUMBER',
  memberEmail: 'MEMBER EMAIL',
};

export const detailedMemberCheckboxLabelMap: { [key: string]: string } = {
  idNumber: 'ID NUMBER',
  firstName: 'FIRST NAME',
  lastName: 'LAST NAME',
  gender: 'GENDER',
  age: 'AGE',
  street: 'STREET',
  city: 'CITY',
  province: 'PROVINCE',
  postalCode: 'POSTAL CODE',
  cellNumber: 'CELL NUMBER',
  altNumber: 'ALT. CELL NUMBER',
  email: 'EMAIL',
  createdOn: 'MEMBER CREATED ON',
  createdBy: 'MEMBER CREATED BY',
  memberTypeId: 'MEMBER TYPE',
  status: 'MEMBER STATUS',
  memberInceptionDate: 'MEMBER INCEPTION DATE',
  waitingPeriod: 'MEMBER WAITING PERIOD',
  premium: 'MEMBER PREMIUM',
  policyNumber: 'POLICY NUMBER',
  product: 'PRODUCT TYPE',
  productInceptionDate: 'PRODUCT INCEPTION DATE',
  productPremium: 'PRODUCT PREMIUM',
  productsPaymentStatus: 'PRODUCT PAID UP TO',
  productWaitingPeriod: 'PRODUCT WAITING PERIOD',
  productStatus: 'PRODUCT STATUS',
  nextPremium: 'NEXT PREMIUM',
  updatedOn: 'MEMBER UPDATED ON',
  updatedBy: 'MEMBER UPDATED BY',
  userLocationId: 'LOCATION',
};
export const summaryMemberCheckboxLabelMap: { [key: string]: string } = {
  policyNumber: 'POLICY',
  product: 'PRODUCT',
  memberTypeId: 'TYPE',
  idNumber: 'ID NUMBER',
  firstName: 'FIRST NAME',
  lastName: 'LAST NAME',
  gender: 'GENDER',
  age: 'AGE',
  street: 'STREET',
  city: 'CITY',
  province: 'PROVINCE',
  postalCode: 'POSTAL CODE',
  cellNumber: 'CELL NUMBER',
  altNumber: 'ALTERNATIVE NUMBER',
  email: 'EMAIL',
  memberInceptionDate: 'INCEPTION DATE',
  waitingPeriod: 'WAITING PERIOD',
  premium: 'STANDARD PREMIUM',
  nextPremium: 'NEXT PREMIUM',
  createdOn: 'CREATED ON',
  updatedOn: 'UPDATED ON',
  createdBy: 'CREATED BY',
  updatedBy: 'UPDATED BY',
  userLocationId: 'LOCATION',
  status: 'STATUS',
};

export const policyLogCheckboxLabelMap: { [key: string]: string } = {
  policyNumber: 'POLICY',
  type: 'EVENT',
  object: 'TYPE',
  key: 'FIELD',
  before: 'ORIGINAL VALUE',
  after: 'PRESENT VALUE',
  referenceNumber: 'REFERENCE',
  createdOn: 'CREATED ON',
  createdBy: 'UPDATED BY',
  userLocationId: 'LOCATION',
};

export const messageCheckboxLabelMap: { [key: string]: string } = {
  message: 'MESSAGE',
  referenceNumber: 'REFERENCE NUMBER',
  cellNumber: 'CELL NUMBER',
  createdOn: 'CREATED ON',
  createdBy: 'UPDATED BY',
  userLocationId: 'LOCATION',
  status: 'STATUS',
  policyNumber: 'POLICY',
  idNumber: 'PRIMARY MEMBER ID NUMBER',
  firstName: 'PRIMARY MEMBER FIRST NAME',
  lastName: 'PRIMARY MEMBER LAST NAME',
};

export const logObjectMap: { [key: string]: string } = {
  ALL: 'ALL',
  policy: 'POLICY',
  member: 'MEMBER',
  addOn: 'ADD-ON',
  comment: 'COMMENT',
  file: 'FILE',
};
