import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { SnackBarService } from './snack-bar.service';
import { MemberPolicySearch } from '../models/search.model';
import { Transaction, TransactionStatus } from '../models/transaction.model';
import { User } from '../models/user.model';
import MeiliSearch, { SearchResponse } from 'meilisearch';

export type SearchText = {
  offlineTransactions: string;
  policies: string;
  users: string;
  transactions: string;
  moveTransaction: string;
};

export type SearchResults = {
  offlineTransactions: number | undefined;
  policies: number | undefined;
  users: number | undefined;
  transactions: number | undefined;
  moveTransaction: number | undefined;
};
@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private client: MeiliSearch;
  searchText: SearchText = {
    offlineTransactions: '',
    policies: '',
    users: '',
    transactions: '',
    moveTransaction: '',
  };

  searchResults: SearchResults = {
    offlineTransactions: undefined,
    policies: undefined,
    users: undefined,
    transactions: undefined,
    moveTransaction: undefined,
  };

  overrideMemberId: boolean = false;

  loadedPolicyMembers: MemberPolicySearch[] | undefined;
  filteredPolicyMembers: MemberPolicySearch[] | undefined;
  loadedOfflineTransactionPolicyMembers: MemberPolicySearch[] | undefined;
  filteredOfflineTransactionPolicyMembers: MemberPolicySearch[] | undefined;
  loadedTransactions: Transaction[] | undefined;
  filteredTransactions: Transaction[] | undefined;
  loadedUsers: User[] | undefined;
  filteredUsers: User[] | undefined;

  dataSourceSearchResults: MatTableDataSource<any>;
  dataSourceSearchPolicies: MatTableDataSource<MemberPolicySearch> | null =
    null;
  dataSourceSearchOfflineTransactionPolicies: MatTableDataSource<any> | null =
    null;

  dataSourceSearchMoveTransaction: MatTableDataSource<any> | null = null;
  dataSourceSearchUsers: MatTableDataSource<any> | null = null;
  dataSourceSearchTransactions: MatTableDataSource<any> | null = null;

  constructor(private snackBarService: SnackBarService) {
    this.client = new MeiliSearch({
      host: environment.meiliEndpoint,
      apiKey: environment.meiliAPIKey,
    });
  }

  async policySearch(
    query: string
  ): Promise<SearchResponse<MemberPolicySearch>> {
    try {
      const index = this.client.index(environment.meiliPolicyIndex);
      return await index.search(query, {
        limit: 1000,
      });
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('POLICY SEARCH FAILED!');
      return {
        hits: [],
        offset: 0,
        limit: 20,
        estimatedTotalHits: 0,
        processingTimeMs: 0,
        query,
      };
    }
  }

  async usersSearch(query: string): Promise<SearchResponse<User>> {
    try {
      const index = this.client.index(environment.meiliUsersIndex);
      return await index.search(query, {
        limit: 1000,
      });
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('USER SEARCH FAILED!');
      return {
        hits: [],
        offset: 0,
        limit: 20,
        estimatedTotalHits: 0,
        processingTimeMs: 0,
        query,
      };
    }
  }

  async transactionSearch(
    query: string,
    page: number = 0,
    hitsPerPage: number = 20,
    status: TransactionStatus = TransactionStatus.ALL
  ): Promise<SearchResponse<Transaction>> {
    try {
      const index = this.client.index(environment.meiliTransactionIndex);
      return await index.search(query, {
        page,
        hitsPerPage,
        filter: status === TransactionStatus.ALL ? '' : `status = ${status}`,
        sort: ['counter:desc'],
      });
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('TRANSACTION SEARCH FAILED!');
      return {
        hits: [],
        offset: 0,
        limit: 20,
        estimatedTotalHits: 0,
        processingTimeMs: 0,
        query,
      };
    }
  }

  public setSearchResultsDataSource(
    members: MemberPolicySearch[],
    searchText: string
  ) {
    this.dataSourceSearchResults = new MatTableDataSource(members);
    this.dataSourceSearchResults.filter = searchText.trim().toUpperCase();
  }

  updateCurrentSearchPolicies(members: MemberPolicySearch[]) {
    if (!this.dataSourceSearchPolicies) {
      this.dataSourceSearchPolicies =
        new MatTableDataSource<MemberPolicySearch>(members);
    } else {
      this.dataSourceSearchPolicies.data = members;
      this.dataSourceSearchPolicies._updateChangeSubscription();
    }
  }

  updateCurrentSearchOfflineTransactionPolicies(members: MemberPolicySearch[]) {
    if (!this.dataSourceSearchOfflineTransactionPolicies) {
      this.dataSourceSearchOfflineTransactionPolicies =
        new MatTableDataSource<MemberPolicySearch>(members);
    } else {
      this.dataSourceSearchOfflineTransactionPolicies.data = members;
      this.dataSourceSearchOfflineTransactionPolicies._updateChangeSubscription();
    }
  }

  updateCurrentSearchTransactions(transactions: Transaction[]) {
    if (!this.dataSourceSearchTransactions) {
      this.dataSourceSearchTransactions = new MatTableDataSource<Transaction>(
        transactions
      );
    } else {
      this.dataSourceSearchTransactions.data = transactions;
      this.dataSourceSearchTransactions._updateChangeSubscription();
    }
  }

  updateCurrentSearchUsers(users: User[]) {
    if (!this.dataSourceSearchUsers) {
      this.dataSourceSearchUsers = new MatTableDataSource<User>(users);
    } else {
      this.dataSourceSearchUsers.data = users;
      this.dataSourceSearchUsers._updateChangeSubscription();
    }
  }
}
